/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars*/
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-else-return */
/* eslint-disable no-lonely-if */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */

/**
 * React component for Inventory Request.
 * @module src/components/NewRepTransfer/index
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import FormEngine from '../../../Shared/FormEngine';
import { getSchemeJson } from '../../../../util/FormJson';
import { GET_ALL_SALES_REPS } from '../../../../graphql/getAllSalesReps';
import { GET_SALES_REPS } from '../../../../graphql/getSalesReps';
import { GET_USER_REP_STOCK_ACCOUNTS } from '../../../../graphql/getUserRepStockAccounts';
import { GET_PRIMARY_ACCOUNT } from '../../../../graphql/getPrimaryAccount';
import { GET_FORM_CONFIGURATIONS } from '../../../../graphql/getFormConfigurations';
import { GET_SAS_URI } from '../../../../graphql/getSasUri';
import DELETE_ATTACHMENT from '../../../../graphql/mutations/deleteAttachment';
import UPDATE_ATTACHMENT from '../../../../graphql/mutations/inventoryReturnAttachments';
import { POST_TRANSFER_INVENTORY } from '../../../../graphql/mutations/transferInventory';
import { POST_TRANSFER_INVENTORY_AFTER_SFID } from '../../../../graphql/mutations/transferInventoryAfterSfid';
import { UPDATE_TRANSFER_INVENTORY } from '../../../../graphql/mutations/updateTransferInventory';
import { GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS } from '../../../../graphql/getSubInventoryWithLotDetails';
import { GET_USER_TERRITORIES } from '../../../../graphql/getUserTerritories';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import {
  NOTIFY_TYPE,
  TRANSFER_LIST,
  OPSCSRUSERS,
  PERSONA_CONDITION,
} from '../../../../util/constants';
import useBlobFileService, { BlobFile } from '../../../../util/customHooks/useBlobFileService';
import SnackBar from '../../../Shared/SnackBar';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import { getUserId } from '../../../../store/ducks/userId';
import { GET_UPCOMING_EVENTS } from '../../../../graphql/getUpcomingEvents';

interface VariableType {
  [key: string]: string;
}

interface File {
  manual?: boolean;
  id?: string;
  name: string;
  imageurl: string;
}
interface Props extends RouteComponentProps {
  transferType: string;
  toggleOpen: () => void;
  formData?: any;
  isOpen: boolean;
  handleCancelButton?: () => void;
  handleRefetchItems: () => void;
  tranferDetails?: any;
  sourceAccount?: any;
  destAccount?: any;
  isEdit?: boolean;
  eventUsage?: boolean;
  editDetails?: any;
}

const EVENTS_KEY: VariableType = {
  sourceAccount: 'fromSubInventoryCode',
  destinationAccount: 'toSubInventoryCode',
};

const EVENTS_KEY_FAILED: VariableType = {
  // country: 'branchId',
  account: 'accountId',
  surgeon: 'surgeonId',
  salesRep: 'salesRepId',
  shipTo: 'shippingId',
  needByDate: 'needByDate',
  serviceLevel: 'serviceLevel',
  comments: 'comments',
};

const NewRepTransfer: FC<Props> = ({
  isOpen,
  toggleOpen,
  formData,
  transferType,
  history,
  handleCancelButton,
  handleRefetchItems,
  tranferDetails,
  sourceAccount,
  destAccount,
  isEdit,
  eventUsage,
  editDetails,
}) => {
  const { data: sasUriData } = useQuery(GET_SAS_URI);
  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const [createTransferInventory, { loading: createTransferInventoryLoading }] = useMutation(
    POST_TRANSFER_INVENTORY
  );
  const [
    createTransferInventoryAfterSfid,
    { loading: createTransferInventoryAfterSfidLoading },
  ] = useMutation(POST_TRANSFER_INVENTORY_AFTER_SFID);
  const [updateTransferAttachment] = useMutation(UPDATE_ATTACHMENT);
  const [updateTransferInventory] = useMutation(UPDATE_TRANSFER_INVENTORY);
  const [accountChecked, setAccountChecked] = useState('No');
  const [warning, setWarningMessage] = useState(false);
  const [apiloading, setLoader] = useState(false);
  const [partsIssue, setPartsErrorIssue] = useState('');
  const { open, notification, openSnackBar } = useSnackBar();
  const [otherAddressData, setOtherAddressData] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadedFiles, setUploadFiles] = useState<File[]>([]);
  const [selectedTab, setSelectedTab] = useState('Details');
  const [userTerritories, setUserTerritories] = useState();
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);
  const { uploadFileToBlobStorage } = useBlobFileService(sasUriData?.getSasUri);
  const [getSalesRepsItems, { data: salesRepsItems }] = useLazyQuery(GET_SALES_REPS); // USELAZYQUERY
  const [getAllSalesRepsItems, { data: getAllSalesReps, loading: loadingSalesRep }] = useLazyQuery(
    GET_ALL_SALES_REPS
  );
  const [getUpcomingEvents, { data: eventlistdata }] = useLazyQuery(GET_UPCOMING_EVENTS, {
    variables: {
      salesRepId: editDetails?.salesRepId,
      accountId: editDetails?.accountId,
      currentEventId: editDetails?.caseId,
      currentEventExternalId: editDetails?.caseExternalId,
    },
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY
  const allSalesRepsData = getAllSalesReps?.getAllSalesReps;
  const salesRepsData = salesRepsItems?.getSalesReps;
  // const { data: salesRepsItems } = useQuery(GET_SALES_REPS);
  // const salesRepsData = salesRepsItems?.getSalesReps;
  const userId = useSelector(getUserId);
  const userInfo = useSelector(getUserInfo);
  const [accountSelected, setAccountSelected] = useState<any>();
  const [accountDesSelected, setAccountDesSelected] = useState<any>();
  const [repSelected, setRepSelected] = useState<any>();
  const [repStockAccountData, setRepStockAccountData] = useState<any>();
  const [recRepStockAccountData, setRecRepStockAccountData] = useState<any>();
  const [salesReps, setSalesReps] = useState<any>();
  const [eventSelected, setEventSelect] = useState<any>();
  const [eventDropdown, setEventList] = useState<any>();
  const [salesRepSelected, setSalesRepSelected] = useState<any>(
    transferType === 'Customer Transfer' && userInfo?.personas === 'Sales Rep' ? userId : null
  );
  const [filteredRepStockAccountData, setFilteredRepStockAccountData] = useState<any>();
  const [filteredAccountData, setFilteredCustomerAccountData] = useState<any>();

  const [
    getRepStockAccountItems,
    { data: repStockAccountItems, loading: repStockLoading },
  ] = useLazyQuery(GET_USER_REP_STOCK_ACCOUNTS, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY

  const [
    getRecRepStockAccountItems,
    { data: recRepStockAccountItems, loading: recRepStockLoading },
  ] = useLazyQuery(GET_USER_REP_STOCK_ACCOUNTS, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY

  const [
    getPrimaryAccountItems,
    { data: primaryAccountItems, loading: dataloading },
  ] = useLazyQuery(GET_PRIMARY_ACCOUNT, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY
  const primaryAccountData = primaryAccountItems?.getPrimaryAccount;
  const eventlist = eventlistdata?.getUpcomingEventsList;
  const [getSubInventoryWithLotDetails, { data: subInventoryWithLotDetails }] = useLazyQuery(
    GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const isUserOpsCsr = !!OPSCSRUSERS?.find((rec: any) => rec?.value === userInfo?.personas);
  const [loading, setLoading] = useState(false);
  const getOptionsWithLabel = (addressData: any): [] => {
    if (addressData) {
      return (
        addressData &&
        addressData.map((item: any) => {
          if (item.__typename === 'Account') {
            return {
              ...item,
              label: item.isRepStockAccount
                ? `* ${item.number} ${item.value}`
                : `${item.number} ${item.value}`,
              // number: item.isRepStockAccount ? `* ${item.number}` : `${item.number}`,
            };
          } else if (item.__typename === 'UpcomingEventsList') {
            return {
              ...item,
              label: `${moment(item?.eventStartDate).format('YYYY/MM/DD')} - ${item?.eventId}`,
            };
          } else if (item.__typename === 'UserRepStockAccount') {
            return {
              ...item,
              label: `* ${item.number} ${item.value}`,
              isRepStockAccount: true,
              number: item.isRepStockAccount ? `* ${item.number}` : `${item.number}`,
            };
          } else {
            if (item.isOtherAddress) {
              return {
                ...item,
                label: `${item.address1 || ''}, ${item.address2 || ''}, ${item.city ||
                  ''}, ${item.state || ''}, ${item.name || ''}`,
              };
            } else {
              return {
                ...item,
                label: `${item.isPrimaryAddress ? '*' : ''} ${item.name} - ${
                  item.address1
                } ${item.address2 || ''}, ${item.state}, ${item.postalcode}`,
              };
            }
          }
        })
      );
    }
    return [];
  };
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  const [getTerritories, { data: territoryData }] = useLazyQuery(GET_USER_TERRITORIES);
  useEffect(() => {
    getTerritories({
      variables: {
        userId: userInfo?.id,
      },
    });
  }, [getTerritories, userInfo]);

  useEffect(() => {
    getTerritories();
    if (territoryData?.getUserTerritories) {
      const userTerritoriesItemsList = territoryData?.getUserTerritories;
      if (userTerritoriesItemsList && userTerritoriesItemsList.length > 0) {
        setUserTerritories(userTerritoriesItemsList[0].value);
      }
    }
  }, [getTerritories, territoryData]);

  useEffect(() => {
    if (editDetails?.caseExternalId) {
      getSubInventoryWithLotDetails({
        variables: {
          eventExternalId: editDetails?.caseExternalId,
          subInventoryName: userTerritories,
          subInventoryCode: editDetails?.accountNumber ? editDetails?.accountNumber : '',
          recordType: 'Transfer',
        },
      });
    }
  }, [editDetails, eventUsage, getSubInventoryWithLotDetails, tranferDetails, userTerritories]);

  const fetchItems = useCallback(() => {
    getSalesRepsItems();
    getAllSalesRepsItems();
    getUpcomingEvents();
    if (salesRepSelected) {
      getPrimaryAccountItems({
        variables: {
          salesRepId: salesRepSelected,
        },
      });
      getRepStockAccountItems({
        variables: {
          salesRepId: salesRepSelected,
        },
      });
      getUpcomingEvents({
        variables: {
          salesRepId: editDetails?.salesRepId,
          accountId: editDetails?.accountId,
          currentEventId: editDetails?.caseId,
          currentEventExternalId: editDetails?.caseExternalId,
        },
      });
    }
  }, [
    getSalesRepsItems,
    getAllSalesRepsItems,
    getUpcomingEvents,
    salesRepSelected,
    getPrimaryAccountItems,
    getRepStockAccountItems,
    editDetails,
  ]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    if (repSelected && repSelected.length > 0) {
      getRecRepStockAccountItems({
        variables: {
          salesRepId: repSelected[0].id,
        },
      });
    }
  }, [repSelected, getRecRepStockAccountItems]);

  useEffect(() => {
    if (tranferDetails) {
      if (tranferDetails.attachments && tranferDetails.attachments.length > 0) {
        // eslint-disable-next-line prefer-const
        let result: any = [];
        tranferDetails.attachments.map((item: any) => {
          const fileToUpload = {
            id: item.id,
            name: item.name,
            imageurl: item.imageurl,
          };
          result.push(fileToUpload);
        });
        setUploadFiles(result);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tranferDetails, setUploadFiles, setAccountSelected]);

  useEffect(() => {
    if (recRepStockAccountItems && recRepStockAccountItems.getUserRepStockAccounts) {
      if (repSelected && repSelected.length > 0) {
        setRecRepStockAccountData(recRepStockAccountItems.getUserRepStockAccounts);
      } else {
        recRepStockAccountItems.getUserRepStockAccounts = [];
        setRecRepStockAccountData([]);
      }
    }
  }, [recRepStockAccountItems, setRecRepStockAccountData, repSelected]);

  useEffect(() => {
    if (repStockAccountItems && repStockAccountItems.getUserRepStockAccounts) {
      if (salesRepSelected) {
        setRepStockAccountData(repStockAccountItems.getUserRepStockAccounts);
      } else {
        repStockAccountItems.getUserRepStockAccounts = [];
        setRepStockAccountData([]);
      }
    }
  }, [repStockAccountItems, setRepStockAccountData, salesRepSelected]);

  useEffect(() => {
    if (primaryAccountData) {
      const filteredRepStockAccount: any = getOptionsWithLabel(primaryAccountData);
      const customerAccount: any = [];
      const repStockAccount: any = [];
      filteredRepStockAccount.map((item: any) => {
        if (item.isRepStockAccount) {
          repStockAccount.push(item);
        } else {
          customerAccount.push(item);
        }
      });
      setFilteredCustomerAccountData(customerAccount);
      setFilteredRepStockAccountData(repStockAccount);
    }
  }, [primaryAccountData]);

  useEffect(() => {
    if (eventlist) {
      const eventListDropdown: any = getOptionsWithLabel(eventlist);
      const eventData: any = [];
      eventListDropdown.map((item: any) => {
        eventData.push(item);
      });
      setEventList(eventData);
    }
  }, [eventlist]);

  useEffect(() => {
    if (tranferDetails) {
      if (tranferDetails.attachments && tranferDetails.attachments.length > 0) {
        // eslint-disable-next-line prefer-const
        let result: any = [];
        tranferDetails.attachments.map((item: any) => {
          const fileToUpload = {
            id: item.id,
            name: item.name,
            imageurl: item.imageurl,
          };
          result.push(fileToUpload);
        });
        setUploadFiles(result);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tranferDetails, setUploadFiles]);

  const handleRadioButtonChange = (event: any): void => {
    setAccountChecked(event.target.value);
  };

  const formatLineItemToSave = (lineItems: any): any => {
    const finalLineItems: Array<any> = [];
    lineItems.map(
      (item: {
        lotNumber: any;
        productId: any;
        productNo: any;
        quantity: any;
        priceBookEntryId: any;
        unitPrice: any;
        salesUnitOfMeasure: any;
        deliveredDate: any;
        productNumber: any;
        isLotNumber: boolean;
      }): any => {
        finalLineItems.push({
          productId: item?.productId,
          productNumber: item?.productNumber,
          quantity: item?.quantity,
          lotNumber: item?.lotNumber,
          priceBookEntryId: item?.priceBookEntryId,
          unitPrice: item?.unitPrice,
          salesUnitOfMeasure: item?.salesUnitOfMeasure,
          deliveredDate: item?.deliveredDate,
          isLotNumber: item?.isLotNumber,
        });
      }
    );
    return finalLineItems;
  };

  const formJson = useCallback(() => {
    try {
      const dataObject = data && data.getFormConfigurations;
      const formIdentifierToFormName: Record<string, string> = {
        'Customer Transfer': 'customerTransfer',
        'Rep Transfer': 'repTransfer',
        'Event Transfer': 'eventTransfer',
        'Rep to Rep': 'repTransfer',
      };
      const result: any = dataObject[formIdentifierToFormName[transferType]];
      const isOps =
        userInfo && PERSONA_CONDITION.some(el => userInfo?.personas?.toLowerCase()?.includes(el));
      const persona = userInfo?.personas?.toLowerCase();
      const isUserOp = !!persona?.includes('branch op');
      const isUserAsr = !!persona?.includes('sales associate');
      const isUserRsm = !!persona?.includes('sales manager');
      const checkLD = result[0]?.fields.filter((i: any) => i.name === 'destinationAccount');
      if (transferType === 'Customer Transfer' && userInfo) {
        if (
          userInfo.personas === 'Sales Associate' ||
          userInfo.personas === 'Sales Manager' ||
          isOps
        ) {
          const checkField = result[0]?.fields.filter((i: any) => i.name === 'salesRep');
          if (checkField.length > 0) {
            result[0]?.fields?.splice(1, 1);
          }
          const objectName: any = {
            kind: 'field',
            name: 'salesRep',
            fieldLabel: 'Sales Rep',
            fieldType: 'selectSingleDynamicAutoCompletePicker',
            required: true,
            optionsPlaceHolder: 'Sales Rep',
            isShow: true,
            optionsDynamic: 'getSalesReps',
            optionsAutoSelected: false,
            optionsDependentField: [],
            permissionSets: [],
          };
          const checkLD = result[0]?.fields.filter((i: any) => i.name === 'salesRep');
          if (checkLD.length === 0) {
            result[0]?.fields?.splice(1, 0, objectName);
          }
        }
      }
      if (
        checkLD.length > 0 &&
        (transferType === 'Rep Transfer' || transferType === 'Rep to Rep')
      ) {
        result[0]?.fields?.splice(3, 1);
      }
      if (
        // repSelected &&
        // repSelected.length > 0 &&
        transferType === 'Rep Transfer' ||
        transferType === 'Rep to Rep'
      ) {
        const objectName: any = {
          fieldLabel: 'Destination Account',
          fieldType: 'selectSingleDynamicAutoCompletePicker',
          kind: 'field',
          name: 'destinationAccount',
          optionsAutoSelected: true,
          optionsDependentField: [],
          isShow: true,
          optionsDynamic: 'getRecDestinationAccounts',
          optionsPlaceHolder: 'Pick an Account',
          permissionSets: [],
          required: true,
          // disabled: true,
        };
        const checkLD = result[0]?.fields.filter((i: any) => i.name === 'destinationAccount');
        if (checkLD.length === 0) {
          result[0]?.fields?.splice(3, 0, objectName);
        }
      }
      const checkSalesRep = result[0]?.fields.filter((i: any) => i.name === 'salesRep');
      const checkSourceAccount = result[0]?.fields.filter((i: any) => i.name === 'sourceAccount');
      const checkDestinationAccount = result[0]?.fields.filter(
        (i: any) => i.name === 'destinationAccount'
      );
      const checkRecievingRep = result[0]?.fields.filter((i: any) => i.name === 'recievingRep');
      if (
        (transferType === 'Rep to Rep' &&
          tranferDetails.status === 'New' &&
          tranferDetails?.lineItems?.length > 0) ||
        (isUserOp && transferType === 'Rep to Rep' && tranferDetails.status === 'Requested')
      ) {
        checkSalesRep[0].disabled = true;
        checkSourceAccount[0].disabled = true;
      } else if (
        (isUserAsr || isUserRsm) &&
        transferType === 'Rep to Rep' &&
        tranferDetails.status === 'Requested' &&
        tranferDetails.visualIndicator === 'Requested'
      ) {
        checkSalesRep[0].disabled = true;
        checkSourceAccount[0].disabled = true;
        checkDestinationAccount[0].disabled = true;
        checkRecievingRep[0].disabled = true;
      }
      return result;
    } catch (error) {
      // If the query errors out, need to return something
      console.log(`Form Json Error - Error Caught: ${error}`);
      return getSchemeJson(transferType);
    }
  }, [data, transferType, repSelected, userInfo]);

  let results: any[] = [];

  if (salesReps?.label) {
    const salesRepNew =
      salesRepsData && salesRepsData.filter((item: any) => salesReps?.id === item.id);
    results =
      allSalesRepsData &&
      allSalesRepsData.filter((item: any) => salesRepSelected !== item.id && salesRepNew);
  } else {
    results =
      allSalesRepsData && allSalesRepsData.filter((item: any) => salesRepSelected !== item.id);
  }

  useEffect(() => {
    const filteredRepStockAccount: any = getOptionsWithLabel(primaryAccountData);
    if (
      accountSelected?.length > 0 &&
      (accountSelected[0]?.isRepStockAccount || accountSelected[0]?.isITorIHAccount)
    ) {
      const filteredAccount = filteredRepStockAccount?.filter((cv: any) => {
        return (
          accountSelected &&
          accountSelected.find((e: any) => {
            return !cv.isRepStockAccount && !cv.isITorIHAccount;
          })
        );
      });
      setFilteredCustomerAccountData(filteredAccount);
    } else {
      const filteredAccount = filteredRepStockAccount?.filter((cv: any) => {
        return (
          accountSelected &&
          accountSelected.find((e: any) => {
            return cv.isRepStockAccount || cv.isITorIHAccount;
          })
        );
      });
      setFilteredCustomerAccountData(filteredAccount);
    }
  }, [accountSelected, primaryAccountData]);

  const getInternalComment = (details: any) => {
    const internalComm: any = details?.comments?.filter((item: any) => item.type === 'Internal');
    return internalComm;
  };

  const apiDataObj = {
    getSalesReps: salesRepsData,
    getSourceAccounts:
      transferType === 'Customer Transfer'
        ? getOptionsWithLabel(primaryAccountData)
        : getOptionsWithLabel(repStockAccountData),
    getDestinationAccounts: filteredAccountData,
    getRecDestinationAccounts: getOptionsWithLabel(recRepStockAccountData),
    getReceivingSalesReps: results,
    getUpcomingEventsList: eventDropdown,
  };
  const lazyQueryObject = {
    getRecDestinationAccounts: getRecRepStockAccountItems,
    getSalesReps: getSalesRepsItems,
  };

  const handleSaveRequest = (result: any): void => {
    let mutation: any = {};
    let uploadAttachments: any = uploadedFiles.filter((item: File) => item.manual);
    uploadAttachments = uploadAttachments.map((e: any) => ({ name: e.name, imageurl: e.imageurl }));
    const attachments = [...uploadAttachments];
    mutation = {
      ...mutation,
      salesOrganization: 'F48',
      lineItems: eventUsage && result?.lineItems ? formatLineItemToSave(result?.lineItems) : [],
      type: transferType,
      // Customer to Rep
      // Rep to Customer
    }; // TODO Added static value for status as discuss with SFDC team
    if (transferType === 'Customer Transfer' || transferType === 'Event Transfer') {
      mutation = {
        ...mutation,
        salesRepId: salesRepSelected || editDetails?.salesRepId,
        receivingRepId: salesRepSelected || editDetails?.salesRepId,
        // eventType,
      };
    }
    if (eventUsage) {
      if (transferType === 'Customer Transfer') {
        mutation = {
          ...mutation,
          eventId: editDetails?.caseId,
          eventExternalId: editDetails?.caseExternalId,
          // eventType,
        };
      }
      if (transferType === 'Event Transfer') {
        mutation = {
          ...mutation,
          parentEventId: editDetails?.caseId,
          parentEventExternalId: editDetails?.caseExternalId,
          eventId: result.event[0]?.id,
          eventExternalId: result.event[0]?.externalId,
          fromSubInventoryCode: editDetails?.accountNumber,
          toSubInventoryCode: editDetails?.accountNumber,
          // eventType,
        };
      }
    }
    if (transferType !== 'Event Transfer') {
      Object.keys(EVENTS_KEY).forEach(key => {
        if (result[key]) {
          const serverKey = EVENTS_KEY[key];
          if (Array.isArray(result[key]) && result[key].length > 0) {
            mutation = {
              ...mutation,
              [serverKey]: `${
                result[key][0]?.number ? result[key][0]?.number : editDetails?.accountNumber
              }`,
            };
          } else if (typeof result[key] === 'string' || typeof result[key] === 'boolean') {
            mutation = { ...mutation, [serverKey]: result[key] };
          }
        }
      });
    }
    if (result.sourceAccount) {
      if (transferType === 'Customer Transfer') {
        mutation = {
          ...mutation,
          accountId: result.sourceAccount[0].id,
          // type: result.sourceAccount[0].isRepStockAccount ? 'Rep to Customer' : 'Customer to Rep',
        };
      } else {
        mutation = { ...mutation, accountId: result.sourceAccount[0].id };
      }
    }
    if (uploadAttachments) {
      const attachmentsData = [...uploadAttachments];
      mutation = { ...mutation, attachments: attachmentsData };
    }
    if (result.comments || result?.comments === '') {
      if (result.externalId) {
        mutation = {
          ...mutation,
          comments: {
            externalId: result.externalId,
            comment: result.comments,
            postTime: moment(new Date()).format('YYYY-MM-DD HH:MM'),
            commentType: 'Internal',
          },
        };
      } else {
        mutation = {
          ...mutation,
          comments: {
            comment: result.comments,
            postTime: moment(new Date()).format('YYYY-MM-DD HH:MM'),
            commentType: 'Internal',
          },
        };
      }
    }
    if (result?.recievingRep && result?.recievingRep?.length > 0 && result?.recievingRep[0]?.id) {
      mutation = { ...mutation, receivingRepId: result.recievingRep[0].id };
    }
    if (result?.salesRep) {
      mutation = { ...mutation, salesRepId: result.salesRep[0].id };
    }
    if (isEdit) {
      mutation = {
        ...mutation,
        orderId: tranferDetails && tranferDetails?.id,
        orderExternalId: tranferDetails && tranferDetails?.externalId,
      };
      let attachmentMutation: any = {
        orderId: tranferDetails && tranferDetails?.id,
        orderExternalId: tranferDetails && tranferDetails?.externalId,
      };
      if (attachments && attachments.length > 0) {
        attachmentMutation = { ...attachmentMutation, attachments };
      }
      updateTransferAttachment({
        variables: attachmentMutation,
      }).then((response: any) => {
        if (
          response &&
          response?.data &&
          response?.data?.inventoryReturnAttachments &&
          response?.data?.inventoryReturnAttachments?.message === 'success' &&
          response?.data?.inventoryReturnAttachments?.externalId !== ''
        ) {
          // setSaveButton(false);
        } else {
          console.log('We had a trouble saving data to salesforce. Please try again later');
        }
      });
      updateTransferInventory({
        variables: mutation,
      }).then((response: any) => {
        if (
          response &&
          response?.data &&
          response?.data?.updateTransferInventory &&
          response?.data?.updateTransferInventory?.message === 'success' &&
          response?.data?.updateTransferInventory?.externalId !== ''
        ) {
          handleRefetchItems();
        } else {
          console.log('We had a trouble saving data to salesforce. Please try again later');
        }
      });
    } else {
      createTransferInventory({
        variables: mutation,
      })
        .then(response => {
          setLoader(true);
          if (
            response &&
            response?.data &&
            response?.data?.transferInventory &&
            response?.data?.transferInventory?.message === 'success'
          ) {
            mutation = {
              ...mutation,
              caseSFID: response?.data?.transferInventory?.sfid,
            };
            const recursiveCallApiUpdate = () => {
              createTransferInventoryAfterSfid({
                variables: mutation,
              })
                .then(response => {
                  if (response?.data !== null) {
                    handleRefetchItems();
                    setWarningMessage(false);
                    setLoader(false);
                    const externalId = response?.data?.transferInventoryAfterSfid?.externalId;
                    history.push('/inventoryTransferDetails', {
                      externalId,
                      transferType: 'Sent',
                      salesRepValue: '',
                      recivingSalesRepValue: '',
                      creatdType: transferType,
                    });
                  }
                })
                .catch(error => {
                  if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                    recursiveCallApiUpdate();
                  } else {
                    setLoader(false);
                    openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                  }
                });
            };
            recursiveCallApiUpdate();
          } else {
            console.log('We had a trouble saving data to salesforce. Please try again later');
          }
        })
        .catch(error => {
          if (error.message.toLowerCase().includes('disposition issue')) {
            setWarningMessage(true);
            const partsIssue = error.message;
            setPartsErrorIssue(partsIssue);
            getSubInventoryWithLotDetails();
            setLoading(true);
            if (subInventoryWithLotDetails) {
              setLoading(false);
            }
          } else openSnackBar(NOTIFY_TYPE.ERROR, 'Error: submission error.');
          // eslint-disable-next-line no-console
          console.log(error); // TODO change when some operation needs to be run on error.
        });
    }
  };

  const handleAccountSelection = useCallback(
    (accountSelectedValue, name) => {
      if (name === 'sourceAccount') {
        setAccountSelected(accountSelectedValue);
      }
      if (name === 'destinationAccount') {
        setAccountDesSelected(accountSelectedValue);
      }
    },
    [setAccountSelected, setAccountDesSelected]
  );

  const handleRecievingRepSelection = useCallback(
    repSelectedValue => {
      setRepSelected(repSelectedValue);
    },
    [setRepSelected]
  );

  const handleSalesRepSelection = useCallback(
    repSelectedValue => {
      if (repSelectedValue && repSelectedValue.length > 0) {
        setSalesRepSelected(repSelectedValue[0].id);
        setSalesReps(repSelectedValue[0]);
      } else {
        setSalesRepSelected(null);
        setSalesReps(null);
      }
    },
    [setSalesRepSelected, setSalesReps]
  );

  const handleEventSelection = useCallback(
    eventValue => {
      if (eventValue && eventValue?.length > 0) {
        setEventSelect(eventValue[0]?.id);
      } else {
        setEventSelect(null);
      }
    },
    [setEventSelect]
  );

  const handleUploadFiles = async (event: any): Promise<void> => {
    const { files } = event;
    const file = files[0];
    setUploadInProgress(true);
    setLoading(true);
    const response: BlobFile = await uploadFileToBlobStorage(file);
    if (response) {
      const fileToUpload = {
        manual: true,
        name: response?.blobName || file.name,
        imageurl: response?.imageurl.split('?')[0] || '',
      };
      setUploadFiles([...uploadedFiles, fileToUpload]);
      setUploadInProgress(false);
      setLoading(false);
    }
  };

  const handleDeleteAttachments = (file: any): void => {
    if (file.manual) {
      const filteredData = uploadedFiles.filter(
        (item: any) => JSON.stringify(item) !== JSON.stringify(file)
      );
      setUploadFiles(filteredData);
    } else {
      deleteAttachment({ variables: { id: file?.id } }).then(() => {
        const filteredData = uploadedFiles.filter(
          (item: any) => JSON.stringify(item) !== JSON.stringify(file)
        );
        setUploadFiles(filteredData);
      });
    }
  };

  const handleSave = (result: any): void => {
    handleSaveRequest(result);
  };

  const getFormFieldValues = useCallback(() => {
    if (
      (eventUsage && transferType.toLowerCase().includes('customer')) ||
      transferType === 'Event Transfer'
    ) {
      return {
        sourceAccount: [
          {
            id: editDetails?.accountId ? editDetails?.accountId : sourceAccount[0].id,
            label: editDetails?.accountId
              ? `${editDetails?.accountNumber} ${editDetails?.accountName}`
              : '',
            number: editDetails?.accountNumber || '',
            isRepStockAccount:
              editDetails?.accountNumber?.startsWith('E') ||
              editDetails?.accountNumber?.startsWith('IT') ||
              editDetails?.accountNumber?.startsWith('IH'),
          },
        ],
      };
    }
    if (tranferDetails) {
      const data: any = {
        salesRep: [
          {
            id: tranferDetails.salesRepId,
            label: tranferDetails.salesRepName,
          },
        ],
        ...(tranferDetails?.type === TRANSFER_LIST.REP_TRANSFER && {
          recievingRep: [
            {
              id: tranferDetails.receivingRepId,
              label: tranferDetails.receivingRepName,
            },
          ],
        }),
        Attachments: [
          {
            id: tranferDetails.attachments.length > 0 ? tranferDetails.attachments[0].imageurl : '',
            label: tranferDetails.attachments.length > 0 ? tranferDetails.attachments[0].name : '',
          },
        ],
        ...(tranferDetails.comments &&
          tranferDetails.comments.length && {
            comments: getInternalComment(tranferDetails)?.[0]?.value,
            externalId: getInternalComment(tranferDetails)?.[0]?.externalId,
          }),
      };

      if (destAccount && destAccount.length > 0 && sourceAccount && sourceAccount.length > 0) {
        data.destinationAccount = [
          {
            id: destAccount[0].id,
            label: destAccount[0].isRepStockAccount
              ? `* ${destAccount[0].number} ${destAccount[0].value}`
              : `${destAccount[0].number} ${destAccount[0].value}`,
            number: destAccount[0].number,
            isRepStockAccount:
              destAccount[0]?.number?.startsWith('E') ||
              destAccount[0]?.number?.startsWith('IT') ||
              destAccount[0]?.number?.startsWith('IH'),
          },
        ];
        data.sourceAccount = [
          {
            id: sourceAccount[0]?.id,
            label: sourceAccount[0]?.isRepStockAccount
              ? `* ${sourceAccount[0]?.number} ${sourceAccount[0]?.value}`
              : `${sourceAccount[0]?.number} ${sourceAccount[0]?.value}`,
            number: sourceAccount[0]?.number,
            isRepStockAccount:
              sourceAccount[0]?.number?.startsWith('E') ||
              sourceAccount[0]?.number?.startsWith('IT') ||
              sourceAccount[0]?.number?.startsWith('IH'),
          },
        ];
      }

      return data;
    }

    return {
      franchise: [],
    };
  }, [tranferDetails, sourceAccount, destAccount]);

  const handleTabChange = (event: any): void => {
    setSelectedTab(event.label);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedTab('Details');
    }
  }, [isOpen]);

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <Modal
        className="form-engine-modal"
        isOpen={isOpen}
        onRequestClose={apiloading ? '' : handleCancelButton}
        ariaHideApp
        heading={
          <div className="slds-float_left slds-text-title_bold">
            {/* {inventoryDetail && isClone
              ? `Clone - ${eventType}` || '--'
              : inventoryDetail
              ? `Edit - ${eventType}` || '--'
              : eventType} */}
            {isEdit ? `Edit ${transferType}` : `New ${transferType}`}
          </div>
        }
        dismissOnClickOutside={false}
      >
        <section className="slds-p-around_medium slds-theme_shade" key="form">
          {data && (
            <FormEngine
              formItems={formJson()}
              division="EU"
              apiData={apiDataObj}
              useLazyQueryObject={lazyQueryObject}
              handleSave={handleSave}
              toggleOpen={toggleOpen}
              formValues={getFormFieldValues()}
              isOpen={isOpen}
              handleCancelButton={handleCancelButton}
              createEventLoading={
                loading || createTransferInventoryLoading || repStockLoading || recRepStockLoading
              }
              createRequest={apiloading}
              handleAccountSelection={handleAccountSelection}
              handleRecievingRepSelection={handleRecievingRepSelection}
              handleSalesRepSelection={handleSalesRepSelection}
              handleUploadFiles={handleUploadFiles}
              handleDeleteAttachments={handleDeleteAttachments}
              uploadedFiles={uploadedFiles}
              selectedTab={selectedTab}
              partsData={subInventoryWithLotDetails}
              handleTabChange={handleTabChange}
              eventUsage={eventUsage}
              warning={warning}
              partsIssue={partsIssue}
              isEdit={tranferDetails}
              modalHeading={transferType}
              isUserOpsCsr={isUserOpsCsr}
              type={
                // eslint-disable-next-line no-cond-assign, no-param-reassign
                !!(tranferDetails && tranferDetails.status === 'Approved')
              }
            />
          )}
        </section>
      </Modal>
    </IconSettings>
  );
};

/** React component for Inventory Request. */
export default withRouter(NewRepTransfer);
