/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/camelcase */

/**
 * Module defining all the constants.
 * @module src/util/constants
 */
/* eslint-disable import/prefer-default-export */
import moment from 'moment';

const { REACT_APP_GRAPHQL_URL } = process.env;

export const PLACEHOLDER = true;

export const API = {
  MASTER_DATA: {
    EMAIL_SERVICE: `${REACT_APP_GRAPHQL_URL?.replace('/graphql', '')}/services/sendEmail`,
  },
};

export const eventTypesArr = [
  { name: 'myEvents', label: 'My Events' },
  { name: 'assignedEvents', label: 'Assigned Events' },
  { name: 'teamEvents', label: 'Team Events' },
];

export const accountTypesArr = [
  { name: 'myAccounts', label: 'My Accounts' },
  { name: 'tbaAccounts', label: 'TBA Accounts' },
];

export const labelMapping: any = {
  'Event Transfer - Create': 'Event Transfer',
  'Customer Transfer - Create': 'Customer Transfer',
  'Pickup Return - Create': 'Pickup Return',
  'Standard Return - Create': 'Standard Return',
};

export const eventFormsMapping: any = {
  'Standard Demo - Create': 'Standard Demo Event',
  'Show-n-Go - Create': 'Show-n-Go Event',
  'Trial - Create': 'Trial Event',
  'Surgery - Create': 'Surgery Event',
  'Mock OR - Create': 'Mock OR Event',
};

export const demoFormsMapping: any = {
  'Standard Demo - Create': 'Standard Demo Event',
  'Show-n-Go - Create': 'Show-n-Go Event',
  'Mock OR - Create': 'Mock OR Event',
};

export const labelMappingTransfer: any = {
  'Rep Transfer - Create': 'Rep Transfer',
  'Customer Transfer - Create': 'Customer Transfer',
};

export const FormInputTypes = {
  SELECT_SINGLE_DYNAMIC_PICKER: 'selectSingleDynamicPicker',
  SELECT_MULTI_DYNAMIC_PICKER: 'selectMultiDynamicPicker',
  SELECT_SINGLE_DYNAMIC_AUTO_COMPLETE_PICKER: 'selectSingleDynamicAutoCompletePicker',
  SELECT_MULTI_DYNAMIC_AUTO_COMPLETE_PICKER: 'selectMultiDynamicAutoCompletePicker',
  FILTER_SINGLE_DYNAMIC_AUTO_COMPLETE_PICKER: 'filterSingleDynamicAutoCompletePicker',
  COMMENTS_AREA: 'comments',
  TOGGLE_SWITCH: 'toogleSwitch',
  RADIO: 'radio',
  INPUT: 'input',
  TEXT_AREA: 'textArea',
  TEXT: 'text',
  DATE_PICKER: 'datePicker',
  TIME_PICKER: 'timePicker',
  DATE_TIME_PICKER: 'dateTimePicker',
  ATTACHMENTS: 'Attachments',
  TAB_NAVIGATOR: 'tabNavigator',
  TEXT_BOX: 'textBox',
  STATIC: 'static',
};

export const SURGERY_EVENT_TYPE = 'Surgery';
export const SURGERY_USAGE_EVENT_TYPE = 'Surgery-Usage Only';
export const WORKSHOP_EVENT_TYPE = 'Workshop Events';
export const TRIAL_EVENT_TYPE = 'Trial';
export const MOCK_OR_EVENT_TYPE = 'Mock OR';
export const DEMO_EVENT_TYPE = 'Demo';
export const CYCLECOUNT_EVENT_TYPE = 'Cycle Count';
export const INVENTORY_REQUEST = 'Inventory';
export const INVENTORY_RETURN = 'Inventory Return';
export const ORDERS = 'Orders';
export const PERSONAS_SALES_RESP = 'Sales Rep';
export const CYCLE_COUNT = 'Cycle Count';
export const EXCLAMATION = 'Exclamation';
export const EMPTY_COMMENTS = 'Empty_Comments';
export const COMMENTS = 'Comments';
export const INVENTORY_REQUEST_TITLE = 'request inventory';
export const EVENT_TRANSFER = 'Event to Event';

export const EVENT_TYPES = [
  { label: 'Demo', value: 'Demo' },
  { label: 'Surgery', value: 'Surgery' },
  // { label: 'Surgery - Usage Only', value: 'Surgery-Usage Only' },
  { label: 'Trial', value: 'Trial' },
  { label: 'Show-n-Go', value: 'Show-n-Go' },
  // { label: 'Workshop', value: 'Workshop Events', type: 'header' },
  // { type: 'divider' },
  // { label: 'Cadaver Lab', value: 'Workshop Events', subType: 'Workshop - Cadaver Lab' },
  // { label: 'Stryker Congress', value: 'Workshop Events', subType: 'Workshop - Stryker Congress' },
  // {
  //   label: 'Third Party Congress',
  //   value: 'Workshop Events',
  //   subType: 'Workshop - Third Party Congress',
  // },
  // { label: 'Sawbones Workshop', value: 'Workshop Events', subType: 'Workshop - Sawbones' },
  // { label: 'Marketing', value: 'Workshop Events', subType: 'Workshop - Marketing' },
  // { label: 'Others', value: 'Workshop Events', subType: 'Workshop - Others' },
];

export const FILTER_EVENT_TYPES = [
  { label: 'Surgery', value: 'Surgery', id: 'Surgery' },
  { label: 'Trial', value: 'Trial', id: 'Trial' },
  { label: 'Standard Demo', value: 'Standard Demo', id: 'Standard Demo' },
  { label: 'Show-n-Go', value: 'Show-n-Go', id: 'Show-n-Go' },
  { label: 'Mock OR', value: 'Mock OR', id: 'Mock OR' },
  { label: 'Cycle Count', value: 'Cycle Count', id: 'Cycle Count' },
];

export const FILTER_ORDER_TYPES = [
  { label: 'Bill Only', value: 'Bill Only', id: 'Bill Only' },
  { label: 'Ship & Bill', value: 'Ship & Bill', id: 'Ship & Bill' },
];

export const ORDER_TYPE = {
  SJC_SM_BILL_ONLY: 'SJC SM Bill Only',
  SM_SHIP_AND_BILL: 'SM Ship & Bill',
};
export const USAGE_TYPE = {
  BILL_ONLY: 'Bill Only',
  SHIP_AND_BILL: 'Ship & Bill',
  INVENTORY_RETURN: 'Inventory Return',
  INVENTORY_REQUEST: 'Inventory Request',
};
export const EVENT_TYPE = {
  SURGERY: 'Surgery',
  DEMO: 'Demo',
  TRIAL: 'Trial',
};

export const tabNavigatorOption = [{ value: 'details', label: 'Details' }];

export const BUSINESSUNIT = [
  { label: 'Sports Medicine', key: 'Sports Med' },
  { label: 'Neurovascular', key: 'NV' },
  { label: 'Acute Care', key: 'Acute Care' },
  { label: 'Emergency Care', key: 'Emergency Care' },
  { label: 'Sage', key: 'Sage' },
];

export const NonSalesRep = [
  { label: 'Branch Op', value: 'Branch Op' },
  { label: '3PL Ops', value: '3PL Ops' },
  { label: 'Agency OPS', value: 'Agency OPS' },
  { label: 'Sales Manager', value: 'Sales Manager' },
  { label: 'Agency OPS;Agency CSR', value: 'Agency OPS;Agency CSR' },
  { label: 'CSR;Branch Op', value: 'CSR;Branch Op' },
  { label: 'Associate Sales Rep', value: 'Associate Sales Rep' },
  { label: 'Business Admin', value: 'Business Admin' },
  { label: 'Sales Associate', value: 'Sales Associate' },
  { label: 'Branch Op;Business Admin', value: 'Branch Op;Business Admin' },
  { label: 'CSR;Branch Op;Business Admin', value: 'CSR;Branch Op;Business Admin' },
  { label: 'Business Admin;CSR;Branch Op;', value: 'Business Admin;CSR;Branch Op;' },
];

export const onHoldUsers = [
  { label: 'Sales Rep', value: 'Sales Rep' },
  { label: 'Sales Manager', value: 'Sales Manager' },
  { label: 'Agency Sales Rep', value: 'Agency Sales Rep' },
  { label: 'Sales Associate', value: 'Sales Associate' },
];

export const holdTypes = [
  {
    'CC Hold': 'Cycle Count Hold',
    'Custom Hold': 'Custom Hold',
    'Regulatory Noncompliance': 'Regulatory Noncompliance',
    'Trial Hold': 'Trial Hold',
    'Sunshine Act Hold': 'Sunshine Act Hold',
  },
];

export const PERSONA_CONDITION = ['op', 'ops', 'csr', 'business'];
export const PERSONA_CONDITION_OPS_CSR = ['op', 'ops', 'csr'];

export const EVENT_FORM_LIST = [
  {
    label: 'Inventory Request',
    value: 'Inventory Request',
  },
  {
    label: 'Bill Only',
    value: 'Bill Only',
  },
  {
    label: 'Standard Return',
    value: 'Standard Return',
  },
  {
    label: 'Pickup Return',
    value: 'Pickup Return',
  },
  {
    label: 'Customer Transfer',
    value: 'Customer Transfer',
  },
  {
    label: 'Event Transfer',
    value: 'Event Transfer',
  },
];

export const EVENT_FORM_LIST_MOCK_OR = [
  {
    label: 'Inventory Request',
    value: 'Inventory Request',
  },
  {
    label: 'Standard Return',
    value: 'Standard Return',
  },
  {
    label: 'Pickup Return',
    value: 'Pickup Return',
  },
  {
    label: 'Customer Transfer',
    value: 'Customer Transfer',
  },
  {
    label: 'Event Transfer',
    value: 'Event Transfer',
  },
];

export const EVENTS_LIST = [
  {
    label: 'Surgery',
    value: 'Surgery',
  },
  {
    label: 'Trial',
    value: 'Trial',
  },
  {
    label: 'Mock OR',
    value: 'Mock OR',
  },
  {
    label: 'Show-n-Go',
    value: 'Show-n-Go',
  },
  {
    label: 'Standard Demo',
    value: 'Demo',
  },
];

export const TRANSFER_FORM_LIST = [
  {
    label: 'Customer Transfer',
    value: 'Customer Transfer',
  },
  {
    label: 'Rep Transfer',
    value: 'Rep Transfer',
  },
];

export const ONHOLD_OPTIONS_OPS = [
  { label: 'Cycle Count Hold', value: 'Cycle Count Hold' },
  { label: 'Regulatory Noncompliance', value: 'Regulatory Noncompliance' },
];

export const INVENTORY_ACTION = [
  { label: 'Edit', value: 'Edit' },
  { label: 'Submit', value: 'Submit' },
  { label: 'Cancel Transfer', value: 'Cancel Transfer' },
  { label: 'Discrepancy', value: 'Discrepancy' },
];
export const TRANSFER_ACTIONS = [
  { value: 'Approved', name: 'Approve' },
  { value: 'Rejected', name: 'Reject' },
  { value: 'No Action', name: 'No Action' },
];

export const TRANSFER_ACTIONS_APPROVAL = [
  { value: 'Approved', label: 'Approve' },
  { value: 'Rejected', label: 'Reject' },
  { value: 'No Action', label: 'No Action' },
];

export const COUNT_TYPE_ACTIONS = ['Blind'];
export const COUNT_TYPE_ACTIONS_NON_SM = ['Blind', 'Comparative'];

export const FILTER_CYCLE_COUNT = [
  { label: 'Blind', key: 'Blind' },
  { label: 'Comparative', key: 'Comparative' },
];

export const SPORTS_MED = 'Sports Medicine';

export const NOTIFICATIONS_RADIO_OPTIONS = [
  { label: 'Both', key: 'both' },
  { label: 'In App', key: 'inApp' },
  { label: 'Email', key: 'email' },
  { label: 'Off', key: 'off' },
];

export const NOTIFICATIONS_OPTIONS = [
  {
    id: null,
    category: 'Inventory',
    subCategory: 'Inventory Expiration',
    both: true,
    inApp: false,
    email: false,
    off: false,
  },
  {
    id: null,
    category: 'Inventory',
    subCategory: 'Commercial Hold',
    both: true,
    inApp: false,
    email: false,
    off: false,
  },
  {
    id: null,
    category: 'Events',
    subCategory: 'Sunshine Act Compliance',
    both: true,
    inApp: false,
    email: false,
    off: false,
  },
  {
    id: null,
    category: 'Events',
    subCategory: 'Other Setting Option 1',
    both: true,
    inApp: false,
    email: false,
    off: false,
  },
  {
    id: null,
    category: 'Events',
    subCategory: 'Other Setting Option 2',
    both: true,
    inApp: false,
    email: false,
    off: false,
  },
  {
    id: null,
    category: 'Orders',
    subCategory: 'Ops Non Approval',
    both: true,
    inApp: false,
    email: false,
    off: false,
  },
  {
    id: null,
    category: 'Orders',
    subCategory: 'Integration Failure',
    both: true,
    inApp: false,
    email: false,
    off: false,
  },
];

export const SURGEON_EVENT_TYPES = [
  { label: 'Surgery', value: 'Surgery' },
  // { label: 'Surgery - Usage Only', value: 'Surgery-Usage Only' },
];
export const ACUTE_CARE_EVENT_TYPES = [
  { label: 'Show-n-Go', value: 'Show-n-Go' },
  { label: 'Trial', value: 'Trial' },
  // { label: 'Surgery - Usage Only', value: 'Surgery-Usage Only' },
];
export const NV_EVENT_TYPES = [
  { label: 'Demo', value: 'Demo' },
  { label: 'Surgery', value: 'Surgery' },
  { label: 'Trial', value: 'Trial' },
  // { label: 'Surgery - Usage Only', value: 'Surgery-Usage Only' },
];
export const ER_SUB_TYPE_EVENT_TYPES = [
  { label: 'Demo', value: 'Demo' },
  { label: 'Show-n-Go', value: 'Show-n-Go' },
  { label: 'Standard Demo', value: 'Demo' },
  { label: 'Trial', value: 'Trial' },
  // { label: 'Surgery - Usage Only', value: 'Surgery-Usage Only' },
];

export const ER_EVENT_TYPES = [
  { label: 'Demo', value: 'Demo' },
  { label: 'Trial', value: 'Trial' },
  // { label: 'Surgery - Usage Only', value: 'Surgery-Usage Only' },
];

export const EVENT_TABS = {
  ALL: 0,
  REQUESTED: 1,
};

export const EVENT_DETAIL_TABS = {
  DETAILS: 0,
  INVENTORY: 1,
  USAGE: 2,
  RELATED: 3,
};

export const CSR_COMMENTS_TAB = {
  INTERNAL: 0,
  EXTERNAL: 1,
  CUSTOMER: 2,
};

export const SURGEON_COMMENTS_TAB = {
  CUSTOMER: 0,
  EXTERNAL: 1,
};

export const EVENT_STATUS = {
  NEW: 'New',
  ASSIGNED: 'Assigned',
  REQUESTED: 'Requested',
  SHIPPED: 'Shipped',
  PORTAL: 'Portal',
  COMPLETED: 'Completed',
  CLOSED: 'Closed',
  CANCELLED: 'Cancelled',
  CANCEL: 'Cancel',
  RETURNING: 'Returning',
  INPROGRESS: 'In Progress',
  COMPLETE: 'Complete',
};

export const ORDER_STATUS = {
  SUBMITTED: 'Submitted',
  BOOKED: 'Booked',
  COMPLETED: 'Completed',
  COMPLETE: 'Complete',
  FAILED: 'Failed',
};

export const LOT_STATUS = {
  EXPIRED: 'Expired',
  HOLD: 'Hold',
  RECALL: 'Recall',
};

export const DATE_VIEW_FORMATTER = 'yyyy-MM-dd HH:mm:ss';

export const FILTER_PRESETS = [
  { label: 'Filter Preset Name 01', value: 'Filter Preset Name 01' },
  { label: 'Filter Preset Name 02', value: 'Filter Preset Name 02' },
  { label: 'Filter Preset Name 03', value: 'Filter Preset Name 03' },
  { label: 'Filter Preset Name 04', value: 'Filter Preset Name 04' },
];

export const LIMIT = 50;
export const FILTER_LIST_LIMIT = 500;
export const DEFAULT_OFFSET = 0;
export const NEXT_DATA = 'Next Data';
export const PREV_DATA = 'Prev Data';
export const MONTH_FORMATTER = 'MMMM yyyy';
export const DAY_FORMATTER = 'EEEE MMMM d, yyyy';

export const REGION = 'EU';

export const AUTO_SELECT_FIELDS = ['billTo', 'shipTo', 'numberOfParticipants'];

export const REPLENISH_LOCATIONS_US = [
  {
    id: 'home',
    label: 'Home',
  },
  {
    id: 'branch',
    label: 'Branch',
  },
  {
    id: 'account',
    label: 'Account',
  },
];

export const EVENTS_TYPE_OPTIONS = [
  {
    label: 'Trial Event',
    id: 'Trial',
  },
  {
    label: 'Standard Demo Event',
    id: 'Demo',
  },
  {
    label: 'Show-n-Go Event',
    id: 'Show-n-Go',
  },
  {
    label: 'Surgery Event',
    id: 'Surgery',
  },
  { label: 'Mock OR', id: 'Mock OR' },
];

export const DAYS_AT_EVENTS_OPTIONS = [
  {
    label: '<60 Days',
    id: '<60',
  },
  {
    label: '>=60 Days',
    id: '>=60',
  },
  {
    label: '>=75 Days',
    id: '>=75',
  },
  {
    label: '>=90 Days',
    id: '>=90',
  },
];

export const FILTER_EVENT_STATUS = [
  {
    id: 'New',
    label: 'New',
  },
  {
    id: 'Assigned',
    label: 'Assigned',
  },
  {
    id: 'Requested',
    label: 'Requested',
  },
  {
    id: 'In Progress',
    label: 'In Progress',
  },
  {
    id: 'Shipped',
    label: 'Shipped',
  },
  {
    id: 'Completed',
    label: 'Completed',
  },
  {
    id: 'Closed',
    label: 'Closed',
  },
  {
    id: 'Cancelled',
    label: 'Cancelled',
  },
];

export const FILTER_ORDER_STATUS = [
  {
    id: 'New',
    label: 'New',
  },
  {
    id: 'Requested',
    label: 'Requested',
  },
  {
    id: 'Submitted',
    label: 'Submitted',
  },
  {
    id: 'Entered',
    label: 'Entered',
  },
  {
    id: 'Booked',
    label: 'Booked',
  },
  {
    id: 'Completed',
    label: 'Completed',
  },
  {
    id: 'Cancelled',
    label: 'Cancelled',
  },
  {
    id: 'Closed',
    label: 'Closed',
  },
  {
    id: 'Failed',
    label: 'Failed',
  },
];

export const FILTER_ORDER_REVIEW_STATUS = [
  {
    id: 'Requested',
    label: 'Requested',
  },
  {
    id: 'Failed',
    label: 'Failed',
  },
];

export const FILTER_ORDER_REVIEW_CATALOGED_PARTS_OPTIONS = [
  {
    id: 'yes',
    label: 'Yes',
  },
  {
    id: 'no',
    label: 'No',
  },
];

export const FILTER_ORDER_REVIEW_RECORD_TYPE_OPTIONS = [
  {
    id: 'Bill Only',
    label: 'Bill Only',
  },
  {
    id: 'Ship & Bill',
    label: 'Ship & Bill',
  },
];

export const FILTER_CYCLECOUNT_STATUS = [
  {
    id: 'New',
    label: 'New',
  },
  {
    id: 'In Progress',
    label: 'In Progress',
  },
  {
    id: 'Submitted',
    label: 'Submitted',
  },
  {
    id: 'Completed',
    label: 'Completed',
  },
  {
    id: 'Cancelled',
    label: 'Cancelled',
  },
];

export const FILTER_NOTIFICATIONS_TYPE = [
  {
    id: 'Events',
    label: 'Events',
  },
  {
    id: 'Inventory',
    label: 'Inventory',
  },
  {
    id: 'Orders',
    label: 'Orders',
  },
];

export const READ_NOTIFICATION_FILTERS = [
  { label: 'All', key: 'All' },
  { label: 'Unread only', key: 'unreadOnly' },
  { label: 'Read only', key: 'readOnly' },
];

export const FLAG_FILTERS = [
  { label: 'All', key: 'All' },
  { label: 'Not Flagged', key: 'unflagged' },
  { label: 'Flagged only', key: 'flagged' },
];

export const FILTER_INVENTORY_FAILED_STATUS = [
  {
    id: 'Failed',
    label: 'Failed',
  },
];

export const FILTER_INVENTORY_REQUEST_STATUS = [
  {
    id: 'New',
    label: 'New',
  },
  {
    id: 'Requested',
    label: 'Requested',
  },
  {
    id: 'Cancelled',
    label: 'Cancelled',
  },
];

export const FILTER_TRANSFER_TYPE = [
  {
    id: 'Rep to Rep',
    label: 'Rep to Rep',
  },
  {
    id: 'Event to Event',
    label: 'Event to Event',
  },
  {
    id: 'Rep to Customer',
    label: 'Rep to Customer',
  },
  {
    id: 'Customer to Rep',
    label: 'Customer to Rep',
  },
];

export const FILTER_INVENTORY_REQUEST_TYPE = [
  {
    id: 'Standard Return',
    label: 'Standard Return',
  },
  {
    id: 'Pickup Return',
    label: 'Pickup Return',
  },
];

export const FILTER_TRANSFER_STATUS = [
  [
    {
      id: 'Cancelled',
      label: 'Cancelled',
    },
    {
      id: 'New',
      label: 'New',
    },
    {
      id: 'Requested',
      label: 'Requested',
    },
  ],
  [
    {
      id: 'New',
      label: 'New',
    },
    {
      id: 'Requested',
      label: 'Requested',
    },
  ],
  [
    {
      id: 'Failed',
      label: 'Failed',
    },
  ],
  [
    {
      id: 'Complete',
      label: 'Completed',
    },
    {
      id: 'Submitted',
      label: 'Submitted',
    },
    {
      id: 'Closed',
      label: 'Closed',
    },
  ],
];

export const FILTER_INVENTORY_RETURNS_STATUS = [
  {
    id: 'New',
    label: 'New',
  },
  {
    id: 'Requested',
    label: 'Requested',
  },
  {
    id: 'Cancelled',
    label: 'Cancelled',
  },
  {
    id: 'Label Ready',
    label: 'Label Ready',
  },
  {
    id: 'Label Requested',
    label: 'Label Requested',
  },
];

export const FILTER_INVENTORY_COMPLETED_STATUS = [
  {
    id: 'Booked',
    label: 'Booked',
  },
  {
    id: 'Cancelled',
    label: 'Cancelled',
  },
  {
    id: 'Closed',
    label: 'Closed',
  },
  {
    id: 'Completed',
    label: 'Completed',
  },
  {
    id: 'Entered',
    label: 'Entered',
  },
  {
    id: 'Partial',
    label: 'Partial',
  },
  {
    id: 'Submitted',
    label: 'Submitted',
  },
];

export const FILTER_INVENTORY_APPROVED_STATUS = [
  {
    id: 'Failed',
    label: 'Approved',
  },
];

export const FILTER_INVENTORY_RETURNS_STATUSES = [
  {
    id: 'New',
    label: 'New',
  },
  {
    id: 'Requested',
    label: 'Requested',
  },
  {
    id: 'Cancelled',
    label: 'Cancelled',
  },
  {
    id: 'Label Ready',
    label: 'Label Ready',
  },
  {
    id: 'Label Requested',
    label: 'Label Requested',
  },
];

export const FILTER_INVENTORY_RETURNS_COMPLETED_STATUS = [
  {
    id: 'Shipped',
    label: 'Shipped',
  },
  {
    id: 'Closed',
    label: 'Closed',
  },
  {
    id: 'Cancelled',
    label: 'Cancelled',
  },
  {
    id: 'Completed',
    label: 'Completed',
  },
];

export const INVENTORY_ADD_DROPDOWN = [
  { label: 'Search', value: 'search' },
  // { label: 'Favorites', value: 'favorites' },
];

export const TRANSFER_ADD_DROPDOWN = [{ label: 'Search', value: 'search' }];

export const INVENTORY_RETURN_DROPDOWN = [{ label: 'Search', value: 'search' }];

export const OPS_ACTION_TYPE = {
  REVIEW: 'Review',
  ASSIGN: 'Assign',
};

export const WORKFLOW_ACTION = {
  APPROVE: 'Approve',
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  MANUAL: 'Manual',
  NO_ACTION: 'No Action',
  CANCEL: 'Cancel',
};

export const RETURN_TYPE_ACTION = {
  RETURN: 'Return',
  WRITE_IN: 'Write In',
  WRITE_OFF: 'Write Off',
};

export const WORKFLOW_ACTION_OPTIONS = [
  { label: WORKFLOW_ACTION.APPROVE, value: WORKFLOW_ACTION.APPROVE },
  { label: WORKFLOW_ACTION.REJECT, value: WORKFLOW_ACTION.REJECT },
  // { label: WORKFLOW_ACTION.NO_ACTION, value: WORKFLOW_ACTION.NO_ACTION },
  { label: 'Manual', value: WORKFLOW_ACTION.MANUAL },
];

export const RETURNS_WORKFLOW_ACTION_OPTION = [
  { label: WORKFLOW_ACTION.APPROVE, value: WORKFLOW_ACTION.ACCEPT },
  { label: WORKFLOW_ACTION.REJECT, value: WORKFLOW_ACTION.REJECT },
  { label: WORKFLOW_ACTION.CANCEL, value: WORKFLOW_ACTION.CANCEL },
  { label: 'Manual', value: WORKFLOW_ACTION.MANUAL },
];

export const RETURNS_TYPE_OPTION = [
  // { label: RETURN_TYPE_ACTION.RETURN, value: RETURN_TYPE_ACTION.RETURN },
  // { label: RETURN_TYPE_ACTION.WRITE_IN, value: RETURN_TYPE_ACTION.WRITE_IN },
  // { label: RETURN_TYPE_ACTION.WRITE_OFF, value: RETURN_TYPE_ACTION.WRITE_OFF },
  { label: 'Transfer to Warehouse', value: 'Transfer to Warehouse' },
  // { label: 'Write In', value: 'Write-In' },
  { label: 'Write Off - SM Sample', value: 'Write Off - SM Sample' },
  { label: 'Write Off - SM Damage/Scrap', value: 'Write Off - SM Damage/Scrap' },
  { label: 'Cancel', value: 'Cancel' },
];

export const RETURNS_TYPE_OPTION_NON_SM = [
  { label: 'Transfer to Warehouse', value: 'Transfer to Warehouse' },
  { label: 'Cancel', value: 'Cancel' },
];

export const RETURNS_REASON_OPTION = [
  { id: 'Expired', value: 'Expired', label: 'Expired' },
  { id: 'No Longer Needed', value: 'No Longer Needed', label: 'No Longer Needed' },
  { id: 'No Longer Sterile', value: 'No Longer Sterile', label: 'No Longer Sterile' },
  { id: 'Not on Sub Inventory', value: 'Not on Sub Inventory', label: 'Not on Sub Inventory' },
];

export const RETURNS_REASON_TYPE = [
  { id: 'Transfer to Warehouse', value: 'Transfer to Warehouse', label: 'Transfer to Warehouse' },
  { id: 'Write Off - SM Sample', value: 'Write Off - SM Sample', label: 'Write Off - SM Sample' },
  {
    id: 'Write Off - SM Damage/Scrap',
    value: 'Write Off - SM Damage/Scrap',
    label: 'Write Off - SM Damage/Scrap',
  },
  { id: 'Cancel', value: 'Cancel', label: 'Cancel' },
];

export const SHIP_METHODS = {
  UPS_PARCEL_GROUND: 'UPS-Parcel-Ground',
  UPS_PARCEL_NEXT_DAY_AIR: 'UPS-Parcel-Next Day Air',
  UPS_PARCEL_NEXT_DAY_AIR_EARLY_AM: 'UPS-Parcel-Next Day Air Early A.M.',
  UPS_PARCEL_NEXT_DAY_AIR_SAVER: 'UPS-Parcel-Next Day Air Saver',
  UPS_PARCEL_OVERNIGHT_SATURDAY: 'UPS-Parcel-Overnight Saturday',
  UPS_PARCEL_2ND_DAY_AIR: 'UPS-Parcel-2nd Day Air',
  UPS_PARCEL_2ND_DAY_AIR_EARLY_AM: 'UPS-Parcel-2nd Day Air Early A.M.',
  UPS_PARCEL_3_DAY_SELECT: 'UPS-Parcel-3-Day Select',
  FDX_PARCEL_SECOND_DAY: 'FDX-Parcel-Second Day',
  FDX_PARCEL_FIRST_OVERNIGHT: 'FDX-Parcel-First Overnight',
  FDX_PARCEL_GROUND: 'FDX-Parcel-Ground',
  FDX_PARCEL_PRIORITY_OVERNIGHT: 'FDX-Parcel-Priority Overnight',
  FDX_PARCEL_OVERNIGHT_SATURDAY: 'FDX-Parcel-Overnight Saturday',
  FDX_PARCEL_STD_OVERNIGHT: 'FDX-Parcel-Standard Overnight',
  FDX_PARCEL_EXPRESS_SAVER: 'FDX-Parcel-Express Saver',
  FED_FIRST_8AM: 'FED FIRST 8am',
  FED_P1_Next_Day: 'FED P1 Next Day 10:30am',
  FED_P2_Next_Day: 'FED P2 Next Day 3pm',
  FEDERAL_P1_HOLD_FOR_PU: 'FEDERAL P1 HOLD FOR P/U',
  FEDERAL_P1_SATURDAY: 'FEDERAL P1 SATURDAY',
  PICKUP_AT_MAHWAH: 'PICKUP AT MAHWAH',
  UPS_Next_Day_Air: 'UPS Next Day Air',
  THREEPL: '3PL Delivery',
};

export const SHIP_METHOD_OPTIONS = [
  {
    value: SHIP_METHODS.FDX_PARCEL_GROUND,
    label: SHIP_METHODS.FDX_PARCEL_GROUND,
  },
  {
    value: SHIP_METHODS.FDX_PARCEL_PRIORITY_OVERNIGHT,
    label: SHIP_METHODS.FDX_PARCEL_PRIORITY_OVERNIGHT,
  },
  {
    value: SHIP_METHODS.FDX_PARCEL_STD_OVERNIGHT,
    label: SHIP_METHODS.FDX_PARCEL_STD_OVERNIGHT,
  },
  {
    value: SHIP_METHODS.FDX_PARCEL_EXPRESS_SAVER,
    label: SHIP_METHODS.FDX_PARCEL_EXPRESS_SAVER,
  },
  {
    value: SHIP_METHODS.FDX_PARCEL_FIRST_OVERNIGHT,
    label: SHIP_METHODS.FDX_PARCEL_FIRST_OVERNIGHT,
  },
  {
    value: SHIP_METHODS.FDX_PARCEL_OVERNIGHT_SATURDAY,
    label: SHIP_METHODS.FDX_PARCEL_OVERNIGHT_SATURDAY,
  },
  {
    value: SHIP_METHODS.FDX_PARCEL_SECOND_DAY,
    label: SHIP_METHODS.FDX_PARCEL_SECOND_DAY,
  },
  {
    value: SHIP_METHODS.UPS_PARCEL_GROUND,
    label: SHIP_METHODS.UPS_PARCEL_GROUND,
  },
  {
    value: SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR,
    label: SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR,
  },
  {
    value: SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR_EARLY_AM,
    label: SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR_EARLY_AM,
  },
  {
    value: SHIP_METHODS.UPS_PARCEL_2ND_DAY_AIR,
    label: SHIP_METHODS.UPS_PARCEL_2ND_DAY_AIR,
  },
  {
    value: SHIP_METHODS.UPS_PARCEL_2ND_DAY_AIR_EARLY_AM,
    label: SHIP_METHODS.UPS_PARCEL_2ND_DAY_AIR_EARLY_AM,
  },
  {
    value: SHIP_METHODS.UPS_PARCEL_3_DAY_SELECT,
    label: SHIP_METHODS.UPS_PARCEL_3_DAY_SELECT,
  },
  {
    value: SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR_SAVER,
    label: SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR_SAVER,
  },
  {
    value: SHIP_METHODS.UPS_PARCEL_OVERNIGHT_SATURDAY,
    label: SHIP_METHODS.UPS_PARCEL_OVERNIGHT_SATURDAY,
  },
];

export const NOTIFY_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const INVENTORY_STATUS = {
  NEW: 'New',
  ASSIGNED: 'Assigned',
  APPROVED: 'Approved',
  REQUESTED: 'Requested',
  SHIPPED: 'Shipped',
  CLOSED: 'Closed',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled',
  PARTIAL: 'Partial',
  BACKORDER: 'Backorder',
  LABELREADY: 'Label Ready',
  LABELREQUESTED: 'Label Requested',
  THREEPLREVIEW: '3PL Review',
};
export const INVENTORY_3PL = {
  YES: 'Yes',
  NO: 'No',
};

export const INVENTORY_STATUS_OPTIONS = [
  { label: INVENTORY_STATUS.NEW, value: INVENTORY_STATUS.NEW },
  { label: INVENTORY_STATUS.ASSIGNED, value: INVENTORY_STATUS.ASSIGNED },
  { label: INVENTORY_STATUS.REQUESTED, value: INVENTORY_STATUS.REQUESTED },
  { label: INVENTORY_STATUS.SHIPPED, value: INVENTORY_STATUS.SHIPPED },
  { label: INVENTORY_STATUS.CLOSED, value: INVENTORY_STATUS.CLOSED },
  { label: INVENTORY_STATUS.CANCELLED, value: INVENTORY_STATUS.CANCELLED },
  { label: INVENTORY_STATUS.APPROVED, value: INVENTORY_STATUS.APPROVED },
  { label: INVENTORY_STATUS.REJECTED, value: INVENTORY_STATUS.REJECTED },
  { label: INVENTORY_STATUS.LABELREQUESTED, value: INVENTORY_STATUS.LABELREQUESTED },
  { label: INVENTORY_STATUS.LABELREADY, value: INVENTORY_STATUS.LABELREADY },
  { label: INVENTORY_STATUS.THREEPLREVIEW, value: INVENTORY_STATUS.THREEPLREVIEW },
];
export const INVENTORY_3PL_OPTIONS = [
  { label: INVENTORY_3PL.YES, id: INVENTORY_3PL.YES, value: INVENTORY_3PL.YES },
  { label: INVENTORY_3PL.NO, id: INVENTORY_3PL.NO, value: INVENTORY_3PL.NO },
];

export const CATALOG_PARTS_OPTIONS = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const RECORD_TYPE_OPTIONS = [
  { label: 'Inventory Requests', id: 'Inventory Requests', value: 'Inventory Requests' },
  { label: 'Standard Return', id: 'Standard Return', value: 'Standard Return' },
  { label: 'Pickup Return', id: 'Pickup Return', value: 'Pickup Return' },
];

export const REPROCESS_RECORD_TYPE_OPTIONS = [
  {
    label: 'Inventory Requests',
    id: 'Inventory Requests',
    value: 'Inventory Requests',
    data: 'Inventory Request',
  },
  {
    label: 'Standard Return',
    id: 'Standard Return',
    value: 'Standard Return',
    data: 'Standard Return',
  },
  { label: 'Pickup Return', id: 'Pickup Return', value: 'Pickup Return', data: 'Pickup Return' },
  {
    label: 'Rep To Rep Transfer',
    id: 'Rep To Rep Transfer',
    value: 'Rep To Rep Transfer',
    data: 'Rep to Rep',
  },
  {
    label: 'Rep To Customer Transfer',
    id: 'Rep To Customer Transfer',
    value: 'Rep To Customer Transfer',
    data: 'Rep to Customer',
  },
  {
    label: 'Customer To Rep Transfer',
    id: 'Customer To Rep Transfer',
    value: 'Customer To Rep Transfer',
    data: 'Customer to Rep',
  },
];

export const INVENTORY_SERVICE_LEVEL = {
  GROUND: 'Ground',
  STANDARD: 'Standard Overnight',
  PRIORITY: 'Priority Overnight',
  FIRSTAM: 'First AM',
  AM: 'AM',
  CLOSEOFBUSINESS: 'By Close of Business',
  HOLD: 'Hold for Pickup',
  THREEPL: '3PL Delivery',
};

export const INVENTORY_SERVICE_LEVEL_OPTIONS = [
  // { label: INVENTORY_SERVICE_LEVEL.GROUND, value: INVENTORY_SERVICE_LEVEL.GROUND },
  // { label: INVENTORY_SERVICE_LEVEL.STANDARD, value: INVENTORY_SERVICE_LEVEL.STANDARD },
  // { label: INVENTORY_SERVICE_LEVEL.PRIORITY, value: INVENTORY_SERVICE_LEVEL.PRIORITY },
  {
    label: 'By Close of Business',
    value: 'By Close of Business',
  },
  {
    label: 'AM',
    value: 'AM',
  },
  {
    label: 'First AM',
    value: 'First AM',
  },
  {
    label: 'Hold for Pickup',
    value: 'Hold for Pickup',
  },
  {
    label: '3PL Delivery',
    value: '3PL Delivery',
  },
];

const SHIP_METHOD_OPTIONS_ARRAY = [
  SHIP_METHODS.FED_FIRST_8AM,
  SHIP_METHODS.FED_P1_Next_Day,
  SHIP_METHODS.FED_P2_Next_Day,
  SHIP_METHODS.FEDERAL_P1_HOLD_FOR_PU,
  SHIP_METHODS.FEDERAL_P1_SATURDAY,
  SHIP_METHODS.PICKUP_AT_MAHWAH,
  SHIP_METHODS.UPS_Next_Day_Air,
  SHIP_METHODS.FDX_PARCEL_GROUND,
  SHIP_METHODS.UPS_PARCEL_GROUND,
  SHIP_METHODS.FDX_PARCEL_STD_OVERNIGHT,
  SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR,
  SHIP_METHODS.FDX_PARCEL_PRIORITY_OVERNIGHT,
  SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR_EARLY_AM,
  SHIP_METHODS.UPS_PARCEL_2ND_DAY_AIR,
  SHIP_METHODS.UPS_PARCEL_2ND_DAY_AIR_EARLY_AM,
  SHIP_METHODS.UPS_PARCEL_3_DAY_SELECT,
  SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR_SAVER,
  SHIP_METHODS.UPS_PARCEL_OVERNIGHT_SATURDAY,
  SHIP_METHODS.FDX_PARCEL_EXPRESS_SAVER,
  SHIP_METHODS.FDX_PARCEL_FIRST_OVERNIGHT,
  SHIP_METHODS.FDX_PARCEL_OVERNIGHT_SATURDAY,
  SHIP_METHODS.FDX_PARCEL_SECOND_DAY,
  SHIP_METHODS.THREEPL,
];

const SHIP_METHOD_OPTIONS_ARRAY_SM = [
  // SHIP_METHODS.FED_FIRST_8AM,
  // SHIP_METHODS.FED_P1_Next_Day,
  // SHIP_METHODS.FED_P2_Next_Day,
  // SHIP_METHODS.FEDERAL_P1_HOLD_FOR_PU,
  // SHIP_METHODS.FEDERAL_P1_SATURDAY,
  // SHIP_METHODS.PICKUP_AT_MAHWAH,
  // SHIP_METHODS.UPS_Next_Day_Air,
  SHIP_METHODS.FDX_PARCEL_GROUND,
  SHIP_METHODS.UPS_PARCEL_GROUND,
  SHIP_METHODS.FDX_PARCEL_STD_OVERNIGHT,
  SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR,
  SHIP_METHODS.FDX_PARCEL_PRIORITY_OVERNIGHT,
  SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR_EARLY_AM,
  SHIP_METHODS.UPS_PARCEL_2ND_DAY_AIR,
  SHIP_METHODS.UPS_PARCEL_2ND_DAY_AIR_EARLY_AM,
  SHIP_METHODS.UPS_PARCEL_3_DAY_SELECT,
  SHIP_METHODS.UPS_PARCEL_NEXT_DAY_AIR_SAVER,
  SHIP_METHODS.UPS_PARCEL_OVERNIGHT_SATURDAY,
  SHIP_METHODS.FDX_PARCEL_EXPRESS_SAVER,
  SHIP_METHODS.FDX_PARCEL_FIRST_OVERNIGHT,
  SHIP_METHODS.FDX_PARCEL_OVERNIGHT_SATURDAY,
  SHIP_METHODS.FDX_PARCEL_SECOND_DAY,
];

export const INVENTORY_SHIPPING = [
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.GROUND,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.STANDARD,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.PRIORITY,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.FIRSTAM,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.AM,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.CLOSEOFBUSINESS,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.HOLD,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.THREEPL,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY,
  },
];

export const INVENTORY_SHIPPING_SM = [
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.GROUND,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY_SM,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.STANDARD,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY_SM,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.PRIORITY,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY_SM,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.FIRSTAM,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY_SM,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.AM,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY_SM,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.CLOSEOFBUSINESS,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY_SM,
  },
  {
    serviceLevel: INVENTORY_SERVICE_LEVEL.HOLD,
    shipMethodOptions: SHIP_METHOD_OPTIONS_ARRAY_SM,
  },
];

export const SERVICELEVELIR = [
  {
    label: 'By Close of Business',
    value: 'By Close of Business',
  },
  {
    label: 'AM',
    value: 'AM',
  },
  {
    label: 'First AM',
    value: 'First AM',
  },
  {
    label: 'Hold for Pickup',
    value: 'Hold for Pickup',
  },
  {
    label: '3PL Delivery',
    value: '3PL Delivery',
  },
];

export const SERVICELEVELIRSM = [
  {
    label: 'By Close of Business',
    value: 'By Close of Business',
  },
  {
    label: 'AM',
    value: 'AM',
  },
  {
    label: 'First AM',
    value: 'First AM',
  },
  {
    label: 'Hold for Pickup',
    value: 'Hold for Pickup',
  },
];

export const ATTACHMENT_TYPE = {
  PO: 'PO',
  USAGE: 'Usage',
  SIGN: 'Signature',
  TRANSFER: 'Transfer',
  RETURN: 'Return ',
};

export const DEFAULT_EMAIL_BODY = (
  usageDetails: any,
  businessUnit: any,
  address: any,
  salesRepConfigEmail: any
) =>
  /* eslint-disable */
  `Hello ${usageDetails?.surgeonFirstName || ''} ${usageDetails?.surgeonLastName ||
    ''},\n\nAttached is the Stryker Order for  ${usageDetails?.eventId || ''}. ${
    usageDetails?.parentEventType
      ? `This order is related to ${usageDetails?.parentEventType ||
          ''} ${usageDetails?.parentEventId || ''}  which occurred on ${moment(
          new Date(usageDetails.eventDateTime || new Date())
        ).format('MM/DD/YYYY') || ''}.`
      : ''
  }\n\nThis is not an invoice.\n\nPlease direct any questions to the contact listed below.\n\n--------------------------------------------------\nContact Information\n--------------------------------------------------\n${usageDetails?.salesRep ||
    ''}\n${usageDetails?.salesRepPhone ? `${usageDetails?.salesRepPhone}\n` : ''}${
    salesRepConfigEmail
      ? `${salesRepConfigEmail}\n`
      : `${usageDetails?.salesRepEmail ? `${usageDetails?.salesRepEmail}\n` : ''}`
  }${address?.['Street Address 1'] ? address?.['Street Address 1'] : ''} ${address?.[
    'Street Address 2'
  ] || ''}${address?.['Street Address 1'] || address?.['Street Address 2'] ? `\n` : ''}${
    address?.City ? address?.City : ''
  } ${address?.State ? address?.State : ''} ${address?.['Zip Code'] ||
    ''}\n\n--------------------------------------------------\nOrder Details\n--------------------------------------------------\nAccount # ${
    usageDetails.accountNumber ? usageDetails.accountNumber : ''
  }\nAccount Name: ${usageDetails.accountName ? usageDetails.accountName : ''}\nOrder Number: ${
    usageDetails.eventId
      ? usageDetails.eventId
      : usageDetails.caseNumber
      ? usageDetails.caseNumber
      : ''
  }\n${usageDetails.poNumber !== null ? `Purchase Order #: ${usageDetails.poNumber}\n` : ''}${
    usageDetails.eventDateTime !== null
      ? `Surgery Date: ${moment(new Date(usageDetails.eventDateTime || new Date())).format(
          'MM/DD/YYYY'
        )}\n`
      : ''
  }${
    usageDetails.surgeonFirstName
      ? `Surgeon: ${usageDetails.surgeonFirstName} ${
          usageDetails.surgeonLastName ? usageDetails.surgeonLastName : ''
        }\n`
      : ''
  }${usageDetails.procedureName !== null ? `Procedure: ${usageDetails.procedureName}\n` : ''}${
    usageDetails.patientId !== null ? `Patient ID: ${usageDetails.patientId}\n` : ''
  }\n`;
/* eslint-disable */

export const MAX_QUANTITY = 999;

export const PERSONAS = {
  KENCO: '3PL Ops',
  BRANCH_OPS: 'Branch Op',
  CSR: 'CSR',
};

export const TRANSFER_LIST = {
  REP_TRANSFER: 'Rep to Rep',
};

export const OPSCSRUSERS = [
  { label: 'Branch Op', value: 'Branch Op' },
  { label: '3PL Ops', value: '3PL Ops' },
  { label: 'Agency OPS', value: 'Agency OPS' },
  { label: 'Branch Op;Business Admin', value: 'Branch Op;Business Admin'},
  { label: 'Agency OPS;Agency CSR', value: 'Agency OPS;Agency CSR' },
  { label: 'CSR;Branch Op', value: 'CSR;Branch Op' },
  { label: 'CSR;Branch Op;Business Admin', value: 'CSR;Branch Op;Business Admin' },
  { label: 'CSR', value: 'CSR' },
  { label: 'Agency CSR', value: 'Agency CSR' },
];

export const OPSUSERS = [
  { label: 'Branch Op', value: 'Branch Op' },
  { label: '3PL Ops', value: '3PL Ops' },
  { label: 'Agency OPS', value: 'Agency OPS' },
  { label: 'Agency OPS;Agency CSR', value: 'Agency OPS;Agency CSR' },
  { label: 'CSR;Branch Op', value: 'CSR;Branch Op' },
  { label: 'CSR;Branch Op;Business Admin', value: 'CSR;Branch Op;Business Admin' },
  { label: 'Branch Op;Business Admin', value: 'Branch Op;Business Admin'}
];

export const MULTIPLE_PDF_PERSONAS = [
  'CSR;Branch Op',
  'CSR;Branch Op;Business Admin',
  'CSR',
  'Sales Rep',
];

// Enhancement 895216: EIM - Set up Config for landing screen options
export const LANDING_PAGE_COMMEN_CONFIG = [
  {
    id: 'Events',
    label: 'Events',
    usedFor: [
      'CSR',
      'CSR;Branch Op;Business Admin',
      'Branch Op',
      'Sales Associate',
      '3PL Ops',
      'Agency Sales Rep',
      'Agency OPS',
      'Branch Op;Business Admin',
      'CSR;Branch Op',
      'Sales Manager',
      'Agency CSR',
      'Sales Rep',
      'Agency OPS;Agency CSR',
      'Business Admin',
    ],
  },
  {
    id: 'Inventory',
    label: 'Inventory',
    usedFor: [
      'CSR;Branch Op;Business Admin',
      'Branch Op',
      'Sales Associate',
      '3PL Ops',
      'Agency Sales Rep',
      'Agency OPS',
      'Branch Op;Business Admin',
      'CSR;Branch Op',
      'Sales Manager',
      'Sales Rep',
      'Agency OPS;Agency CSR',
      'Business Admin',
    ],
  },
  {
    id: 'Orders',
    label: 'Orders',
    usedFor: [
      'CSR',
      'CSR;Branch Op;Business Admin',
      'Branch Op',
      'Sales Associate',
      'Agency Sales Rep',
      'Agency OPS',
      'Branch Op;Business Admin',
      'CSR;Branch Op',
      'Sales Manager',
      'Agency CSR',
      'Sales Rep',
      'Agency OPS;Agency CSR',
      'Business Admin',
    ],
  },
  {
    id: 'CycleCount',
    label: 'CycleCount',
    usedFor: [
      'CSR;Branch Op;Business Admin',
      'Branch Op',
      'Branch Op;Business Admin',
      'CSR;Branch Op',
    ],
  },
  {
    id: 'Configurations',
    label: 'Configurations',
    usedFor: ['CSR;Branch Op;Business Admin', 'Branch Op;Business Admin', 'Business Admin'],
  },
];
// * Enhancement 895216: EIM - Set up Config for landing screen options

export const INVENTORY_MODULE_LABEL_MAPPING: { [key: string]: string } = {
  'Inventory Request - Create': 'Inventory Request',
  'Standard Return - Create': 'Standard Return',
  'Pickup Return - Create': 'Pickup Return',
  'Rep Transfer - Create': 'Rep Transfer',
  'Customer Transfer - Create': 'Customer Transfer',
};

type InventoryModule = {
  label: string;
  value: string;
};

export const INVENTORY_MODULE: InventoryModule[] = [
  { label: 'Inventory Request', value: 'inventoryRequest' },
  { label: 'Standard Return', value: 'inventoryReturns' },
  { label: 'Pickup Return', value: 'pickupReturn' },
  { label: 'Customer Transfer', value: 'customerTransfer' },
  { label: 'Rep Transfer', value: 'repTransfer' },
]

export const ACCOUNT_TYPE_RETURN = ['E', 'IT', 'IH', 'A'];
