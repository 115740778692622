/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useCallback, useEffect } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import Button from '@salesforce/design-system-react/components/button';
import Radio from '@salesforce/design-system-react/components/radio-group/radio';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import DatePicker from '../Shared/DatePicker';
import { getUserId } from '../../store/ducks/userId';
import SaveFilter from '../FilterEvent/SaveFilter';
import {
  DATE_VIEW_FORMATTER,
  FILTER_ORDER_STATUS,
  accountTypesArr,
  FILTER_ORDER_REVIEW_STATUS,
  FILTER_ORDER_TYPES,
  FILTER_ORDER_REVIEW_CATALOGED_PARTS_OPTIONS,
  FILTER_ORDER_REVIEW_RECORD_TYPE_OPTIONS,
  FILTER_LIST_LIMIT,
} from '../../util/constants';
import { GET_FILTERED_ACCOUNTS } from '../../graphql/getFilteredAccounts';
import { GET_USER_TBA_ACCOUNTS } from '../../graphql/getUserTBAAccounts';
import { GET_USER_TERRITORIES } from '../../graphql/getUserTerritories';
import { GET_SALES_REPS } from '../../graphql/getSalesReps';
import { GET_FORM_CONFIGURATIONS } from '../../graphql/getFormConfigurations';
import { GET_OPS_LIST } from '../../graphql/getOpsList';
import { GET_PRODUCT_GROUP } from '../../graphql/getProductGroups';
import { getUserInfo } from '../../store/ducks/userInfo';
import AutocompleteSingleSelectPicker from '../Shared/AutocompleteSingleSelectPicker';
import { GET_SURGEONS } from '../../graphql/getSurgeons';
import { clearOrderFilters } from '../../store/ducks/orderFilters';
import { escapeRegExp, filter } from 'lodash';
import { GET_PRODUCT_LIST } from '../../graphql/getProductList';
import Input from '@salesforce/design-system-react/components/input';
import AutocompleteSingleSelectPickerWithSearch from '../Shared/AutocompleteSingleSelectPickerWithSearch';
import { GET_USER_REGION_AND_TERRITORIES } from '../../graphql/getUserRegionAndTerritories';
import CREATE_FILTER_PRESET from '../../graphql/mutations/createFilterPreset';
import UPDATE_FILTER_PRESET from '../../graphql/mutations/updateFilterPreset';
import DeleteFilterPreset from '../FilterEvent/DeleteFilterPreset';
import DELETE_FILTER_PRESET from '../../graphql/mutations/deleteFilterPreset';
import { getRegionTerritoryInfo, setRegionTerritoryInfo } from '../../store/ducks/userRegionAndTerritory';

interface Props {
  handleToggleFilterOrder: () => void;
  filterModalVisible: boolean;
  selectedFilter?: FilterPreset | DynamicType | null;
  refetchFilterPresets: () => void;
  handleFilterOrders: (filter: DynamicType | null) => void;
  resetFilters: () => void;
  type?: string;
  handleClearFilter: (externalId: string) => void;
  // handleEditFilter: (editEvent: boolean) => void;
  isEditFilter: boolean;
}

interface Selection {
  name: string;
  __typename: string;
  id: string;
  label: string;
  value?: string;
}


type SelectionType = Selection[];

interface UsageData {
  id: string;
  label: string;
}
interface ValueInterface {
  value: string;
}
interface SelectedData {
  id: string;
  label: string;
}

interface DynamicType {
  [key: string]: any;
}

interface FilterPreset {
  filterValues: SelectedFilters;
  filterExternalId?: string;
  filterName?: string;
}

interface SelectedFilters {
  status: SelectedData[];
  salesRep: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  // PO: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  // event: string;
  orderType: string[];
}
interface IEvents {
  name: string;
  label: string;
}

enum DetailTypes {
  Account = 'Account',
  SalesRep = 'SalesRep',
  ProcedureDetail = 'ProcedureDetail',
  CoveringRep = 'CoveringRep',
  Surgeon = 'Surgeon',
  userTerritory = 'UserTerritory',
  TBAAccount = 'TBAAccount',
}

type UserRegionAndTerritory = {
  id?: string;
  accountId: string;
  region: string;
  regionId: string;
  territory: string;
  territoryId: string;
  userId: string;
  __typename: string;
};

const FilterOrders: FC<Props> = ({
  handleToggleFilterOrder,
  filterModalVisible,
  selectedFilter,
  refetchFilterPresets,
  handleFilterOrders,
  resetFilters,
  type,
  handleClearFilter,
  // handleEditFilter,
  isEditFilter,
}) => {
  const userRegionTerritoryInfo: any = useSelector(getRegionTerritoryInfo);
  const filterValues = selectedFilter?.filterValues;
  const filterName = selectedFilter ? selectedFilter.filterName : '';
  const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
  const selectdeOrderTypes = filterValues ? filterValues.orderType : [];
  const userId = useSelector(getUserId);
  const userInfo = useSelector(getUserInfo);
  const dispatch = useDispatch();
  // useState
  const [inputValue, setInputValue] = useState('');
  // const [accountChecked, setAccountChecked] = useState('My Accounts');
  const [options, setOptions] = useState<any[]>();
  const [territory, setTerritory] = useState<any>(null);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openPO, setOpenPO] = useState(false);
  const [selectedOrderTypes, setSelectedOrderTypes] = useState<any>(null);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<any>(filterValues || {});
  const [territoryOptions, setTerritoryOptions] = useState<UserRegionAndTerritory[]>();
  const [regionOptions, setRegionOptions] = useState<UserRegionAndTerritory[]>();

  // Mutation & Queries
  const [createFilterPreset, { data: filterPresets }] = useMutation(CREATE_FILTER_PRESET);
  const [updateFilterPreset, { data: updatedfilterPresets }] = useMutation(UPDATE_FILTER_PRESET);
  const [deleteFilterPreset] = useMutation(DELETE_FILTER_PRESET);
  const [getRegionAndTerritory, { data: regionAndTerritoryData }] = useLazyQuery(GET_USER_REGION_AND_TERRITORIES);
  const [getOpsList, { data }] = useLazyQuery(GET_OPS_LIST);
  const [productGroup, { data: productGroupItems }] = useLazyQuery(GET_PRODUCT_GROUP);
  const productGroupList = productGroupItems?.getProductGroups;
  const [getFormConfigurations, { data: formdata }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const [productListItems, { data: productItems }] = useLazyQuery(GET_PRODUCT_LIST);
  const [getAccount, { data: filteredAccounts, loading: loadingAccounts }] = useLazyQuery(
    GET_FILTERED_ACCOUNTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userId,
        filters: {
          searchText: '',
        },
        salesRepId: '',
        limit: FILTER_LIST_LIMIT,
      },
    }
  );

  const dataObject = formdata?.getFormConfigurations;
  const billOnlyConfig = dataObject && dataObject?.billOnly[2]?.fields[0];
  const ShipAndBillConfig = dataObject && dataObject?.shipAndBill[2]?.fields[0];
  const itemsData: any = [];
  itemsData.push({ label: 'Missing PO', id: 'Missing PO', value: 'Missing PO' })
  const { data: tbaAccountsData } = useQuery(GET_USER_TBA_ACCOUNTS, {
    fetchPolicy: 'no-cache',
  });
  const [getSalesRepsItems, { data: salesRepsItems, loading: loadingSalesRep }] = useLazyQuery(
    GET_SALES_REPS
  );

  const [getSurgeonsData, { data: filteredSurgeons, loading: loadingSurgeons }] = useLazyQuery(
    GET_SURGEONS
  );
  const getApiDateTime = useCallback((eventDate: any, name: string): string => {
    const formattedSelectedDate = new Date(eventDate);
    let updatedDate;

    if (name.toLowerCase().includes('enddate') || name.toLowerCase().includes('todate')) {
      updatedDate = new Date(formattedSelectedDate).setHours(23);
      updatedDate = new Date(updatedDate).setMinutes(59);
      updatedDate = new Date(updatedDate).setSeconds(59);
    } else {
      updatedDate = new Date(formattedSelectedDate).setHours(0);
      updatedDate = new Date(updatedDate).setMinutes(0);
      updatedDate = new Date(updatedDate).setSeconds(0);
    }

    const finalDate = format(new Date(updatedDate), DATE_VIEW_FORMATTER);
    return finalDate;
  }, []);

  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  useEffect(() => {
    productGroup();
    getSalesRepsItems();
    getOpsList({
      variables: {
      },
    });
  }, [productGroup, getSalesRepsItems, getOpsList, userInfo]);
  useEffect(() => {
    getAccount();
  }, []);

  useEffect(() => {
    if (data && data.getOPSList) {
      const optionList = data.getOPSList.map((i: any) => {
        return {
          label: i.value,
          value: i.value,
          id: i.id,
        };
      });
      optionList.unshift({ id: 'Unassigned', value: 'Unassigned', label: 'Unassigned', type: 'assignedTo' });
      setOptions(optionList);
    }
  }, [data]);

  useEffect(() => {
    productListItems({
      variables: {
        filters: {
          productName: selectedFilters?.productGroup?.map((item: any) => item.label),
          businessUnit: [userInfo?.businessunit || 'Sports Med'],
        },
        salesRepId: userInfo?.id
      },
    });
  }, [productListItems]);

  useEffect(() => {
    if (!filterValues?.iscataloged && type === 'Review') {
      selectedFilters.iscataloged = FILTER_ORDER_REVIEW_CATALOGED_PARTS_OPTIONS;
    }
  }, []);

  useEffect(() => {
    if (billOnlyConfig && billOnlyConfig?.isShow || ShipAndBillConfig && ShipAndBillConfig?.isShow) {
      setOpenPO(true);
    }
  }, [formdata, billOnlyConfig, ShipAndBillConfig]);

  const comboboxFilterAndLimit = (parameters: any): SelectionType => {
    const { searchedValue, options, selection } = parameters;
    const inputValueRegExp = new RegExp(escapeRegExp(searchedValue), 'ig');
    return options.filter((option: Selection) => {
      const searchTermFound = option.label ? option.label.match(inputValueRegExp) : false;
      const isSeparator = false;
      const notAlreadySelected = !selection.some((sel: Selection) => sel.id === option.id);

      return (!searchedValue || isSeparator || searchTermFound) && notAlreadySelected;
    });
  };

  const handleChangeOnType = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );

  const handleSelectItem = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValue('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc: any, cur: any) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      setTerritory(updatedSelection);
      handleSelect(updatedSelection, 'productGroup')
    },
    [setInputValue, setTerritory]
  );

  const handleRemoveSelection = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValue('');
      setTerritory(selection);
    },
    [setTerritory]
  );

  const handleChange = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );

  const reset = (): void => {
    handleToggleFilterOrder();
    setSelectedFilters(null);
    setSelectedOrderTypes([]);
    resetFilters();
    dispatch(clearOrderFilters());
  };

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate) {
      const utcDate = getApiDateTime(selectedDate, name);
      setFilterValue(name, utcDate);
    }
  };

  const handleApplyFilter = (): void => {
    handleToggleFilterOrder();
    console.log(selectedFilters);
    handleFilterOrders(selectedFilters);
  };

  const handleCreateFilterPreset = (name: string): void => {
    let reducedFilterValues = {};
    if (selectedFilters) {
      Object.keys(selectedFilters).forEach((key: string) => {
        if (Array.isArray(selectedFilters[key])) {
          const values = selectedFilters[key].map((item: SelectedData) => {
            const itemCopy = { id: item.id, label: item.label };
            return { ...itemCopy };
          });
          reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
        } else if (key !== '__typename' && selectedFilters[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: selectedFilters[key] };
        }
      });
    }
    const filterData = { ...reducedFilterValues };

    if (!filterExternalId || filterExternalId === '') {
      createFilterPreset({
        variables: {
          userId,
          filterValues: filterData,
          type: 'Orders',
          name,
        },
      });
    } else if (filterExternalId) {
      updateFilterPreset({
        variables: {
          externalId: filterExternalId,
          filterValues: filterData,
          type: 'Orders',
          name,
        },
      });
    }
  };

  const handleSaveModalVisible = (): void => {
    setSaveModalVisible(!saveModalVisible);
  };

  const handleDeleteModalVisible = useCallback((): void => {
    setDeleteModalVisible(!deleteModalVisible);
  }, [deleteModalVisible]);

  const handleDeleteFilterPreset = (): void => {
    deleteFilterPreset({
      variables: {
        externalId: filterExternalId,
      },
    }).then(response => {
      // if delete filter preset is successful
      const deleteUsageResponse =
        response.data &&
        response.data.deleteFilterPreset &&
        response.data.deleteFilterPreset.message === 'success';
      if (deleteUsageResponse) {
        handleDeleteModalVisible();
        handleToggleFilterOrder();
        handleClearFilter(filterExternalId);
        if (refetchFilterPresets) {
          // handleEditFilter(false);
          refetchFilterPresets();
        }
      }
    });
  };

  useEffect(() => {
    if (
      updatedfilterPresets &&
      updatedfilterPresets.updateFilterPreset &&
      updatedfilterPresets.updateFilterPreset.message === 'success'
    ) {
      handleToggleFilterOrder();
      if (refetchFilterPresets) {
        // handleEditFilter(false);
        refetchFilterPresets();
        reset();
      }
    }
  }, [
    // handleEditFilter,
    handleFilterOrders,
    handleToggleFilterOrder,
    refetchFilterPresets,
    selectedFilters,
    updatedfilterPresets,
  ]);

  useEffect(() => {
    if (
      filterPresets &&
      filterPresets.createFilterPreset &&
      filterPresets.createFilterPreset.message === 'success'
    ) {
      handleToggleFilterOrder();
      if (refetchFilterPresets) {
        // handleEditFilter(false);
        refetchFilterPresets();
      }
    }
  }, [
    filterPresets,
    // handleEditFilter,
    handleFilterOrders,
    handleToggleFilterOrder,
    refetchFilterPresets,
    selectedFilters,
  ]);

  // eslint-disable-next-line no-underscore-dangle
  const optionsWithLabel = (value: any): any[] =>
    value &&
    value.map((elem: any) => {
      if ([DetailTypes.Account].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.number}-${elem.value}` };
      }
      if ([DetailTypes.TBAAccount].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.number}-${elem.value}` };
      }
      if ([DetailTypes.Surgeon].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.value}` };
      }
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.userTerritory) {
        return { ...elem, label: `${elem.value}` };
      }
    });
  const surgeonDetails = optionsWithLabel(filteredSurgeons?.getSurgeons);
  const accounts = optionsWithLabel(filteredAccounts?.getAccounts);
  const tbaAccounts = optionsWithLabel(tbaAccountsData?.getUserTBAAccounts);

  // const contactData = optionsWithLabel(contactsDataOption);
  const productGroupOptions =
    productGroupList &&
    productGroupList.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      return { ...elem, label: `${elem.value}` };
    });

  const optionsWithPart = (value: []): any[] =>
    value &&
    // eslint-disable-next-line consistent-return
    value.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      return { ...elem, id: elem.productId, label: `${elem.productNumber}-${elem.productdescription}` };
    });

  const optionsWithSalesRepLabel = (value: []): any =>
    value &&
    // eslint-disable-next-line consistent-return
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.SalesRep) {
        return { ...elem, label: `${elem.value}` };
      } else {
        return { ...elem, label: `${elem.value}` };
      }
    });
  const salesRepsData = optionsWithSalesRepLabel(salesRepsItems?.getSalesReps);
  const partList = optionsWithPart(productItems?.getProductList);
  const getAccountdata = (searchText: string): void => {
    getAccount({
      variables: {
        salesRepId: '',
        userId,
        limit: FILTER_LIST_LIMIT,
        filters: {
          searchText: searchText ? searchText : '',
        },
      },
    });
  }

  useEffect(() => {
    if (userInfo) {
      getRegionAndTerritory({
        variables: {
          userId: userInfo?.id,
        },
      });
    }
  }, [getRegionAndTerritory, userInfo]);

  const getUniqueRegionTerritory = (array: UserRegionAndTerritory[]): UserRegionAndTerritory[] => {
    const cleanedArray = array.filter((item: any) => item !== undefined && item !== null);
    return cleanedArray.filter(
      (item: UserRegionAndTerritory, index: number, self: UserRegionAndTerritory[]) =>
        index === self.findIndex((element: UserRegionAndTerritory) => item.id === element.id)
    );
  };

  const getRegionTerritoryOptions = () => {
    let tempRegions: any[] = [];
    let tempTerritories: any[] = [];
    if (selectedFilters && selectedFilters?.salesRep?.length && !selectedFilters?.region?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item.userId === selectedFilters.salesRep[0].id)
          tempRegions.push({ id: item.regionId, value: item.region });
      });
      userRegionTerritoryInfo?.map((item: any) => {
        if (item.userId === selectedFilters.salesRep[0].id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && selectedFilters?.region?.length && selectedFilters?.salesRep?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item?.userId === selectedFilters?.salesRep?.[0]?.id && item?.regionId === selectedFilters?.region?.[0]?.id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && selectedFilters?.region?.length && !selectedFilters?.salesRep?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item?.regionId === selectedFilters?.region?.[0]?.id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && !selectedFilters?.region?.length && !selectedFilters?.salesRep?.length) {
      tempRegions = userRegionTerritoryInfo?.map((item: any, index: number) => {
        return { id: item.regionId, value: item.region };
      });
      tempTerritories = userRegionTerritoryInfo?.map((item: any, index: number) => {
        return { id: item.territoryId, value: item.territory };
      });
    }
    setRegionOptions(getUniqueRegionTerritory(tempRegions));
    setTerritoryOptions(getUniqueRegionTerritory(tempTerritories));
  };

  useEffect(() => {
    if (userRegionTerritoryInfo) getRegionTerritoryOptions()
  }, [selectedFilters, userRegionTerritoryInfo]);

  useEffect(() => {
    if (regionAndTerritoryData)
      dispatch(setRegionTerritoryInfo(regionAndTerritoryData?.getUserRegionAndTerritories));
  }, [regionAndTerritoryData]);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };
      setSelectedFilters(selectedFilterValues);
      if (key === 'salesReps'){
        const selectedFilterValues = 
        { ...selectedFilters,
          [key]: filterValuesObj,
          region: [],
          territory: [],
        };
        setSelectedFilters(selectedFilterValues);
      }
      if (key === 'region'){
        const selectedFilterValues = 
        { ...selectedFilters,
          [key]: filterValuesObj,
          territory: [],
        };
        setSelectedFilters(selectedFilterValues);
      }
      if (key === 'salesRep' && filterValuesObj?.length === 0) {
        const newObject = {
          ...selectedFilters,
          salesReps: [],
          region: [],
          territory: [],
        };
        setSelectedFilters(newObject);
      } else if (key === 'region' && filterValuesObj?.length === 0) {
        const newObject = {
          ...selectedFilters,
          region: [],
          territory: [],
        };
        setSelectedFilters(newObject);
      }
    },
    [selectedFilters]
  );

  const handleSelect = (data: any, name: string) => {
    const { selection } = data;
    if (name === 'account' && data) {
      getSurgeonsData({
        variables: {
          accountId: data?.id,
        },
      });
    }
    if (name === 'productGroup') {
      productListItems({
        variables: {
          filters: {
            productName: selection ? selection?.map((item: any) => item.label) : data?.map((item: any) => item.label) || '',
            businessUnit: [userInfo?.businessunit || 'Sports Med'],
          },
          salesRepId: userInfo?.id
        },
      });
    }
    if (selection) {
      setFilterValue(name, selection);
    } else {
      setFilterValue(name, data);
    }
  };

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={filterModalVisible}
        onRequestClose={handleToggleFilterOrder}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Save Filters"
            disabled={
              selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate
            }
            key="Save"
            onClick={handleSaveModalVisible}
          />,
          <Button label="Reset Filter" key="Reset" onClick={reset} />,
          <Button
            className="yellow-btn"
            label="Apply"
            onClick={handleApplyFilter}
            disabled={
              selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate
            }
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          // <div className="slds-float_left slds-text-color_default" key="filterName">
          //   Filters
          // </div>,
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            {`${filterName && filterName !== ''
              ? `${isEditFilter ? 'Edit ' : ''}Filter Preset ${filterName}`
              : 'Filters'
              }`}
          </div>,

          isEditFilter && (
            <div className="slds-float_right" key="delete">
              <Button
                className="delete_button"
                label="Delete Preset"
                key="delete"
                onClick={handleDeleteModalVisible}
              />
            </div>
          ),
        ]}
      >
        {type === 'Review' ? (
          <section className="slds-p-around_medium slds-theme_shade">
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">Attributes</h1>
              {/* <p className="selectbox_label">Product Group</p> */}
              {/* <Combobox
                id="orderType"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'orderType'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'orderType'),
                }}
                labels={{
                  label: `Order Types`,
                  placeholderReadOnly: 'Select Order Types',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                // hasInputSpinner={loadingAccounts}
                multiple
                options={FILTER_ORDER_TYPES || []}
                selection={selectedFilters ? selectedFilters.orderType || [] : []}
                variant="readonly"
              /> */}
              <Combobox
                id="status"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'status'),
                }}
                labels={{
                  label: `Status`,
                  placeholderReadOnly: 'Select Status',
                  noOptionsFound: 'No data found',
                }}
                multiple
                // placeholder="Select"
                options={(userInfo && userInfo.personas === 'Branch Op') ||
                (userInfo && userInfo.personas === 'Agency OPS') || (userInfo?.personas === 'Branch Op;Business Admin') ? [
                  {
                    id: 'Requested',
                    label: 'Requested',
                  },
                ] : FILTER_ORDER_REVIEW_STATUS || []}
                selection={selectedFilters ? selectedFilters.status || [] : []}
                variant="readonly"
              />
              {/* <Combobox
                id="diplayordertype"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'displayordertype'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'displayordertype'),
                }}
                labels={{
                  label: `Record Type`,
                  placeholderReadOnly: 'Select an Option',
                  noOptionsFound: 'No data found',
                }}
                // placeholder="Select"
                // hasInputSpinner={loadingAccounts}
                multiple
                selection={selectedFilters ? selectedFilters.displayordertype || [] : []}
                variant="readonly"
                placeholder="Select"
                options={FILTER_ORDER_REVIEW_RECORD_TYPE_OPTIONS || []}
              /> */}
              <Combobox
                id="productGroup"
                options={comboboxFilterAndLimit({
                  searchedValue: inputValue,
                  options: productGroupOptions || [],
                  selection: selectedFilters?.productGroup || [],
                })}
                events={{
                  onChange: handleChangeOnType,
                  onRequestRemoveSelectedOption: (event: any, data: any): void => handleSelect(data, 'productGroup'),
                  onSelect: handleSelectItem,
                }}
                labels={{
                  label: `Product Group`,
                  placeholder: 'Select Product Group',
                  noOptionsFound: 'No data found',
                }}
                disabled={false}
                multiple
                placeholder="Select Product Group"
                menuPosition="relative"
                value={inputValue}
                selection={selectedFilters ? selectedFilters.productGroup || [] : []}
                variant="base" />
              <Combobox
                id="part"
                events={{
                  onSelect: (event: any, data: any): void => {
                    handleSelect(data, 'part')
                  },
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'part'),
                }}
                labels={{
                  label: `Part`,
                  placeholder: 'Select Part',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select Part"
                options={partList || []}
                selection={selectedFilters ? selectedFilters.part || [] : []}
                variant="readonly"
              />
              <Input
                label="Lot/Serial"
                onChange={
                  (event: any): void => handleSelect({ label: event.target.value }, 'lotOrSerial')
                }
                value={selectedFilters ? selectedFilters?.lotOrSerial?.label || '' : ''}
                maxLength="200"
              />
              <Combobox
                id="iscataloged"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'iscataloged'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'iscataloged'),
                }}
                labels={{
                  label: `Cataloged Parts`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                options={FILTER_ORDER_REVIEW_CATALOGED_PARTS_OPTIONS || []}
                selection={selectedFilters ? selectedFilters.iscataloged || [] : []}
                multiple
                variant="readonly"
              />
            </div>
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">Date</h1>
              <p className="slds-form-element__label ">Submitted Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="From"
                    handleChangeDate={(data: any): void =>
                      handleChangeDate(data, 'createdFromDate')
                    }
                    disabled={false}
                    isFilterDate={true}
                    value={selectedFilters ? selectedFilters.createdFromDate : null}
                  />
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <span>-</span>
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="To"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'createdEndDate')}
                    disabled={false}
                    isFilterDate={true}
                    value={selectedFilters ? selectedFilters.createdEndDate : null}
                  />
                </div>
              </div>
            </div>
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">Other</h1>
              <AutocompleteSingleSelectPicker
                items={salesRepsData}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'salesRep');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.salesRep || [] : []}
                placeholder="Sales Rep"
                label="Sales Rep"
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="salesRep"
                autoSelectValue={false}
              />
              <AutocompleteSingleSelectPicker
                items={regionOptions || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'region');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.region || [] : []}
                placeholder="Select Region"
                label="Region"
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="region"
                autoSelectValue={false}
              />
              <AutocompleteSingleSelectPicker
                items={territoryOptions || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'territory');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.territory || [] : []}
                placeholder="Select Territory"
                label="Territory"
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="territory"
                autoSelectValue={false}
              />
              <AutocompleteSingleSelectPickerWithSearch
                items={accounts || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'account');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.account || [] : []}
                placeholder="Select Account"
                label="Account"
                withAddMenuButton={false}
                fetchItems={(searchText: string) => getAccountdata(searchText)}
                filter
                name="account"
                hasInputSpinnerCheck={loadingAccounts}
                autoSelectValue={false}
              />
              <Combobox
                id="assignedTo"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'assignedTo'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'assignedTo'),
                }}
                labels={{
                  label: `Assigned To`,
                  placeholderReadOnly: 'Select an Option',
                  noOptionsFound: 'No data found',
                }}
                multiple
                placeholder="Select an Option"
                options={options || []}
                selection={selectedFilters ? selectedFilters.assignedTo || [] : []}
                variant="readonly"
              />
              {openPO && (
                <div className="slds-form-element__control">
                  <p style={{ marginTop: '3%', marginBottom: '-3%' }}>PO</p>
                  <AutocompleteSingleSelectPicker
                    items={itemsData}
                    setSelectedItem={(result: any): void => {
                      handleSelect(result, 'PO');
                    }}
                    disabled={false}
                    selectedItem={selectedFilters ? selectedFilters.PO || [] : []}
                    placeholder="Select PO"
                    label="PO"
                    withAddMenuButton={false}
                    fetchItems={(PO: string): string => PO}
                    filter
                    name="PO"
                    autoSelectValue={false}
                  />
                </div>
              )}
            </div>
          </section>
        ) : (
          <section className="slds-p-around_medium slds-theme_shade">
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">Attributes</h1>
              <Combobox
                id="orderType"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'orderType'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'orderType'),
                }}
                labels={{
                  label: `Order Types`,
                  placeholderReadOnly: 'Select Order Types',
                  noOptionsFound: 'No data found',
                }}
                // placeholder="Select"
                // hasInputSpinner={loadingAccounts}
                multiple
                options={FILTER_ORDER_TYPES || []}
                selection={selectedFilters ? selectedFilters.orderType || [] : []}
                variant="readonly"
              />
              <Combobox
                id="status"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'status'),
                }}
                labels={{
                  label: `Status`,
                  placeholderReadOnly: 'Select Status',
                  noOptionsFound: 'No data found',
                }}
                multiple
                // placeholder="Select"
                options={FILTER_ORDER_STATUS || []}
                selection={selectedFilters ? selectedFilters.status || [] : []}
                variant="readonly"
              />
            </div>
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--xx-small">Date</h1>
              <div className="filter_date_container">
                <p className="selectbox_label" style={{ fontSize: '15px', color: '#000' }}>Surgery Date</p>
                <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="From"
                      handleChangeDate={(data: any): void =>
                        handleChangeDate(data, 'surgeryStartDate')
                      }
                      disabled={false}
                      isFilterDate={true}
                      value={selectedFilters ? selectedFilters.surgeryStartDate : null}
                    />
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <span>-</span>
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="To"
                      handleChangeDate={(data: any): void =>
                        handleChangeDate(data, 'surgeryEndDate')
                      }
                      disabled={false}
                      isFilterDate={true}
                      value={selectedFilters ? selectedFilters.surgeryEndDate : null}
                    />
                  </div>
                </div>
              </div>
              <div className="filter_date_container">
                <p className="selectbox_label" style={{ fontSize: '15px', color: '#000' }}>Order Creation Date</p>
                <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="From"
                      handleChangeDate={(data: any): void =>
                        handleChangeDate(data, 'createdFromDate')
                      }
                      disabled={false}
                      isFilterDate={true}
                      value={selectedFilters ? selectedFilters.createdFromDate : null}
                    />
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <span>-</span>
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="To"
                      handleChangeDate={(data: any): void =>
                        handleChangeDate(data, 'createdEndDate')
                      }
                      disabled={false}
                      isFilterDate={true}
                      value={selectedFilters ? selectedFilters.createdEndDate : null}
                    />
                  </div>
                </div>
              </div>
              <div>
                {selectedFilters?.endDate &&
                  selectedFilters?.startDate &&
                  selectedFilters.endDate < selectedFilters.startDate && (
                    <Pill
                      labels={{
                        label: 'Error: To Date must be greater than or equal to From Date',
                      }}
                      hasError
                      icon={
                        <Icon
                          title="Error"
                          category="utility"
                          name="warning"
                          className="slds-icon-text-error"
                        />
                      }
                    />
                  )}
              </div>
            </div>

            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--xx-small">Other</h1>
              <AutocompleteSingleSelectPicker
                items={salesRepsData}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'salesRep');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.salesRep || [] : []}
                placeholder="Sales Rep"
                label="Sales Rep"
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="salesRep"
                autoSelectValue={false}
              />
              <AutocompleteSingleSelectPicker
                items={regionOptions || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'region');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.region || [] : []}
                placeholder="Select Region"
                label="Region"
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="region"
                autoSelectValue={false}
              />
              <AutocompleteSingleSelectPicker
                items={territoryOptions || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'territory');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.territory || [] : []}
                placeholder="Select Territory"
                label="Territory"
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="territory"
                autoSelectValue={false}
              />
              <AutocompleteSingleSelectPickerWithSearch
                items={accounts || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'account');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.account || [] : []}
                placeholder="Select Account"
                label="Account"
                withAddMenuButton={false}
                fetchItems={(searchText: string) => getAccountdata(searchText)}
                filter
                name="account"
                hasInputSpinnerCheck={loadingAccounts}
                autoSelectValue={false}
              />
              <Combobox
                id="contact"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'surgeon'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'account'),
                }}
                labels={{
                  label: `Surgeon`,
                  placeholderReadOnly: 'Select Surgeon',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                hasInputSpinner={loadingSurgeons}
                options={surgeonDetails || []}
                selection={selectedFilters ? selectedFilters.surgeon || [] : []}
                variant="readonly"
              />

              {openPO && (
                <div className="slds-form-element__control">
                  <p style={{ marginTop: '3%', marginBottom: '-3%' }}>PO</p>
                  <AutocompleteSingleSelectPicker
                    items={itemsData}
                    setSelectedItem={(result: any): void => {
                      handleSelect(result, 'PO');
                    }}
                    disabled={false}
                    selectedItem={selectedFilters ? selectedFilters.PO || [] : []}
                    placeholder="Select PO"
                    label="PO"
                    withAddMenuButton={false}
                    fetchItems={(PO: string): string => PO}
                    filter
                    name="PO"
                    autoSelectValue={false}
                  />
                </div>
              )}
            </div>
          </section>
        )}
        {saveModalVisible && (
          <SaveFilter
            handleSaveModalVisible={handleSaveModalVisible}
            saveModalVisible={saveModalVisible}
            handleCreateFilterPreset={handleCreateFilterPreset}
            selectedFilterName={filterName}
          />
        )}
        {deleteModalVisible && (
          <DeleteFilterPreset
            deleteModalVisible={deleteModalVisible}
            handleDeleteModalVisible={handleDeleteModalVisible}
            handleDeleteFilterPreset={handleDeleteFilterPreset}
          />
        )}
      </Modal>
    </IconSettings>
  );
};

export default FilterOrders;
