/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import { useQuery } from '@apollo/react-hooks';
import { ATTACHMENT_TYPE } from '../../../../../../../util/constants';
import { GET_SAS_URI } from '../../../../../../../graphql/getSasUri';

interface Item {
  attachmentType: string;
  showOnUsageSheet: boolean;
  name?: string;
  imageurl?: string;
}

interface Props {
  children?: FC;
  item?: Item;
  indicator: string;
}

const CustomeDataCell: FC<Props> = ({
  item = {
    showOnUsageSheet: false,
    attachmentType: '',
  },
  indicator = '',
}) => {
  const { data: sasUriData } = useQuery(GET_SAS_URI);
  const { attachmentType, showOnUsageSheet, name, imageurl } = item;
  const openAttachment = (): void => {
    const attachmentUrl = `${imageurl}?${sasUriData?.getSasUri.sasUri}`;
    window.open(attachmentUrl, '_blank');
  };
  const flag =
    indicator === ATTACHMENT_TYPE.PO ? attachmentType === ATTACHMENT_TYPE.PO : showOnUsageSheet;
  return (
    <DataTableCell>
      <IconSettings iconPath="/icons">
        {indicator === 'ATTACHMENT' ? (
          <div
            aria-hidden="true"
            onKeyDown={openAttachment}
            onClick={openAttachment}
            style={{ padding: '0px 5px' }}
          >
            {name}
          </div>
        ) : flag ? (
          <Icon category="utility" name="check" size="small" />
        ) : (
          <span style={{ color: 'lightgray' }}>NA</span>
        )}
      </IconSettings>
    </DataTableCell>
  );
};

CustomeDataCell.displayName = DataTableCell.displayName;

export default CustomeDataCell;
