/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/**
 * Module for header on InventoryRequestDetailCatalog Page
 * @module src/SurgicalDetail/InventoryRequestDetailCatalog
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC, ReactNode, useCallback, useState, useEffect } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { debounce } from 'lodash';
import Input from '@salesforce/design-system-react/components/input';
import Combobox from '@salesforce/design-system-react/components/combobox';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Icon from '@salesforce/design-system-react/components/icon';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import CartComponent from './cartComponent';
import './index.scss';
import InventoryReturnAddItems from '../InventoryReturnAddItems';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import {
  CYCLE_COUNT,
  MAX_QUANTITY,
  NOTIFY_TYPE,
  RETURNS_REASON_OPTION,
  RETURNS_TYPE_OPTION,
  OPSCSRUSERS,
} from '../../../../util/constants';
import { getEventIcon, getHoldIcon, getSvgIcon } from '../../../../util/utilityFunctions';
import RESUBMIT_ORDER_DETAILS from '../../../../graphql/mutations/resubmitOrderDetails';
import CREATE_UPDATE_USAGE from '../../../../graphql/mutations/createdUpdateUsage';
import CREATE_UPDATE_INVENTORY_RETURN_LINE_ITEMS from '../../../../graphql/mutations/createUpdateInventoryReturnLineItems';
import SnackBar from '../../../Shared/SnackBar';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import debounced from '../../../../util/debounced';
import { GET_INVENTORY_RETURN_DETAILS } from '../../../../graphql/getInventoryReturnDetails';
import { GET_ORDER_DETAILS } from '../../../../graphql/getOrderDetails';

interface Props {
  inventoryDetail?: any;
  isEventInventoryFlow?: boolean;
  refetch: () => void;
  visualIndicator: string;
  type: string;
  hasEventInventory?: any;
  eventInventoryExternalId?: any;
  externalId?: any;
  isOrder?: boolean;
  detailsViewConfig?: any;
  returnType?: any;
  viewOnly?: boolean;
}

interface DynamicValue {
  [key: string]: any;
}

interface SelectedData {
  id: string;
  value: string;
  __typename?: string;
}

const InventoryRequestTable: FC<Props> = ({
  refetch,
  inventoryDetail,
  isEventInventoryFlow,
  visualIndicator,
  type,
  hasEventInventory,
  eventInventoryExternalId,
  externalId,
  isOrder,
  detailsViewConfig,
  returnType,
  viewOnly,
}) => {
  const userInfo = useSelector(getUserInfo);
  const { open, notification, openSnackBar } = useSnackBar();
  const [searchValue, setSearchText] = useState('');
  const [isLoading, setLoader] = useState(false);
  const [showSearchCatalogModal, setShowSearchCatalogModal] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [lotChange, setLotChange] = useState<any>([]);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const [reSubmitInventory] = useMutation(RESUBMIT_ORDER_DETAILS);
  // const [createUpdateUsage, { data: eventUsageMutation }] = useMutation(CREATE_UPDATE_USAGE);
  const [createUpdateInventoryReturn, { data: createUpdateInventoryReturnMutation }] = useMutation(
    CREATE_UPDATE_INVENTORY_RETURN_LINE_ITEMS
  );
  const isUserOpsCsr = !!OPSCSRUSERS?.find((rec: any) => rec?.value === userInfo?.personas);
  // const { data: getInventoryRequestDetails, refetch: refetchEventDetails, loading } = useQuery(
  //   GET_INVENTORY_REQUEST_DETAILS,
  //   {
  //     fetchPolicy: 'no-cache',
  //     variables: {
  //       externalId:
  //         isEventInventoryFlow || hasEventInventory ? eventInventoryExternalId : externalId,
  //       searchText: searchValue,
  //     },
  //   }
  // );
  const { data: getInventoryRequestDetails, refetch: refetchEventDetails, loading } = useQuery(
    GET_INVENTORY_RETURN_DETAILS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: externalId,
      },
    }
  );
  const {
    data: getOrderDetails,
    refetch: refetchProcessedEventDetails,
    loading: processedLoading,
  } = useQuery(GET_ORDER_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: externalId,
      searchText: searchValue,
    },
  });
  const onHandleCloseSearchCatalogModal = (): void => {
    setShowSearchCatalogModal(false);
    if (refetch) {
      refetch();
    }
  };
  const tableClass =
    returnType === 'Failed' || returnType === 'Completed'
      ? inventoryDetail.orderType === 'Pickup Return'
        ? 'irpp-cols'
        : 'irs-cols'
      : inventoryDetail.returnType === 'Pickup Return'
      ? 'irpp-cols'
      : 'irs-cols';
  const isPickupReturnType =
    returnType === 'Failed' || returnType === 'Completed'
      ? inventoryDetail.orderType === 'Pickup Return'
        ? true
        : false
      : inventoryDetail?.returnType === 'Inventory Return'
      ? false
      : true;
  const isUserOps =
    !!userInfo?.personas?.toLowerCase().includes('branch op') ||
    userInfo?.personas === 'CSR;Branch Op' ||
    userInfo?.personas === '3PL Ops';

  const [quantityRecCount, setCartRecCount] = useState(
    inventoryDetail?.receivedLineItems?.reduce(
      (
        accumulator: any,
        current: {
          receivedQuantity: any;
          quantity: any;
        }
      ) => accumulator + Number(current?.receivedQuantity),
      0
    )
  );
  const [quantityCount, setCartCount] = useState(
    inventoryDetail?.lineItems?.reduce(
      (accumulator: any, current: { quantity: any }) => accumulator + Number(current.quantity),
      0
    )
  );
  let enabledTabs: any[] = [];
  if (detailsViewConfig) {
    detailsViewConfig.forEach((item: any) => {
      if (item.isShow === true) {
        enabledTabs = [...enabledTabs, item.mappingKey];
      }
    });
  }
  useEffect(() => {
    if (type === 'Returning') {
      const total = items?.reduce(
        (accumulator: any, current: any) => accumulator + Number(current.counterValue),
        0
      );
      setCartCount(total);
    } else {
      const total = items?.reduce(
        (accumulator: any, current: any) => accumulator + Number(current.counterValue),
        0
      );
      setCartRecCount(total);
    }
  }, [items, type]);

  useEffect(() => {
    if (type === 'Returning') {
      const updateList = inventoryDetail.lineItems.map((record: any) => {
        return {
          ...record,
          changed: false,
          counterValue: Number(record.quantity),
        };
      });
      setItems(updateList);
    } else {
      const updateList = inventoryDetail.receivedLineItems.map((record: any) => {
        return {
          ...record,
          changed: false,
          counterValue: Number(record?.receivedQuantity),
        };
      });
      setItems(updateList);
    }
    // setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryDetail.lineItems, type, inventoryDetail.receivedLineItems]);

  // useEffect(() => {
  //   if (isOrder) {
  //     if (type === 'Returning') {
  //       const updateList = getOrderDetails?.getOrderDetails?.lineItems.map((record: any) => {
  //         return {
  //           ...record,
  //           changed: false,
  //           counterValue: Number(record.quantity),
  //         };
  //       });
  //       setItems(updateList);
  //     } else {
  //       const updateList = getOrderDetails?.getOrderDetails?.receivedLineItems.map(
  //         (record: any) => {
  //           return {
  //             ...record,
  //             changed: false,
  //             counterValue: Number(record.quantity),
  //           };
  //         }
  //       );
  //       setItems(updateList);
  //     }
  //   }
  //   if (type === 'Returning') {
  //     const updateList = getInventoryRequestDetails?.getInventoryReturnDetails?.lineItems.map(
  //       (record: any) => {
  //         return {
  //           ...record,
  //           changed: false,
  //           counterValue: Number(record.quantity),
  //         };
  //       }
  //     );
  //     setItems(updateList);
  //   } else {
  //     const updateList = getInventoryRequestDetails?.getInventoryReturnDetails?.receivedLineItems.map(
  //       (record: any) => {
  //         return {
  //           ...record,
  //           changed: false,
  //           counterValue: Number(record.quantity),
  //         };
  //       }
  //     );
  //     setItems(updateList);
  //   }
  //   setLoader(false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getInventoryRequestDetails, getOrderDetails, type, isOrder, setItems]);

  const calculateActualQuantitity = useCallback((item: any, onHandQuantity: any) => {
    const isPackContent = item?.salesUnitOfMeasure === 'PK' || item?.salesUnitOfMeasure === 'BX';
    if (!isPackContent) {
      return onHandQuantity;
    }
    const packOfContentCount = item?.packContent > 0 ? item?.packContent || 1 : 1;
    if (item?.productFamily === 'SPTSIMPLANTS') {
      return onHandQuantity * packOfContentCount;
    }
    return onHandQuantity;
  }, []);
  const holdIconPath = getHoldIcon();
  const catalogInd = getSvgIcon('catalog');
  const formatLineItemToReSubmit = (lineItemsArray: any): any => {
    const finalLineItems: Array<any> = [];
    lineItemsArray.map(
      (item: {
        isLotNumber: any;
        productId: any;
        productNumber: any;
        quantity: any;
        actualQuantity: any;
        externalId: any;
        lotNumber: any;
        sfid: any;
        unitPrice: any;
        caseUsageExternalId: any;
        priceBookEntryId: any;
        salesUnitOfMeasure: any;
        deliveredDate: any;
        caseUsageId: any;
        returnReason: any;
        sourceLocation: any;
        returnType: any;
        trackingNumber: any;
        // eslint-disable-next-line array-callback-return
      }): any => {
        finalLineItems.push({
          caseUsageId: item.caseUsageId,
          caseUsageExternalId: item.caseUsageExternalId,
          priceBookEntryId: item.priceBookEntryId,
          salesUnitOfMeasure: item.salesUnitOfMeasure,
          deliveredDate: item.deliveredDate || null,
          productId: item.productId,
          productNumber: item.productNumber,
          quantity: item.quantity,
          actualQuantity: item.actualQuantity,
          isLotNumber: item?.isLotNumber,
          lotNumber: item.lotNumber || '',
          externalId: item.externalId || null,
          sfid: item.sfid || '',
          unitPrice: item.unitPrice || '',
          returnReason: item.returnReason || null,
          sourceLocation: item.sourceLocation,
          returnType: item.returnType,
          trackingNumber: item.trackingNumber || '',
        });
      }
    );
    return finalLineItems;
  };

  const handleUpdates = (): void => {
    setLoader(true);
    if (returnType === 'Failed') {
      // resubmitInventory();
      const payload: Array<any> = [];
      items.forEach((product: any): void => {
        if (product.changed) {
          const actualQuantity = calculateActualQuantitity(product, product.counterValue);
          payload.push({
            sfid: product.sfid,
            externalId: product.externalId,
            productId: product.productId,
            quantity: product.counterValue.toString(),
            actualQuantity: actualQuantity.toString(),
            productNumber: product.productNumber,
            priceBookEntryId: product.priceBookEntryId,
            salesUnitOfMeasure: product.salesUnitOfMeasure,
            sourceLocation: product.sourceLocation,
            lotNumber: product.lotNumber,
            isLotNumber: product?.isLotNumber,
            trackingNumber: product.trackingNumber,
            returnType: product.returnType,
            returnReason: product.returnReason
              ? typeof product.returnReason === 'string'
                ? product.returnReason
                : product.returnReason[0].value
              : null,
            deliveredDate: null,
            ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
            ...(product.caseUsageId && { caseUsageId: product.caseUsageId }),
            ...(product.caseUsageExternalId && {
              caseUsageExternalId: product.caseUsageExternalId,
            }),
          });
        }
      });
      const mutation = {
        id: inventoryDetail.id,
        caseId: inventoryDetail.caseId,
        caseExternalId: inventoryDetail.caseExternalId,
        // comments: userComment,
        submitType: 'Update',
        lineItems: formatLineItemToReSubmit(payload),
        salesRepId: inventoryDetail.salesRepId,
      };

      setLoader(true);
      reSubmitInventory({
        variables: mutation,
      })
        .then((res: any) => {
          if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
            setTimeout(() => {
              setLoader(false);
              openSnackBar(
                NOTIFY_TYPE.SUCCESS,
                'Line items for Return Inventory updated successfully'
              );
            }, 2500);
            refetch();
          }
        })
        .catch(error => {
          setLoader(false);
          if (error && error.message) {
            setLoader(false);
            openSnackBar(NOTIFY_TYPE.ERROR, `${error.message}`);
          } else openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Inventory Return error.');
          // eslint-disable-next-line no-console
          console.log('We had a trouble saving data to salesforce. Please try again later');
          // eslint-disable-next-line no-console
          console.log(error); // TODO change when some operation needs to be run on error.
        });
    } else {
      const payload: Array<any> = [];
      items?.forEach((product: any): void => {
        if (product.changed) {
          const actualQuantity = calculateActualQuantitity(product, product.counterValue);
          payload.push({
            productId: product.productId,
            externalId: product.externalId,
            id: product?.id,
            sourceLocation: product?.sourceLocation,
            returnReason: product.returnReason
              ? typeof product.returnReason === 'string'
                ? product.returnReason
                : product.returnReason[0].value
              : null,
            trackingNumber: product.trackingNumber,
            subInventoryCode: product?.caseUsageSubInventoryCode || '',
            quantity: product.counterValue.toString(),
            productNumber: product.productNumber,
            lotNumber: product.lotNumber,
            isLotNumber: product?.isLotNumber,
            actualQuantity: actualQuantity.toString(),
            ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
          });
        }
      });

      let mutation: any = {
        orderId: inventoryDetail.orderId,
        orderExternalId: inventoryDetail.orderExternalId,
        salesRepId: inventoryDetail.salesRepId,
        formType: 'Update',
        // returnType: inventoryDetail.returnType,
      };
      if (type === 'Returning') {
        mutation = {
          ...mutation,
          lineItems: payload,
        };
      } else {
        mutation = {
          ...mutation,
          receivedLineItems: payload,
        };
      }
      setLoader(true);
      createUpdateInventoryReturn({
        variables: mutation,
      })
        .then((res: any) => {
          if (
            res &&
            res.data &&
            res.data.createUpdateInventoryReturnLineItems.message === 'success'
          ) {
            setLoader(false);
            setTimeout(() => {
              setLoader(false);
              openSnackBar(
                NOTIFY_TYPE.SUCCESS,
                'Line items for Return Inventory updated successfully'
              );
            }, 1000);
            refetch();
          }
        })
        .catch(error => {
          setLoader(false);
          setTimeout(() => {
            if (error && error.message) {
              setLoader(false);
              openSnackBar(NOTIFY_TYPE.ERROR, `${error.message}`);
            } else {
              openSnackBar(
                NOTIFY_TYPE.ERROR,
                'Error:  We are having issue updating the line items for Return Inventory'
              );
            }
          }, 1000);
        });
    }
  };
  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout;
    if (lotChange === 1) {
      // // eslint-disable-next-line prefer-const
      delayDebounceFn = setTimeout(() => {
        handleUpdates();
      }, 2000);
      setLotChange(0);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    // return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: SelectedData) => item.value);
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };
  const handleFilter = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
    }
  };

  const handleAddParts = (): void => {
    refetch();
    setShowSearchCatalogModal(true);
  };

  const handleDragOnHand = (result: any): void => {
    if (!result.destination) {
      return;
    }
    const listItems =
      type === 'Returning'
        ? [...inventoryDetail.lineItems]
        : [...inventoryDetail.receivedLineItems];
    const reorderedItem = listItems.splice(result.source.index, 1);
    listItems.splice(result.destination.index, 0, ...reorderedItem);
  };
  const updateProducts: Array<any> = items;
  const handleCount = (event: any, data: { value: string; number: number }, product: any): void => {
    const updatedList = [...items];
    updatedList.forEach((element: any) => {
      if (
        product.productId === element.productId &&
        product.usageExternalId === element.usageExternalId &&
        product.lotNumber === element.lotNumber
      ) {
        const packContent =
          element?.salesUnitOfMeasure === 'PK' || element?.salesUnitOfMeasure === 'BX';

        const packOfContentCount = product?.packContent > 0 ? product?.packContent || 1 : 1;

        if (data.value !== '' && data.number > 0) {
          const maxQty = Number(element.availableQuantity) + Number(element.quantity);
          element.counterValue =
            element.iscatalog && data.number < MAX_QUANTITY
              ? data.number
              : data.number <= maxQty
              ? data.number
              : maxQty;
          if (
            userInfo?.businessunit === 'Sports Medicine' &&
            element.productFamily === 'SPTSIMPLANTS' &&
            packContent
          ) {
            element.actualQuantity = element.counterValue * packOfContentCount;
          } else {
            element.actualQuantity = element.counterValue;
          }
          element.changed = true;
        } else {
          element.counterValue = '';
          element.changed = false;
        }
      }
    });
    setItems(updatedList);
    // handleUpdates();
    const debounceHandleCountalert = debounce(handleUpdates, 1000);
    debounceHandleCountalert();
  };
  const handleCountalert = (
    event: any,
    data: { value: string; number: number },
    product: any
  ): void => {
    if (product?.productOnHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCount(event, data, product);
  };
  const handleDelete = (product: any): void => {
    // empty/clear quantity, it will delete ib Backend
    let counter = 0;
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (
        i.productId === product.productId &&
        i.usageExternalId === product.usageExternalId &&
        product.lotNumber === i.lotNumber
      ) {
        i.counterValue = 0;
        i.changed = true;
        i.deleted = true;
      }
      counter += i.counterValue;
    });
    setItems(listItems || []);
    handleUpdates();
  };
  const handleSelectDropdownItem = (data: any, item: any): void => {
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (i.externalId === item) {
        i.changed = true;
        i.sourceLocation = data?.selection[0]?.value;
        //  i.lotNumber = '';
      }
    });
    setItems(listItems || []);
    handleUpdates();
  };

  // const handleSelectDropdownItem = (data: any, item: any): void => {
  //   const listItems = [...items];
  //   listItems.forEach((i: any) => {
  //     if (
  //       i.productId === item.productId &&
  //       i.usageExternalId === item.usageExternalId &&
  //       item.lotNumber === i.lotNumber
  //     ) {
  //       i.returnReason = data.selection;
  //       i.changed = true;
  //     }
  //   });
  //   setItems(listItems || []);
  //   handleUpdates();
  // };

  const handleSelectReturnType = (data: any, item: any): void => {
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (
        i.productId === item.productId &&
        i.usageExternalId === item.usageExternalId &&
        item.lotNumber === i.lotNumber
      ) {
        i.returnReason = data.selection;
        i.changed = true;
        i.sourceLocation = data?.selection[0]?.value;
        //  i.lotNumber = '';
      }
    });
    setItems(listItems || []);
    handleUpdates();
  };

  const handleTrackingNumber = (event: any, item: any): void => {
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (
        i.productId === item.productId &&
        i.usageExternalId === item.usageExternalId &&
        item.lotNumber === i.lotNumber
      ) {
        i.trackingNumber = event.target.value;
        i.changed = true;
      }
    });
    setItems(listItems || []);
    setLotChange(1);
    // handleUpdates();
  };
  const handleLotSerialNumber = (event: any, item: any): void => {
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (i.productId === item.productId && item.lotNumber === i.lotNumber) {
        i.lotNumber = event.target.value;
        i.changed = true;
      }
    });
    setItems(listItems || []);
    setLotChange(1);
  };

  const handleUserWorkflowAction = (changeItem: any): void => {
    const listItemsF = [...items];
    listItemsF.forEach((e: any) => {
      if (
        e.productId === changeItem.productId &&
        e.caseUsageExternalId === changeItem.caseUsageExternalId
      ) {
        e.quantity = 0;
        e.actualQuantity = 0;
        e.counterValue = 0;
        e.changed = true;
        e.deleted = true;
      }
    });
    setItems(listItemsF);
    handleUpdates();
  };

  const iconPath = getEventIcon(CYCLE_COUNT);

  const setSearch = (value: string): void => {
    setLoader(true);
    setSearchText(value);
    if (returnType === 'Processed' || returnType === 'Failed') {
      refetchProcessedEventDetails();
    } else refetchEventDetails();
  };

  const debouncedFetchRequestedItems = useCallback(debounced(500, setSearch), [
    debounced,
    setSearch,
  ]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      debouncedFetchRequestedItems(value);
    },
    [debouncedFetchRequestedItems]
  );

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <div style={{ marginRight: '10px' }}>
          <CartComponent
            count={quantityCount}
            color="grey"
            textColor="white"
            cartTitle="Returning"
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          <CartComponent
            count={quantityRecCount}
            color="grey"
            textColor="white"
            cartTitle="Received"
          />
        </div>
        <Button
          iconCategory="utility"
          iconSize="small"
          className="slds-text-color_default"
          label="Add"
          iconName="add"
          iconPosition="left"
          disabled={
            isUserOpsCsr && returnType === 'Failed'
              ? false || viewOnly
              : visualIndicator.toLowerCase() === 'requested' ||
                visualIndicator.toLowerCase() === 'approved' ||
                visualIndicator.toLowerCase() === 'cancelled' ||
                visualIndicator.toLowerCase() === 'label requested' ||
                isOrder ||
                (returnType === 'Failed' && !isUserOps)
          }
          style={{ fontWeight: 'bold' }}
          onClick={handleAddParts}
        />
      </ButtonGroup>
    </PageHeaderControl>
  );

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {(isLoading || loading || processedLoading) && <Spinner />}
      <PageHeader
        className="events_header"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        onRenderActions={headerActions}
        variant="record-home"
        title="Inventory"
      />
      {showSearchCatalogModal && (
        <InventoryReturnAddItems
          isOpen={showSearchCatalogModal}
          refetch={refetch}
          handleClose={onHandleCloseSearchCatalogModal}
          inventoryReturnDetail={inventoryDetail}
          isEventInventoryFlow={isEventInventoryFlow}
          selectedFilter={selectedFilter}
          handleFilter={handleFilter}
          type={type}
          returnType={returnType}
          quantityCount={quantityCount}
        />
      )}
      <div className="order-list-container">
        <DragDropContext onDragEnd={handleDragOnHand}>
          <Droppable droppableId="items">
            {(provided: any): any =>
              type === 'Returning' ? (
                <div className="inventory-table-container">
                  <div className={`parts-table-header ${tableClass} `}>
                    {detailsViewConfig.includes('Part') && <p>Part</p>}
                    {detailsViewConfig.includes('Lot_Serial') && (
                      <p style={{ textAlign: 'left' }}>Lot/Serial</p>
                    )}

                    {detailsViewConfig.includes('Expiration') && (
                      <p style={{ textAlign: 'center' }}>Expiration Date</p>
                    )}
                    {detailsViewConfig.includes('Status') && (
                      <p style={{ textAlign: 'left', marginLeft: '25px' }}>Status</p>
                    )}
                    {detailsViewConfig.includes('Qty') && (
                      <p style={{ textAlign: 'center' }}>Qty</p>
                    )}
                    {/* {(detailsViewConfig.includes('returning_reason') ||
                      detailsViewConfig.includes('Return Reason')) && (
                      <p style={{ textAlign: 'center' }}>Return Reason</p>
                    )} */}
                    <p style={{ marginLeft: '13%' }}>Source Location</p>
                    {(!isPickupReturnType && detailsViewConfig.includes('tracking#')) ||
                    (!isPickupReturnType && detailsViewConfig.includes('Tracking')) ? (
                      <p style={{ textAlign: 'center' }}>Tracking #</p>
                    ) : (
                      ''
                    )}
                    {/* {detailsViewConfig.includes('Lot/Serial') && ( */}

                    <p style={{ textAlign: 'center' }}>Action</p>
                    {/* )} */}
                  </div>
                  {items?.length > 0 &&
                    items.map((lineItem: any) => {
                      return !lineItem.deleted ? (
                        <div className={`parts-table-header ${tableClass} `}>
                          {detailsViewConfig.includes('Part') && (
                            <div
                              style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                              className={
                                !lineItem?.isValidLotSerialCombination &&
                                lineItem.iscatalog &&
                                inventoryDetail?.status !== 'Completed' &&
                                lineItem.lotValidation === 'true'
                                  ? 'redPart'
                                  : ''
                              }
                            >
                              {`${lineItem.productNumber} ${
                                isOrder || returnType === 'Failed'
                                  ? lineItem.productdescription || lineItem.productDescription || ''
                                  : lineItem.productDescription
                              }`}
                              {lineItem.productOnHold ? (
                                <img
                                  src={holdIconPath}
                                  className="logo-image"
                                  alt="company"
                                  style={{
                                    marginLeft: '10px',
                                    width: '16px',
                                    height: '17px',
                                  }}
                                />
                              ) : (
                                ''
                              )}
                              {lineItem?.iscatalog && (
                                <img
                                  src={catalogInd}
                                  className="slds-tooltip-trigger"
                                  alt="catalog part"
                                  style={{
                                    marginLeft: '25px',
                                    marginRight: '8px',
                                    cursor: 'default',
                                  }}
                                  title="This part was selected from the Catalog"
                                />
                              )}
                            </div>
                          )}
                          {detailsViewConfig.includes('Lot_Serial') && (
                            <div
                              style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                            >
                              {!lineItem?.iscatalog && `${lineItem?.lotNumber || '--'}`}
                              {lineItem?.iscatalog && (
                                <div
                                  className="vertical-align-middle"
                                  style={{ paddingLeft: '10px', marginLeft: '-10%' }}
                                >
                                  <Input
                                    value={lineItem?.lotNumber ? lineItem?.lotNumber : ''}
                                    onChange={(_event: any): void =>
                                      handleLotSerialNumber(_event, lineItem)
                                    }
                                    autoComplete="off"
                                    placeholder="Enter Lot#"
                                    id="unique-id-1"
                                    style={{ marginLeft: '-10%' }}
                                    disabled={
                                      visualIndicator.toLowerCase() === 'requested' ||
                                      visualIndicator.toLowerCase() === 'submitted' ||
                                      visualIndicator.toLowerCase() === 'cancelled' ||
                                      visualIndicator.toLowerCase() === 'label requested' ||
                                      isOrder ||
                                      viewOnly ||
                                      (returnType === 'Failed' && !isUserOps)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          {detailsViewConfig.includes('Expiration') && (
                            <div
                              style={{
                                paddingLeft: '5px',
                                textAlign: 'center',
                                fontWeight: 'bold',
                              }}
                            >
                              {lineItem.expirationDate
                                ? moment(lineItem.expirationDate).format('MM/DD/YYYY')
                                : '--'}
                            </div>
                          )}
                          {detailsViewConfig.includes('Status') && (
                            <div style={{ paddingLeft: '5px', display: 'flex' }}>
                              <EventStatusBadge
                                status={isOrder ? lineItem.status : lineItem.lineVisualIndicator}
                              />
                              <br />
                            </div>
                          )}
                          {detailsViewConfig.includes('Qty') && (
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              <div className="row-control">
                                <Input
                                  id="counter-input-3"
                                  minValue={1}
                                  maxValue={
                                    lineItem.iscatalog
                                      ? MAX_QUANTITY
                                      : Number(lineItem.availableQuantity) +
                                        Number(lineItem.quantity)
                                  }
                                  disabled={
                                    // (lineItem &&
                                    //   lineItem.lineVisualIndicator &&
                                    //   lineItem.lineVisualIndicator.toLowerCase() === 'approved') ||
                                    isUserOpsCsr && returnType === 'Failed'
                                      ? false || viewOnly
                                      : visualIndicator.toLowerCase() === 'requested' ||
                                        visualIndicator.toLowerCase() === 'approved' ||
                                        visualIndicator.toLowerCase() === 'cancelled' ||
                                        visualIndicator.toLowerCase() === 'label requested' ||
                                        isOrder ||
                                        (returnType === 'Failed' && !isUserOps)
                                  }
                                  onChange={(
                                    event: any,
                                    data: { value: string; number: number }
                                  ): void => {
                                    handleCountalert(event, data, lineItem);
                                  }}
                                  value={lineItem.counterValue}
                                  variant="counter"
                                />
                              </div>
                            </div>
                          )}
                          {/* {(detailsViewConfig.includes('returning_reason') ||
                            detailsViewConfig.includes('Return Reason')) && (
                            <div className="vertical-align-middle" style={{ paddingLeft: '10px' }}>
                              <Combobox
                                id="combobox-readonly-single"
                                menuPosition="overflowBoundaryElement"
                                disabled={
                                  // (lineItem &&
                                  //   lineItem.lineVisualIndicator &&
                                  //   lineItem.lineVisualIndicator.toLowerCase() === 'approved') ||
                                  isUserOpsCsr &&
                                  returnType === 'Failed' &&
                                  (visualIndicator.toLowerCase() === 'approved' ||
                                    visualIndicator.toLowerCase() === 'failed')
                                    ? false
                                    : visualIndicator.toLowerCase() === 'requested' ||
                                      visualIndicator.toLowerCase() === 'approved' ||
                                      visualIndicator.toLowerCase() === 'cancelled' ||
                                      visualIndicator.toLowerCase() === 'label requested' ||
                                      isOrder ||
                                      (returnType === 'Failed' && !isUserOps) ||
                                      (returnType === 'Failed' && isBUSportsMed)
                                }
                                events={{
                                  onSelect: (_event: any, data: any): void =>
                                    handleSelectDropdownItem(data, lineItem),
                                }}
                                labels={{
                                  placeholder: 'Select',
                                }}
                                options={RETURNS_REASON_OPTION || []}
                                selection={
                                  typeof lineItem.returnReason === 'string'
                                    ? [
                                        {
                                          label: lineItem.returnReason,
                                          value: lineItem.returnReason,
                                        },
                                      ]
                                    : lineItem.returnReason || []
                                }
                                variant="readonly"
                              />
                            </div>
                          )} */}
                          <div className="row-control widthAuto">
                            {lineItem?.sourceLocation ? (
                              <div className="vertical-align-middle" style={{ marginLeft: '10%' }}>
                                {lineItem.sourceLocation}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          {(!isPickupReturnType && detailsViewConfig.includes('tracking#')) ||
                          (!isPickupReturnType && detailsViewConfig.includes('Tracking')) ? (
                            <div className="vertical-align-middle" style={{ paddingLeft: '10px' }}>
                              <Input
                                value={lineItem.trackingNumber ? lineItem.trackingNumber : ''}
                                onChange={(_event: any): void =>
                                  handleTrackingNumber(_event, lineItem)
                                }
                                autoComplete="off"
                                id="unique-id-4"
                                disabled={
                                  // (lineItem &&
                                  //   lineItem.lineVisualIndicator &&
                                  //   lineItem.lineVisualIndicator.toLowerCase() === 'approved') ||
                                  visualIndicator.toLowerCase() === 'requested' ||
                                  visualIndicator.toLowerCase() === 'submitted' ||
                                  visualIndicator.toLowerCase() === 'cancelled' ||
                                  visualIndicator.toLowerCase() === 'label requested' ||
                                  isOrder ||
                                  viewOnly ||
                                  (returnType === 'Failed' && !isUserOps)
                                }
                              />
                            </div>
                          ) : (
                            ''
                          )}
                          {/* {detailsViewConfig.includes('Lot/Serial') && ( */}
                          <div
                            className="vertical-align-middle"
                            style={{ paddingLeft: '5px', textAlign: 'center' }}
                          >
                            <Button
                              disabled={
                                isUserOpsCsr && returnType === 'Failed'
                                  ? false || viewOnly
                                  : visualIndicator.toLowerCase() === 'requested' ||
                                    // visualIndicator.toLowerCase() === 'approved' ||
                                    visualIndicator.toLowerCase() === 'cancelled' ||
                                    visualIndicator.toLowerCase() === 'submitted' ||
                                    isOrder ||
                                    viewOnly ||
                                    visualIndicator.toLowerCase() === 'label requested' ||
                                    (returnType === 'Failed' && !isUserOps)
                              }
                              iconCategory="utility"
                              iconName="delete"
                              iconSize="large"
                              iconVariant="bare"
                              variant="icon"
                              onClick={(): void => handleDelete(lineItem)}
                            />
                          </div>
                          {/* )} */}
                        </div>
                      ) : null;
                    })}
                </div>
              ) : (
                <div className="inventory-table-container">
                  <div className={`parts-table-header ${tableClass} `}>
                    <p>Part</p>
                    <p style={{ textAlign: 'left' }}>Lot/Serial</p>
                    {!isPickupReturnType && <p style={{ textAlign: 'center' }}>Expiration Date</p>}
                    {detailsViewConfig.includes('Status') && (
                      <p style={{ textAlign: 'left', marginLeft: '25px' }}>Status</p>
                    )}
                    {detailsViewConfig.includes('tracking#') ||
                      (detailsViewConfig.includes('Tracking') && (
                        <p style={{ textAlign: 'center' }}>Tracking #</p>
                      ))}
                    {detailsViewConfig.includes('Returning Qty') && (
                      <p style={{ textAlign: 'center' }}>Returning Qty</p>
                    )}
                    {detailsViewConfig.includes('Received_Qty') && (
                      <p style={{ textAlign: 'center' }}>Received Qty</p>
                    )}
                    {detailsViewConfig.includes('ERP_Order') && (
                      <p style={{ textAlign: 'center' }}>ERP Order</p>
                    )}
                    {detailsViewConfig.includes('Received') && (
                      <p style={{ textAlign: 'center' }}>Received</p>
                    )}
                  </div>
                  {items?.length > 0 &&
                    items.map((lineItem: any) => {
                      return !lineItem.deleted ? (
                        <div className={`parts-table-header ${tableClass} `}>
                          <div
                            style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                          >
                            {`${lineItem.productNumber} ${
                              isOrder || returnType === 'Failed'
                                ? lineItem.productdescription
                                : lineItem.productDescription
                            }`}
                            {lineItem.productOnHold ? (
                              <img
                                src={holdIconPath}
                                className="logo-image"
                                alt="company"
                                style={{ marginTop: '-36px', marginLeft: '57%' }}
                              />
                            ) : (
                              ''
                            )}
                            {lineItem?.iscatalog && (
                              <img
                                src={catalogInd}
                                className="slds-tooltip-trigger"
                                alt="catalog part"
                                style={{
                                  cursor: 'default',
                                }}
                                title="This part was selected from the Catalog"
                              />
                            )}
                            {lineItem?.failureReason && (
                              <span style={{ marginLeft: '10px' }} title={lineItem?.failureReason}>
                                <Icon
                                  category="utility"
                                  name="warning"
                                  size="x-small"
                                  style={{ fill: '#D2042D', marginRight: '5px' }}
                                />
                              </span>
                            )}
                            <br />
                          </div>

                          <div
                            style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                          >
                            {`${lineItem?.lotNumber || '--'}`}
                            {/* {lineItem?.iscatalog && (
                              <div
                                className="vertical-align-middle"
                                style={{ paddingLeft: '10px', marginLeft: '-10%' }}
                              >
                                <Input
                                  defaultValue={lineItem?.lotNumber ? lineItem?.lotNumber : ''}
                                  onBlur={(_event: any): void =>
                                    handleLotSerialNumber(_event, lineItem)
                                  }
                                  id="unique-id-1"
                                  style={{ marginLeft: '-10%' }}
                                  placeholder="Enter Lot#"
                                  disabled={
                                    visualIndicator.toLowerCase() === 'requested' ||
                                    visualIndicator.toLowerCase() === 'submitted' ||
                                    visualIndicator.toLowerCase() === 'cancelled' ||
                                    visualIndicator.toLowerCase() === 'label requested' ||
                                    isOrder ||
                                    (returnType === 'Failed' && !isUserOps) ||
                                    (returnType === 'Failed' && isBUSportsMed)
                                  }
                                />
                              </div>
                            )} */}
                          </div>

                          {!isPickupReturnType && (
                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                              {lineItem.expirationDate
                                ? moment(lineItem.expirationDate).format('MM/DD/YYYY')
                                : '--'}
                            </div>
                          )}

                          {detailsViewConfig.includes('Status') && (
                            <div style={{ textAlign: 'center' }}>
                              <EventStatusBadge
                                status={isOrder ? lineItem?.status : lineItem?.lineVisualIndicator}
                              />
                            </div>
                          )}
                          {/* <div className="row-control widthAuto">
                            {lineItem.sourceLocation ? (
                              <div className="vertical-align-middle">{lineItem.sourceLocation}</div>
                            ) : (
                              ''
                            )}
                          </div> */}
                          {detailsViewConfig.includes('tracking#') ||
                            (detailsViewConfig.includes('Tracking') && (
                              <div
                                className="vertical-align-middle"
                                style={{ paddingLeft: '10px' }}
                              >
                                <Input
                                  value={lineItem.trackingNumber ? lineItem.trackingNumber : ''}
                                  onChange={(_event: any): void =>
                                    handleTrackingNumber(_event, lineItem)
                                  }
                                  id="unique-id-4"
                                  disabled={
                                    visualIndicator.toLowerCase() === 'requested' ||
                                    visualIndicator.toLowerCase() === 'submitted' ||
                                    visualIndicator.toLowerCase() === 'cancelled' ||
                                    visualIndicator.toLowerCase() === 'label requested' ||
                                    isOrder ||
                                    viewOnly ||
                                    (returnType === 'Failed' && !isUserOps)
                                  }
                                />
                              </div>
                            ))}
                          {detailsViewConfig.includes('Returning Qty') && (
                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                              {`${lineItem?.quantity || ''}`}
                            </div>
                          )}
                          {detailsViewConfig.includes('Received_Qty') && (
                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                              {`${lineItem?.receivedQuantity || 0}`}
                            </div>
                          )}
                          {detailsViewConfig.includes('ERP_Order') && (
                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                              {`${lineItem?.erpOrderNumber || ''}`}
                            </div>
                          )}
                          {detailsViewConfig.includes('Received') && (
                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                              {lineItem?.receivedDate || '--'}
                            </div>
                          )}
                        </div>
                      ) : null;
                    })}
                </div>
              )
            }
          </Droppable>
        </DragDropContext>
      </div>
    </IconSettings>
  );
};

/** Custom Header on InventoryRequestDetailCatalog Page **/
export default InventoryRequestTable;
