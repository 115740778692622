/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import Modal from '@salesforce/design-system-react/components/modal';
import OrdersHeader from '../OrdersHeader';
import { API, LIMIT, NEXT_DATA, NOTIFY_TYPE, ORDER_TYPE, PREV_DATA } from '../../../util/constants';
import debounced from '../../../util/debounced';
import '../index.scss';
import FilterTags from '../../Shared/FilterTags';
import FilterOrders from '../FilterOrders';
import OrdersDataTable from '../OrderDataTable';
import { GET_ORDER_LIST } from '../../../graphql/getOrderList';
import { getUserId } from '../../../store/ducks/userId';
import { GET_FILTER_PRESETS } from '../../../graphql/getFilterPresets';
import {
  GET_WORKFLOW_PERMISSION,
  GET_WORKFLOW_PERMISSIONS,
} from '../../../graphql/workflowPermissions';
import { setOrderFilters, getOrderFilter } from '../../../store/ducks/orderFilters';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { openSnackBar } from '../../../store/ducks/snackBar';
import GENERATE_MULTIPLE_PDF from '../../../graphql/mutations/generateMultiplePdf';

interface Props {
  type: string;
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}
interface Filters {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  orderType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}
interface FilterPreset {
  filterValues: Filters;
  filterExternalId?: string;
  filterName?: string;
}

interface DynamicValue {
  [key: string]: any;
}

const ErpOrderList: FC<Props> = ({ type }) => {
  const [offsetOrderFailed, setOffsetOrderFailed] = useState(0);
  const [sortColumn, setSortColumn] = useState<any>();
  const [offsetOrderProcessed, setOffsetOrderProcessed] = useState(0);
  const [orders, setOrders] = useState<any[]>([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [enableGenerate, setEnableGenerate] = useState(false);
  const [generatePdf, setGeneratePdf] = useState(false);
  const [requestId, setRequestId] = useState(0);

  const userInfo = useSelector(getUserInfo);
  const businessUnit = userInfo?.businessunit || 'Sports Med';
  const [updatedSelectedFilter, setUpdatedSelectedFilter] = useState<
    FilterPreset | DynamicValue | null
  >(null);
  useQuery(GET_WORKFLOW_PERMISSIONS);
  const id = useSelector(getUserId);
  const [isEditFilter, setIsEditFilter] = useState(false);
  const { data: filterPresets, refetch: refetchFilterPresets } = useQuery(GET_FILTER_PRESETS, {
    variables: {
      userId: id,
      type: 'Orders',
    },
  });
  const { data: orderResubmitPermission } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'enable_order_resubmission',
    },
  });

  const { data: isCsrUser } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'is_csr_user',
    },
  });

  const [getOrdersList, { data: ordersList, loading: loadingOrderList }] = useLazyQuery(
    GET_ORDER_LIST,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [generateMultiplePDF] = useMutation(GENERATE_MULTIPLE_PDF);

  useEffect(() => {
    getOrdersList({
      variables: {
        limit: LIMIT,
        offset: 0,
        orderType: 'BILL_ONLY',
        screenType: type.toUpperCase(),
        filters: selectedFilterApiValues,
      },
    });
  }, [getOrdersList, selectedFilterApiValues, type]);
  const getOrdersFromList = useCallback((items: any[]): any[] => {
    if (items && items.length > 0) {
      const list = items.map((item: any) => {
        return {
          ...item,
          usageType: item.type === ORDER_TYPE.SJC_SM_BILL_ONLY ? 'Bill Only' : 'Ship & Bill',
          createdDate: item.createdDate ? moment(item.createdDate).format('L') : '--',
          eventDateTime: item.eventDateTime ? moment.utc(item.eventDateTime).format('L') : null,
          account: `${item.accountNumber} - ${item.accountName}`,
          PO: item.poNumber || 'Missing PO',
          procedureName: item.procedureName || '--',
          surgeon: item.surgeonFirstName
            ? `${item.surgeonFirstName} ${item.surgeonLastName}`
            : '--',
          totalAmount: item.totalAmount ? parseFloat(item.totalAmount).toFixed(2) : '0.00',
        };
      });
      return list;
    }
    return [];
  }, []);
  useEffect(() => {
    if (ordersList?.getOrderList) {
      const list = getOrdersFromList(ordersList.getOrderList);
      setOrders(list);
    }
  }, [ordersList, getOrdersFromList]);

  const sortOrderList = (items: any[]): void => {
    setOrders(items);
  };
  const handleEditFilter = (editFilter: boolean): void => {
    setIsEditFilter(editFilter);
  };
  const handleToggleFilterOrder = (): void => {
    handleEditFilter(false);
    setSelectedFilter(updatedSelectedFilter);
    setFilterModalVisible(!filterModalVisible);
  };
  const resetFilters = useCallback((): void => {
    setSelectedFilter(null);
    setUpdatedSelectedFilter(null);
    setSelectedFilterApiValues(null);
    setOffsetOrderFailed(0);
    setOffsetOrderProcessed(0);
    getOrdersList({
      variables: {
        offset: 0,
        limit: LIMIT,
        orderType: 'BILL_ONLY',
        screenType: type.toUpperCase(),
      },
    });
  }, [getOrdersList, type]);
  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          if (key === 'orderType') {
            values = filterValues[key].map((item: SelectedData) => item.label);
          } else {
            values = filterValues[key].map((item: SelectedData) => item.id);
          }
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key === 'accountType' && filterValues[key]) {
          const accountTypeSelected =
            filterValues[key] === 'My Accounts' ? 'MyAccounts' : 'TBAAccounts';
          reducedFilterValues = { ...reducedFilterValues, [key]: accountTypeSelected };
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  // function to refetch items on filter selection
  const handleRefetchItems = useCallback(
    (filterData: Filters | DynamicValue | null): void => {
      setOffsetOrderFailed(0);
      setOffsetOrderProcessed(0);
      getOrdersList({
        variables: {
          offset: 0,
          limit: LIMIT,
          type: type.toUpperCase(),
          filters: filterData,
        },
      });
    },
    [getOrdersList, type]
  );
  const handleEditPress = (filter: FilterPreset, isEditPress: boolean): void => {
    setSelectedFilter(filter);
    setFilterModalVisible(!filterModalVisible);
    if (isEditPress) {
      handleEditFilter(true);
    }
  };
  const filterPresetData = filterPresets?.getFilterPresets;
  const dispatch = useDispatch();
  const filterStore = useSelector(getOrderFilter);

  useEffect(() => {
    const setFilters = JSON.parse(filterStore || '{}');

    if (setFilters && setFilters.filterValues) {
      setSelectedFilter(setFilters);
      const filters = getFilterApiValue(setFilters.filterValues);
      setSelectedFilterApiValues(filters);
      handleRefetchItems(filters);
    }
  }, [filterStore, handleRefetchItems]);

  const handleFilterOrders = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    setUpdatedSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
      dispatch(setOrderFilters(JSON.stringify(filter)));
      handleRefetchItems(filters);
    } else {
      resetFilters();
    }
  };

  const handleDeleteFilterPreset = (externalId: string): void => {
    const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
    if (filterExternalId && filterExternalId === externalId) {
      resetFilters();
    }
  };

  const sortedcolumn = (property: any): void => {
    setSortColumn(property);
  };

  const setRequestSearch = (searchedItem: string): void => {
    getOrdersList({
      variables: {
        offset: 0,
        limit: LIMIT,
        orderType: 'BILL_ONLY',
        screenType: type.toUpperCase(),
        filters: {
          searchText: searchedItem,
        },
      },
    });
  };

  const debouncedFetchRequestedItems = useCallback(debounced(500, setRequestSearch), [
    debounced,
    setRequestSearch,
  ]);

  const handleOrderSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchRequestedItems(value);
      } else {
        debouncedFetchRequestedItems(value);
      }
    },
    [debouncedFetchRequestedItems]
  );

  // Pagination for Erp orders- processed and failed tabs items
  const handlePaginationButtonClicked = (nav: string): void => {
    let finalOffset;
    if (type.toUpperCase() === 'FAILED') {
      if (nav === NEXT_DATA) {
        finalOffset = offsetOrderFailed + LIMIT;
      } else {
        finalOffset = offsetOrderFailed - LIMIT;
      }
      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getOrdersList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            orderType: 'BILL_ONLY',
            orderBy: sortProperty,
            orderSortType: sortDirection,
            screenType: type.toUpperCase(),
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getOrdersList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            orderType: 'BILL_ONLY',
            screenType: type.toUpperCase(),
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetOrderFailed(finalOffset);
    } else if (type.toUpperCase() === 'PROCESSED') {
      if (nav === NEXT_DATA) {
        finalOffset = offsetOrderProcessed + LIMIT;
      } else {
        finalOffset = offsetOrderProcessed - LIMIT;
      }
      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getOrdersList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            orderType: 'BILL_ONLY',
            orderBy: sortProperty,
            orderSortType: sortDirection,
            screenType: type.toUpperCase(),
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getOrdersList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            orderType: 'BILL_ONLY',
            screenType: type.toUpperCase(),
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetOrderProcessed(finalOffset);
    }
  };

  const handleGeneratePdf = (): void => {
    const randomRequestId: any = Math.floor(Math.random() * (999999 - 100) + 100);
    setRequestId(randomRequestId.toString());
    const items: any = [];
    selectedRows.forEach((cases: any) => {
      items.push({
        salesRepId: userInfo?.id,
        caseId: cases.caseId,
        caseExternalId: cases.caseExternalId,
      });
    });
    const payload = {
      userId: userInfo?.id,
      requestId: randomRequestId.toString(),
      items,
    };
    generateMultiplePDF({
      variables: payload,
    }).catch(error => {
      // eslint-disable-next-line no-console
      openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
      console.log(error); // TODO change when some operation needs to be run on error.
    });
    setGeneratePdf(true);
  };

  const handleSelectedRows = (items: any): void => {
    setSelectedRows(items);
    const isSameAccounts = items?.every((accountId: any) => {
      return accountId.accountId === items[0].accountId;
    });
    if (items.length > 0 && items.length < 6 && isSameAccounts) {
      setEnableGenerate(true);
    } else {
      setEnableGenerate(false);
    }
  };

  const handleClosePdfModal = (): void => {
    setGeneratePdf(false);
  };

  return (
    <IconSettings iconPath="/icons">
      {filterModalVisible && (
        <FilterOrders
          handleToggleFilterOrder={handleToggleFilterOrder}
          filterModalVisible={filterModalVisible}
          selectedFilter={selectedFilter}
          refetchFilterPresets={refetchFilterPresets}
          handleFilterOrders={handleFilterOrders}
          resetFilters={resetFilters}
          type={type}
          handleClearFilter={handleDeleteFilterPreset}
          // handleEditFilter={handleEditFilter}
          isEditFilter={isEditFilter}
        />
      )}
      <OrdersHeader
        label={type}
        handleToggleFilterOrder={handleToggleFilterOrder}
        handleOrderSearch={handleOrderSearch}
        handleGeneratePdf={handleGeneratePdf}
        enableGenerate={enableGenerate}
        filterPresets={filterPresetData}
        onEditPress={handleEditPress}
        handleFilterOrders={handleFilterOrders}
        resetFilters={resetFilters}
        selectedFilterApiValues={selectedFilterApiValues}
        totalFilterResult={orders.length}
      />
      {selectedFilter && selectedFilter.filterValues && !isEditFilter && (
        <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterOrders} />
      )}
      <div className="order-list-container">
        {loadingOrderList && (
          <Spinner
            size="large"
            variant="base"
            assistiveText={{ label: 'Loading...' }}
            key="spinner"
          />
        )}
        <OrdersDataTable
          orders={orders || []}
          sortedcolumn={sortedcolumn}
          offset={type.toUpperCase() === 'FAILED' ? offsetOrderFailed : offsetOrderProcessed}
          getOrdersList={getOrdersList}
          type={type}
          sortOrderList={sortOrderList}
          isCsrUser={isCsrUser}
          selectedFilterApiValues={selectedFilterApiValues}
          orderResubmitPermission={orderResubmitPermission}
          handleSelectedRows={handleSelectedRows}
        />
      </div>
      {type.toUpperCase() === 'FAILED' && (
        <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronleft"
              iconSize="small"
              disabled={!(offsetOrderFailed > 0)}
              iconVariant="bare"
              onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
              variant="icon"
            />
          </div>
          <div className="slds-col slds-p-left_medium slds-p-right_medium">
            <span>{`${offsetOrderFailed + 1} - ${offsetOrderFailed + LIMIT}`}</span>
          </div>
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronright"
              iconSize="small"
              iconVariant="bare"
              disabled={ordersList?.getOrderList.length < 50}
              onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
              variant="icon"
            />
          </div>
        </div>
      )}
      {type.toUpperCase() === 'PROCESSED' && (
        <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronleft"
              iconSize="small"
              disabled={!(offsetOrderProcessed > 0)}
              iconVariant="bare"
              onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
              variant="icon"
            />
          </div>
          <div className="slds-col slds-p-left_medium slds-p-right_medium">
            <span>{`${offsetOrderProcessed + 1} - ${offsetOrderProcessed + LIMIT}`}</span>
          </div>
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronright"
              iconSize="small"
              iconVariant="bare"
              disabled={ordersList?.getOrderList.length < 50}
              onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
              variant="icon"
            />
          </div>
          {generatePdf && (
            <Modal
              className="cancel-creation-modal default-modal_header default-modal_footer"
              isOpen={generatePdf}
              onRequestClose={handleClosePdfModal}
              ariaHideApp={false}
              footer={[
                <Button label="Dismiss" variant="brand" onClick={handleClosePdfModal} key="no" />,
              ]}
              heading="Generated PDF"
            >
              <div className="slds-text-align--center">
                <div>
                  <h2 className="slds-m-around--large ">
                    {`PDF(s) will be generated under Request ID 
                  ${requestId} 
                  after 10 mins.`}
                  </h2>
                  <h2>PDF(s) can be viewed from Orders menu, under Generated PDF tab.</h2>
                  <h2>
                    PDF(s) will be valid until next 8 hours.
                    <br />
                    Any changes made after submitting the generate PDF request will not be reflected
                    in the generated PDF.
                  </h2>
                  <br />
                </div>
              </div>
            </Modal>
          )}
        </div>
      )}
    </IconSettings>
  );
};

export default ErpOrderList;
