/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable array-callback-return */

/**
 * Module for showing Events with their status.
 * @module src/components/Events/EventsList/index
 */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useCallback, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
} from 'date-fns/esm';
import EventsHeader from './EventsHeader';
import EventsCalendar from './EventsCalendar';
import EventsList from './EventsList';
import { Event, EventItemsData } from '../../graphql/getEventItems';
import { GET_FORM_CONFIGURATIONS } from '../../graphql/getFormConfigurations';
// import { LIMIT } from '../../util/constants';
import { getUserId } from '../../store/ducks/userId';
import { getUserInfo } from '../../store/ducks/userInfo';
import debounced from '../../util/debounced';
import { excelExport } from '../../util/utilityFunctions';
import {
  setEventFilters,
  getEventFilters,
  clearEventFilters,
} from '../../store/ducks/eventFilters';
import './index.scss';

import {
  GET_WORKFLOW_PERMISSION,
  GET_WORKFLOW_PERMISSIONS,
} from '../../graphql/workflowPermissions';
import { GET_USER_TERRITORIES } from '../../graphql/getUserTerritories';
// Will need to use the commented code below for S-108749
// import { GET_TRANSLATIONS } from '../../graphql/getTranslations';
import FilterEvent from '../FilterEvent';
import { GET_FILTER_PRESETS } from '../../graphql/getFilterPresets';
import { GET_EVENT_INVENTORY_DETAILS } from '../../graphql/getEventInventoryDetails';

// import EventSearch from './EventsSearch';
import EventsCard from './EventsCard';
import EventsInventoryExposure from './EventInventoryExposure';
import FilterTags from '../Shared/FilterTags';
import { setHoldBannerStatus } from '../../store/ducks/holdBanner';
import {
  clearExposureFilters,
  getExposureFilters,
  setExposureFilters,
} from '../../store/ducks/eventExposure';

interface Filters {
  status?: string[];
  salesReps?: string[];
  coveringReps?: string[];
  account?: string;
  branch?: string[];
  franchise?: string[];
  surgeon?: string;
  procedureDetail?: string[];
  procedureType?: string[];
  startDate?: Date | string;
  endDate?: Date | string;
  usage?: string;
  eventType?: string[];
  search?: string;
  searchText?: string;
}

interface SelectedData {
  id: string;
  productId?: string;
  label: string;
  territoryId?: string;
  regionId?: string;
  __typename?: string;
}
interface Filter {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
  searchText?: string;
}

interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  filterName?: string;
}

interface DynamicValue {
  [key: string]: any;
}

interface EventDataVariables {
  variables: {
    offset: number;
    limit: number;
    id: string | null;
    filters?: DynamicValue | null;
    startDate: Date;
    endDate: Date;
  };
}

interface Props extends RouteComponentProps {
  children?: FC;
  activeTab?: string;
  getEventsData: (variables: any) => void;
  data: EventItemsData;
  loading: boolean;
  refetchEventItems: (data?: any) => void;
  setOffset: (item: number) => void;
  offset: number;
  activeTabClick: number;
  tabValue?: number;
  history: any;
}

const Events: FC<Props> = ({
  activeTab = 'All',
  loading,
  data,
  getEventsData,
  refetchEventItems,
  offset,
  setOffset,
  activeTabClick,
  tabValue = 0,
  history,
  location,
}) => {
  const filterStore = useSelector(getEventFilters);
  const exposureStore = useSelector(getExposureFilters);
  const { pathname } = history.location;
  const dispatch = useDispatch();
  dispatch(setHoldBannerStatus(false));
  const userInfo = useSelector(getUserInfo);
  const LIMIT = 50;
  const [showCalendar, setShowCalendar] = useState(tabValue === 0);
  const [showList, setShowList] = useState(tabValue === 1);
  const [showCard, setShowCard] = useState(tabValue === 2);
  const [showEventExposure, setShowEventExposure] = useState(tabValue === 3);
  const [isEditFilter, setIsEditFilter] = useState(false);
  const [filterEventModalVisible, setFilterEventModalVisible] = useState(false);
  const [eventItems, setEventItems] = useState<Event[]>([]);
  const [inventoriesView, setInventoriesView] = useState<any>([]);
  const [selectedTerritory, setSelectedTerritory] = useState<any>([]);
  const [sortColumn, setSortColumn] = useState<any>();
  const [sortDirectionData, setSortDirectionData] = useState<any>();
  const [territoryOptions, setTerritoryOptions] = useState<any[]>();
  const [updatedSelectedFilter, setUpdatedSelectedFilter] = useState<
    FilterPreset | DynamicValue | null
  >(null);
  const [selectedFilter, setSelectedFilter] = useState<FilterPreset | DynamicValue | null>(null);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<Filters | null>(null);
  // const [searchModalVisible, setSearchModalVisible] = useState(false);
  // const [value, setValue] = useState(tabValue);

  const id = useSelector(getUserId);
  const [getTerritories, { data: territoryData }] = useLazyQuery(GET_USER_TERRITORIES);
  const { data: filterPresets, refetch: refetchFilterPresets } = useQuery(GET_FILTER_PRESETS, {
    variables: {
      userId: id,
      type: pathname.toLowerCase().includes('event_exposure') ? 'Exposure' : 'Events',
    },
  });
  const [getWorkflowPermissions] = useLazyQuery(GET_WORKFLOW_PERMISSIONS);
  const [getFormConfigurations] = useLazyQuery(GET_FORM_CONFIGURATIONS);

  // Create a useQuery call for getFormConfig, grab that data, and pass it in accordingly
  const { data: showSurgeonData } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      action: 'view_surgeon_cases',
    },
  });
  const sortedcolumn = (property: any): void => {
    setSortColumn(property);
  };
  const setSortDirection = (property: any): void => {
    setSortDirectionData(property);
  };

  const [
    getEventInventory,
    { data: eventInventoryData, loading: eventInventoryLoading, refetch: refetchEventInventory },
  ] = useLazyQuery(GET_EVENT_INVENTORY_DETAILS, {
    fetchPolicy: 'no-cache',
    // notifyOnNetworkStatusChange: true,
  });

  const fetchKeysFromArray = (el: any) => {
    const dataArray: any = [];
    el.map((item: any) => {
      dataArray.push(item.value);
    });
    return dataArray;
  };

  useEffect(() => {
    if (selectedTerritory) {
      const dataArray = fetchKeysFromArray(selectedTerritory);
      getEventInventory({
        variables: {
          // salesRepId: userInfo?.id,
          subInventoryName: dataArray,
          filters: {},
        },
      });
    }
  }, [getEventInventory, selectedTerritory]);

  useEffect(() => {
    if (userInfo) {
      getTerritories({
        variables: {
          userId: userInfo?.id,
        },
      });
    }
  }, [getTerritories, userInfo]);

  const getInventoryListFormatted = useCallback((items: any[]): any[] => {
    if (items && items.length > 0) {
      const list = items.map((item: any) => {
        return {
          ...item,
        };
      });
      return list;
    }
    return [];
  }, []);
  const resetFilters = useCallback((): void => {
    setSelectedFilter(null);
    setUpdatedSelectedFilter(null);
    setIsEditFilter(false);
    setSelectedFilterApiValues(null);
    setOffset(0);
    if (showEventExposure) {
      getEventInventory({
        variables: {
          salesRepId: userInfo?.id,
        },
      });
      dispatch(clearExposureFilters());
    } else {
      getEventsData({
        variables: { offset: 0, limit: showCard && showList ? LIMIT : 1000, id, filters: {} },
      });
      dispatch(clearEventFilters());
    }
  }, [dispatch, getEventsData, id, setOffset, showEventExposure]);

  useEffect(() => {
    if (eventInventoryData && eventInventoryData?.getEventInventoryDetails) {
      const list = getInventoryListFormatted(eventInventoryData?.getEventInventoryDetails);
      setInventoriesView(list);
    }
  }, [eventInventoryData, setInventoriesView, getInventoryListFormatted]);

  const handleExport = (): void => {
    const result: any = [];
    inventoriesView.map((item: any) => {
      item.accounts.map((itemData: any) => {
        itemData.events.map((elem: any) => {
          elem.lineItems.map((el: any) => {
            const obj = {
              Territory: item.territoryName,
              Account: itemData.accountName,
              Event: elem.eventNumber,
              'Event Name': elem.eventName,
              'Sales Rep': elem.salesRepName,
              Part: `${el.productNumber} ${el.productdescription}`,
              'Lot Number': el.lotNumber,
              Quantity: el.quantity,
              'Days at Event': Number(elem.daysAtEvent) >= 0 ? Number(elem.daysAtEvent) : 0,
            };
            result.push(obj);
          });
        });
      });
    });
    excelExport([{ title: 'All Data', data: result }], 'Event Exposure.xlsx');
  };
  /*
  Will need to use this section later when utilizing i18next because users can direct
  immediately to any routed page. This means that I'd need to import the translations, 
  check if the resource bundle exists, and set it for it does not.
  */
  // const { data: translationData } = useQuery(GET_TRANSLATIONS, {
  //   variables: {
  //     translationKey: 'Translation-en_US',
  //   },
  // });

  // eslint-disable-next-line no-underscore-dangle
  const optionsWithLabelTerritory = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return { ...elem, label: `${elem.value}` };
    });

  useEffect(() => {
    if (territoryData?.getUserTerritories) {
      setTerritoryOptions(optionsWithLabelTerritory(territoryData.getUserTerritories));
    }
  }, [territoryData]);

  useEffect(() => {
    if (territoryOptions && territoryOptions.length === 1) {
      setSelectedTerritory(territoryOptions);
    }
  }, [territoryOptions]);

  useEffect(() => {
    if (activeTabClick) {
      resetFilters();
    }
  }, [activeTabClick, resetFilters]);

  const { data: enableOracleStatus } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      action: 'enable_oracle_status',
    },
  });

  useEffect(() => {
    getWorkflowPermissions();
    // resetFilters();
  }, [getWorkflowPermissions]);

  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  // useEffect(() => {
  //   getEventsData({ variables: { offset, limit: LIMIT, id } });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    if (showList || showCard) {
      let filters;
      if (selectedFilterApiValues) {
        filters = selectedFilterApiValues;
      } else {
        // default status
        filters = {
          status: ['New', 'Assigned', 'Requested', 'Shipped'],
        };
      }
      getEventsData({
        variables: {
          offset: 0,
          limit: LIMIT,
          id,
          filters,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showList, showCard]);
  useEffect(() => {
    setEventItems(data && data.eventItems);
  }, [data]);

  const handleEditFilter = (editFilter: boolean): void => {
    setIsEditFilter(editFilter);
  };

  useEffect(() => {
    setShowCalendar(false);
    setShowList(false);
    setShowCard(false);
    setShowEventExposure(false);
    if (pathname.includes('/events/calendar')) {
      setShowCalendar(true);
    } else if (pathname.includes('/events/list')) {
      setShowList(true);
    } else if (pathname.includes('/events/card_view')) {
      setShowCard(true);
    } else if (pathname.includes('/events/event_exposure')) {
      setShowEventExposure(true);
    } else {
      setShowCalendar(true);
    }
  }, [location]);

  const handleChange = (e: any): void => {
    const tabName = e.target.innerText;
    switch (tabName) {
      case 'Calendar':
        history.push('/events/calendar');
        return;
      case 'List':
        history.push('/events/list');
        return;
      case 'Card View':
        history.push('/events/card_view');
        return;
      case 'Event Inventory Exposure':
        history.push('/events/event_exposure');
        return;
      default:
        history.push('/');
    }
  };

  const handleToggleFilterEvent = (): void => {
    handleEditFilter(false);
    setSelectedFilter(updatedSelectedFilter);
    setFilterEventModalVisible(!filterEventModalVisible);
  };
  let calendarDate = new Date();
  const selDate = localStorage.getItem('calendarDate');
  if (selDate) {
    calendarDate = JSON.parse(selDate);
  }
  const start = startOfMonth(calendarDate);
  const end = endOfMonth(calendarDate);
  let cycleCount = false;
  const handleRefetchItems = useCallback(
    (filterData: Filters | null): void => {
      const dataFilter = filterData;
      if (dataFilter && Object.keys(dataFilter).length > 0) {
        if (typeof dataFilter.eventType !== 'undefined') {
          if (dataFilter.eventType.includes('Cycle Count')) {
            cycleCount = true;
          }
        }
      }
      setOffset(0);
      getEventsData({
        variables: {
          startDate: cycleCount && showCalendar ? start : '',
          endDate: cycleCount && showCalendar ? end : '',
          offset: 0,
          limit: showCard || showList ? LIMIT : 1000,
          id,
          filters: dataFilter,
        },
      });
    },
    [getEventsData, id, setOffset]
  );

  const handleEditPress = (filter: FilterPreset, isEditPress: boolean): void => {
    setSelectedFilter(filter);
    setFilterEventModalVisible(!filterEventModalVisible);
    if (isEditPress) {
      handleEditFilter(true);
    }
  };

  const getFilterApiValue = (filterValues: DynamicValue): Filters => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          // These are the only two fields which we need to send the name not Id.
          if (key === 'region') {
            values = filterValues[key].map((item: SelectedData) =>
              item?.regionId ? item?.regionId : item?.id
            );
          } else if (key === 'territory') {
            values = filterValues[key].map((item: SelectedData) =>
              item?.territoryId ? item?.territoryId : item?.id
            );
          } else if (key === 'franchise' || key === 'coveringReps') {
            values = filterValues[key].map((item: SelectedData) => item.label);
          } else if (key === 'event') {
            values = filterValues[key].map((item: SelectedData) => item);
          } else {
            values = filterValues[key].map((item: SelectedData) => item.id);
          }
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (Array.isArray(filterValues[key]) && filterValues[key]) {
          if (filterValues[key].length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
          }
        } else if (key === 'accountType' && filterValues[key]) {
          const accountTypeSelected =
            filterValues[key] === 'My Accounts' ? 'My Accounts' : 'TBAAccounts';
          reducedFilterValues = { ...reducedFilterValues, [key]: accountTypeSelected };
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const getFilterApiValueForExposure = (filterValues: DynamicValue): Filters => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          // These are the only two fields which we need to send the name not Id.
          if (key === 'region') {
            values = filterValues[key].map((item: SelectedData) =>
              item?.regionId ? item?.regionId : item?.id
            );
          } else if (key === 'territory') {
            values = filterValues[key].map((item: SelectedData) =>
              item?.territoryId ? item?.territoryId : item?.id
            );
          } else if (key === 'franchise' || key === 'coveringReps') {
            values = filterValues[key].map((item: SelectedData) => item.label);
          } else if (key === 'event') {
            values = filterValues[key].map((item: SelectedData) => item.id);
          } else if (key === 'productId' && filterValues[key].length !== 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key][0].productId };
          } else {
            values = filterValues[key].map((item: SelectedData) => item.id);
          }
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (Array.isArray(filterValues[key]) && filterValues[key]) {
          if (filterValues[key].length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleSelectTerritory = (item: any, name: any) => {
    const { selection } = item;
    if (selection) {
      setSelectedTerritory(selection);
    }
  };

  const handleFilterEvents = (
    filterValues: Filter | DynamicValue | null,
    filterName?: string
  ): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setUpdatedSelectedFilter(filter);
    setSelectedFilter(filter);
    if (filterValues) {
      const filters: any = getFilterApiValueForExposure(filterValues);
      if (showEventExposure) {
        setSelectedFilterApiValues(filters);
        let dataArray = fetchKeysFromArray(selectedTerritory);
        if (filters?.territory) {
          dataArray = filters?.territory;
        }
        getEventInventory({
          variables: {
            salesRepId: userInfo?.id,
            subInventoryName: dataArray,
            filters,
          },
        });
        dispatch(setExposureFilters(JSON.stringify(filter)));
      } else {
        setSelectedFilterApiValues(filters);
        handleRefetchItems(filters);
        dispatch(setEventFilters(JSON.stringify(filter)));
      }
    } else {
      resetFilters();
    }
  };

  // const handleSearchModalVisble = (): void => {
  //   setSearchModalVisible(!searchModalVisible);
  // };

  const setSearch = (searchedItem: string): void => {
    const appliedFilters = selectedFilterApiValues || {};

    if (showEventExposure) {
      appliedFilters.searchText = searchedItem || '';
      const dataArray = fetchKeysFromArray(selectedTerritory);
      if (searchedItem.length >= 3) {
        getEventInventory({
          variables: {
            // salesRepId: userInfo?.id,
            subInventoryName: dataArray,
            filters: appliedFilters,
          },
        });
      } else {
        getEventInventory({
          variables: {
            // salesRepId: userInfo?.id,
            subInventoryName: dataArray,
            filters: appliedFilters,
          },
        });
      }
    } else {
      appliedFilters.search = searchedItem || '';
      if (searchedItem.length >= 3) {
        getEventsData({
          variables: {
            offset: 0,
            limit: showCard && showList ? LIMIT : 1000,
            id,
            filters: appliedFilters,
          },
        });
      } else {
        getEventsData({
          variables: {
            offset: 0,
            limit: showCard && showList ? LIMIT : 1000,
            id,
            filters: appliedFilters,
          },
        });
      }
    }
  };

  const debouncedFetchItems = useCallback(debounced(500, setSearch), [debounced, setSearch]);

  const handleEventSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchItems(value);
      } else {
        debouncedFetchItems(value);
      }
    },
    [debouncedFetchItems]
  );

  const handleDeleteFilterPreset = (externalId: string): void => {
    const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
    if (filterExternalId && filterExternalId === externalId) {
      resetFilters();
    }
  };

  const handleRefreshEventList = (): void => {
    if (showCalendar) {
      getEventsData({
        variables: { startDate: start, endDate: end, id, filters: selectedFilterApiValues },
      });
    } else
      getEventsData({
        variables: { offset, limit: LIMIT, id, filters: selectedFilterApiValues },
      });
  };

  const handleRefreshExposureList = (): void => {
    const dataArray = fetchKeysFromArray(selectedTerritory);
    getEventInventory({
      variables: {
        salesRepId: userInfo?.id,
        subInventoryName: dataArray,
        filters: selectedFilterApiValues,
      },
    });
  };

  const filterData = filterPresets?.getFilterPresets;
  const eventCount: number = eventItems?.length;
  const exposureCount: number = inventoriesView?.length;

  // Preventing filter reset on back

  useEffect(() => {
    let setFilters;
    if (pathname.includes('/events/event_exposure')) {
      setFilters = JSON.parse(exposureStore || '{}');
    } else {
      setFilters = JSON.parse(filterStore || '{}');
    }
    if (setFilters) {
      setUpdatedSelectedFilter(setFilters);
      setSelectedFilter(setFilters);
      const filters = getFilterApiValue(setFilters.filterValues);
      setSelectedFilterApiValues(filters);
    }
  }, [filterStore, exposureStore, pathname, handleRefetchItems]);

  return (
    <div>
      <Paper className="paper" square>
        <Tabs
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          // value={value}
          onChange={handleChange}
          aria-label="events_view_selection"
        >
          <Tab className={showCalendar ? 'active' : 'inactive'} label="Calendar" />
          <Tab className={showList ? 'active' : 'inactive'} label="List" />
          <Tab className={showCard ? 'active' : 'inactive'} label="Card View" />
          <Tab
            className={showEventExposure ? 'active' : 'inactive'}
            label="Event Inventory Exposure"
          />
        </Tabs>
      </Paper>
      <IconSettings iconPath="/icons">
        <div className="slds-p-horizontal_large slds-p-vertical_x-large events_list">
          <EventsHeader
            eventCount={eventCount}
            exposureCount={exposureCount}
            // handleToggleListView={handleToggleListView}
            isShowCalendar={showCalendar}
            isShowEventExposure={showEventExposure}
            showSurgeonData={showSurgeonData}
            refetchEventItems={refetchEventItems}
            handleToggleFilterEvent={handleToggleFilterEvent}
            filterPresets={filterData}
            onEditPress={handleEditPress}
            handleFilterEvents={handleFilterEvents}
            // handleSearchModalVisble={handleSearchModalVisble}
            resetFilters={resetFilters}
            selectedFilterApiValues={selectedFilterApiValues}
            handleRefreshEventList={handleRefreshEventList}
            handleRefreshExposureList={handleRefreshExposureList}
            handleSearch={handleEventSearch}
            handleExport={handleExport}
            handleSelect={handleSelectTerritory}
            selectedTerritory={selectedTerritory}
            territoryOptions={territoryOptions}
          />
          {selectedFilter && selectedFilter.filterValues && !isEditFilter && (
            <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterEvents} />
          )}
          {filterEventModalVisible && (
            <FilterEvent
              history={history}
              handleToggleFilterEvent={handleToggleFilterEvent}
              filterEventModalVisible={filterEventModalVisible}
              selectedFilter={selectedFilter}
              refetchFilterPresets={refetchFilterPresets}
              handleFilterEvents={handleFilterEvents}
              resetFilters={resetFilters}
              handleEditFilter={handleEditFilter}
              isShowEventExposure={showEventExposure}
              isEditFilter={isEditFilter}
              handleClearFilter={handleDeleteFilterPreset}
            />
          )}
          {/* {searchModalVisible && (
          <EventSearch
            handleSearchModalVisble={handleSearchModalVisble}
            searchModalVisible={searchModalVisible}
            handleEventSearch={handleEventSearch}
          />
        )} */}
          {showCalendar ? (
            <EventsCalendar
              loading={loading}
              activeTab={activeTab}
              selectedFilterApiValues={selectedFilterApiValues}
              getEventsData={getEventsData}
              eventItems={eventItems || []}
            />
          ) : showList ? (
            [
              loading ? (
                <Spinner
                  size="large"
                  variant="base"
                  assistiveText={{ label: 'Loading...' }}
                  key="spinner"
                />
              ) : (
                <EventsList
                  getEventsData={getEventsData}
                  eventItems={eventItems}
                  setOffset={setOffset}
                  offset={offset}
                  sortDirectionData={sortDirectionData}
                  sortedcolumn={sortedcolumn}
                  setSortDirection={setSortDirection}
                  enableOracleStatus={enableOracleStatus}
                  activeTab={activeTab}
                  refetchEventItems={refetchEventItems}
                  selectedFilterApiValues={selectedFilterApiValues}
                  key="event_list"
                />
              ),
            ]
          ) : showCard ? (
            [
              loading ? (
                <Spinner
                  size="large"
                  variant="base"
                  assistiveText={{ label: 'Loading...' }}
                  key="spinner"
                />
              ) : (
                <EventsCard
                  getEventsData={getEventsData}
                  eventItems={eventItems}
                  setOffset={setOffset}
                  offset={offset}
                  enableOracleStatus={enableOracleStatus}
                  activeTab={activeTab}
                  selectedFilterApiValues={selectedFilterApiValues}
                  key="event_card"
                />
              ),
            ]
          ) : showEventExposure ? (
            [
              eventInventoryLoading ? (
                <Spinner
                  size="large"
                  variant="base"
                  assistiveText={{ label: 'Loading...' }}
                  key="spinner"
                />
              ) : (
                <EventsInventoryExposure inventoriesView={inventoriesView} />
              ),
            ]
          ) : null}
        </div>
      </IconSettings>
    </div>
  );
};

/** Events Listing and Creation Page **/
export default withRouter(Events);
