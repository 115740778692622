/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { FC, useState } from 'react';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import '../index.scss';
import OrderStatusCell from './OrderStatusCell';
import OrderIdCell from './OrderIdCell';
import EventCell from './EventCell';
import OrderRequestIdCell from './OrderRequestIdCell';
import OrderRequestStatusCell from './OrderRequestStatusCell';
import SubmitDateCell from './SubmitDateCell';
import AccountCell from './AccountCell';
import Inventory3PL from './Inventory3PL';

interface Props {
  type: string;
  orders: any[];
  getRequestsList: (list: any) => void;
  sortedcolumn: (property: any) => void;
  filters: any;
  offsetData?: any;
}

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}

// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
OrderIdCell.displayName = DataTableCell.displayName;
OrderStatusCell.displayName = DataTableCell.displayName;
OrderRequestIdCell.displayName = DataTableCell.displayName;
OrderRequestStatusCell.displayName = DataTableCell.displayName;
SubmitDateCell.displayName = DataTableCell.displayName;
AccountCell.displayName = DataTableCell.displayName;
EventCell.displayName = DataTableCell.displayName;
Inventory3PL.displayName = DataTableCell.displayName;

const OrdersDataTable: FC<Props> = ({
  orders,
  type,
  getRequestsList,
  sortedcolumn,
  filters,
  offsetData,
}) => {
  const [sortObj, setSortObj] = useState<Sort>();
  const handleSort = (sortColumn: any): void => {
    const sortProperty = sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    // needs to work in both directions
    setSortObj(sort);
    sortedcolumn(sortColumn);
    if (type === 'Open') {
      getRequestsList({
        variables: {
          limit: 50,
          orderBy: sortProperty,
          orderSortType: sortDirection,
          offset: offsetData,
          type: 'REQUESTED',
          filters,
        },
      });
    } else if (type === 'Failed' || type === 'Processed') {
      getRequestsList({
        variables: {
          limit: 50,
          orderBy: sortProperty,
          orderSortType: sortDirection,
          offset: offsetData,
          orderType: 'INVENTORY_REQUEST',
          screenType: type.toUpperCase(),
          filters,
        },
      });
    }
  };
  return (
    <DataTable
      assistiveText={{
        columnSort: 'sort this column',
        columnSortedAscending: 'asc',
        columnSortedDescending: 'desc',
      }}
      items={orders || []}
      id="DataTableExample-2"
      className="font-12"
      fixedLayout
      keyboardNavigation
      joined
      onSort={handleSort}
    >
      {[
        type === 'Favorites' ? (
          <DataTableColumn
            key="eventId"
            label="Name"
            property="eventId"
            width="20%"
            isSorted={sortObj?.sortColumn === 'eventId'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.eventId}
          >
            <OrderRequestIdCell type={type} />
          </DataTableColumn>
        ) : type === 'Open' ? (
          <DataTableColumn
            key="eventId"
            label="IR"
            property="eventId"
            width="10%"
            isSorted={sortObj?.sortColumn === 'eventId'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.eventId}
          >
            <OrderRequestIdCell type={type} />
          </DataTableColumn>
        ) : (
          <DataTableColumn
            key="caseNumber"
            label="IR"
            property="caseNumber"
            width="10%"
            {...(type !== 'Processed' && {
              isSorted: sortObj?.sortColumn === 'caseNumber',
              sortable: true,
              sortDirection: sortObj?.sortColumnDirection.caseNumber,
            })}
          >
            <OrderRequestIdCell type={type} />
          </DataTableColumn>
        ),
        (type === 'Failed' || type === 'Processed' || type === 'Open') && (
          <DataTableColumn
            key="parentEventId"
            label="Event"
            property="parentEventId"
            width="10%"
            {...(type !== 'Processed' && {
              isSorted: sortObj?.sortColumn === 'parentEventId',
              sortable: true,
              sortDirection: sortObj?.sortColumnDirection.parentEventId,
            })}
          >
            <EventCell type={type} />
          </DataTableColumn>
        ),
        (type === 'Failed' || type === 'Processed') && (
          <DataTableColumn
            key="type"
            label="Order type"
            property="type"
            width="10%"
            {...(type !== 'Processed' && {
              isSorted: sortObj?.sortColumn === 'type',
              sortable: true,
              sortDirection: sortObj?.sortColumnDirection.type,
            })}
          />
        ),
        type === 'Open' && (
          <DataTableColumn
            key="eventStatus"
            label="Status"
            property="eventStatus"
            width="150px"
            isSorted={sortObj?.sortColumn === 'eventStatus'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.eventStatus}
          >
            <OrderRequestStatusCell type={type} />
          </DataTableColumn>
        ),
        (type === 'Failed' || type === 'Processed') && (
          <DataTableColumn
            key="status"
            label="Status"
            property="status"
            width="150px"
            {...(type !== 'Processed' && {
              isSorted: sortObj?.sortColumn === 'status',
              sortable: true,
              sortDirection: sortObj?.sortColumnDirection.status,
            })}
          >
            <OrderRequestStatusCell type={type} />
          </DataTableColumn>
        ),
        type === 'Failed' && (
          <DataTableColumn
            key="failureReason"
            label="Failure Reason"
            property="failureReason"
            isSorted={sortObj?.sortColumn === 'failureReason'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.failureReason}
          />
        ),
        (type === 'Open' || type === 'Processed' || type === 'Failed') && (
          <DataTableColumn
            key="accountName"
            label="Account"
            property="accountNumber"
            width="250px"
            {...(type !== 'Processed' && {
              isSorted: sortObj?.sortColumn === 'accountNumber',
              sortable: true,
              sortDirection: sortObj?.sortColumnDirection.accountName,
            })}
          >
            <AccountCell type={type} />
          </DataTableColumn>
        ),
        type === 'Open' ? (
          <DataTableColumn
            key="salesRep"
            label="Sales Rep"
            property="salesRep"
            isSorted={sortObj?.sortColumn === 'salesRep'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.salesRep}
          />
        ) : (
          type !== 'Favorites' && (
            <DataTableColumn
              key="salesRepName"
              label="Sales Rep"
              property="salesRepName"
              {...(type !== 'Processed' && {
                isSorted: sortObj?.sortColumn === 'salesRepName',
                sortable: true,
                sortDirection: sortObj?.sortColumnDirection.salesRepName,
              })}
            />
          )
        ),
        type === 'Open' ? (
          <DataTableColumn
            key="territoryNumber"
            label="Territory"
            property="territoryNumber"
            isSorted={sortObj?.sortColumn === 'territoryNumber'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.territoryNumber}
          />
        ) : (
          type !== 'Favorites' && (
            <DataTableColumn
              key="caseTerritoryName"
              label="Territory"
              property="caseTerritoryName"
              {...(type !== 'Processed' && {
                isSorted: sortObj?.sortColumn === 'caseTerritoryName',
                sortable: true,
                sortDirection: sortObj?.sortColumnDirection.caseTerritoryName,
              })}
            />
          )
        ),
        (type === 'Open' || type === 'Processed' || type === 'Failed') && (
          <DataTableColumn
            key="3PLDelivery"
            label="3PL Delivery"
            property="3PLDelivery"
            {...(type !== 'Processed' && {
              isSorted: sortObj?.sortColumn === '3PLDelivery',
              sortable: true,
              sortDirection: sortObj?.sortColumnDirection.serviceLevel,
            })}
          >
            <Inventory3PL />
          </DataTableColumn>
        ),
        <DataTableColumn
          key="serviceLevel"
          label="Service Level"
          property="serviceLevel"
          {...(type !== 'Processed' && {
            isSorted: sortObj?.sortColumn === 'serviceLevel',
            sortable: true,
            sortDirection: sortObj?.sortColumnDirection.serviceLevel,
          })}
        />,
        type !== 'Favorites' && (
          <DataTableColumn
            isSorted={sortObj?.sortColumn === 'submittedDate'}
            key="submittedDate"
            label="Submit Date"
            property="submittedDate"
            {...(type !== 'Processed' && {
              sortable: true,
              sortDirection: sortObj?.sortColumnDirection.submittedDate,
            })}
          >
            <SubmitDateCell />
          </DataTableColumn>
        ),
        // type === 'Failed' && (
        //   <DataTableColumn key="action" label="Action" property="action">
        //     <OrderIdCell actionButton type={type} />
        //   </DataTableColumn>
        // ),
        type === 'Favorites' && (
          <DataTableColumn
            isSorted={sortObj?.sortColumn === 'favComments'}
            key="favComments"
            label="Comments"
            property="favComments"
            sortable
            sortDirection={sortObj?.sortColumnDirection.favComments}
          />
        ),
      ]}
    </DataTable>
  );
};

export default OrdersDataTable;
