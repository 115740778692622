/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
/* eslint-disable */
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useQuery } from '@apollo/react-hooks';
import Datepicker from 'react-datepicker';
import { getUserId } from '../../../store/ducks/userId';
import { getUserInfo } from '../../../store/ducks/userInfo';
import SinglePicker from '../SinglePicker';
import RadioButton from '../Radio';
import AutocompleteSingleSelectPicker from '../AutocompleteSingleSelectPicker';
import AutocompleteMultiSelectPicker from '../AutocompleteMultiSelectPicker';
import SwitchToggle from '../Checkbox';
import InputField from '../Input';
import TextArea from '../TextArea';
import TabNavigator from '../TabNavigator';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
// import DatePicker1 from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
// import { GET_WORKFLOW_PERMISSION } from '../../../graphql/workflowPermissions';
import { EVENT_STATUS, PERSONAS_SALES_RESP } from '../../../util/constants';
import './index.scss';
import UploadFile from '../UploadFile';
import { isBefore, format, isEqual, isSameDay } from 'date-fns';

interface Props {
  division: string;
  fieldItem: any;
  key: number;
  setFieldValue: any;
  values: any;
  itemsData: any;
  FormInputTypes: any;
  showAddMenu: boolean;
  handleFormValues: any;
  handleFieldDisable: any;
  handlefailedTabFieldDisable: any;
  fetchItems: any;
  eventStatus: string;
  selectedTab?: any;
  handleCallback?: any;
  handleUploadFiles?: ((value: string, label: string) => any | undefined) | undefined;
  handleRadioButtonChange?: any;
  accountChecked?: any;
  locationChecked?: any;
  handleDeleteAttachments?: ((value: any, label: string) => any | undefined) | undefined;
  uploadedFiles?: any[];
  uploadedShippingLabelFiles?: any[];
  modalHeading?: string;
  eventUsage?: any;
  formType?: string;
  partsListCheck?: any;
}

const getTimezoneName = (formattedDate: any) => {
  const currentDate = new Date(formattedDate);
  const short = currentDate.toLocaleDateString(undefined);
  const full = currentDate.toLocaleDateString(undefined, { timeZoneName: 'long' });
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
  } else {
    return full;
  }
};

const RenderDynamicFields: FC<Props> = ({
  // division,
  fieldItem,
  itemsData,
  FormInputTypes,
  values,
  setFieldValue,
  handleFormValues,
  handleFieldDisable,
  handlefailedTabFieldDisable,
  showAddMenu,
  fetchItems,
  key,
  eventStatus,
  handleCallback,
  handleUploadFiles,
  handleDeleteAttachments,
  handleRadioButtonChange,
  accountChecked,
  selectedTab,
  locationChecked,
  uploadedFiles,
  uploadedShippingLabelFiles,
  modalHeading,
  eventUsage,
  formType,
  partsListCheck,
}) => {
  const {
    fieldLabel,
    fieldType,
    optionsPlaceHolder,
    optionsDynamic,
    required,
    disabled,
    name,
    date,
    time,
    optionsAutoSelected,
    optionsDependentField,
    characterLimit,
    showTooltip = false,
    allowBackDate = false,
    isFailed,
  } = fieldItem;

  const userId = useSelector(getUserId);
  const userInfo = useSelector(getUserInfo);
  const d = new Date();
  const [startDate, setStartDate] = useState<any>(d);
  const [text, setText] = useState<any>('hh:mm');
  useEffect(() => {
    //Setting default for New Trial and Clone Trial form start and end date as intially it will current date to be displayed
    if (
      modalHeading &&
      (modalHeading?.includes('New Trial') ||
        modalHeading.includes('Clone Trial') ||
        modalHeading.includes('New Mock OR') ||
        modalHeading.includes('Clone Mock OR'))
    ) {
      if (fieldLabel.includes('Start Date/Time')) {
        d.setHours(8);
        d.setMinutes(0);
        setText('8:00 am');
      } else if (fieldLabel.includes('End Date/Time')) {
        d.setHours(17);
        d.setMinutes(0);
        setText('5:00 pm');
      }
    }
    setStartDate(d);
  }, [modalHeading]);

  const calculateMinTime = (setDate: any): Date => {
    let isToday = moment(setDate).isSame(moment(), 'day');
    let setEventTime = moment(new Date()).toDate();
    if (isToday) {
      setEventTime = setEventTime;
    } else
      setEventTime = moment()
        .startOf('day')
        .toDate();
    return setEventTime;
  };
  // const { data: enableField } = useQuery(GET_WORKFLOW_PERMISSION, {
  //   fetchPolicy: 'cache-only',
  //   variables: {
  //     division,
  //     state: eventStatus,
  //     action: `enable_${name}`,
  //   },
  // });
  const isEditEvent = eventStatus === EVENT_STATUS.CANCELLED;
  for (const x in values) {
    if (x === 'surgeon') {
      values = { ...values, contact: values[x] };
    }
  }

  // const handleRadioButtonChange = (event: any): void => {
  //   let selectedFilterValues = {};
  //     setAccountChecked(event.target.value);
  //   // if (event.target.value === 'My Accounts') {
  //   //   selectedFilterValues = Object.assign(selectedFilters, {
  //   //     myAccounts: true,
  //   //     tbaAccounts: false,
  //   //   });
  //   // } else if (event.target.value === 'TBA Accounts') {
  //   //   selectedFilterValues = Object.assign(selectedFilters, {
  //   //     myAccounts: false,
  //   //     tbaAccounts: true,
  //   //   });
  //   // }
  //   // setSelectedFilters(selectedFilterValues);
  // };

  switch (fieldType) {
    case FormInputTypes.TEXT:
      return (
        <div
          className="slds-text-body_regular slds-p-bottom_large surgery-field-label"
          key={`${key}`}
        >
          {fieldLabel}
        </div>
      );
    case FormInputTypes.STATIC:
      return (
        <>
          <div className="" key={`${key}`}>
            {fieldLabel}
          </div>
          <div
            className="slds-text-body_regular slds-p-bottom_large surgery-field-label"
            style={{ fontWeight: 'bold' }}
            key={`${key}`}
          >
            {values?.sourceAccount[0]?.label}
          </div>
        </>
      );
    case FormInputTypes.SELECT_SINGLE_DYNAMIC_PICKER: {
      return (
        <SinglePicker
          items={itemsData}
          selectedItem={values[`${name}`]}
          setSelectedItem={(result: any): void => {
            handleFormValues(setFieldValue, name, result, values, optionsDynamic);
          }}
          disabled={handleFieldDisable(optionsDependentField, values) || disabled}
          // label={`${fieldLabel}-1`}
          label={fieldLabel}
          placeholder={optionsPlaceHolder}
          key={`${key || name}`}
          required={required}
          optionsAutoSelected={optionsAutoSelected}
          name={name}
        />
      );
    }
    case FormInputTypes.SELECT_SINGLE_DYNAMIC_AUTO_COMPLETE_PICKER: {
      ((values && values[`${name}`]) || []).forEach((element: any) => {
        element.label = element.label || element.value || element.name;
      });
      let record;
      const splitText = userInfo?.personas?.split(';');
      if ((splitText || [])?.indexOf(PERSONAS_SALES_RESP) >= 0 && name !== 'recievingRep') {
        record = (itemsData || []).filter((item: any) => item.id === userId);
        record.forEach((element: any) => {
          element.label = element.label || element.value || element.name;
        });
      }
      return (
        <AutocompleteSingleSelectPicker
          items={itemsData}
          setSelectedItem={(result: any): void => {
            if (name !== 'salesRep') {
              handleFormValues(setFieldValue, name, result, values, optionsDynamic);
            } else if ((!result.length && values[name] && values[name].length) || result.length) {
              handleFormValues(setFieldValue, name, result, values, optionsDynamic);
              handleCallback && handleCallback(result);
            } else {
              handleFormValues(setFieldValue, name, result, values, optionsDynamic);
            }
          }}
          disabled={
            !!isEditEvent || handleFieldDisable(optionsDependentField, values) || disabled === true || (name!=='serviceLevel'? handlefailedTabFieldDisable(isFailed):'')
          }
          selectedItem={values[`${name}`] || record || []}
          // label={`${fieldLabel}-2`}
          label={fieldLabel}
          placeholder={optionsPlaceHolder}
          withAddMenuButton={showAddMenu}
          addItemLabel="Create New Surgeon Contact" // optional
          key={`${key}`}
          required={required}
          fetchItems={

            (searchText: string): string => {
              return  fetchItems;
            }
            // fetchItems
          }
          filter={false}
          name={name}
          modalHeading={modalHeading}
        />
      );
    }

    case FormInputTypes.FILTER_SINGLE_DYNAMIC_AUTO_COMPLETE_PICKER: {
      ((values && values[`${name}`]) || []).forEach((element: any) => {
        element.label = element.label || element.value || element.name;
      });
      let record;
      const splitText = userInfo?.personas?.split(';');
      if ((splitText || [])?.indexOf(PERSONAS_SALES_RESP) >= 0 && name !== 'recievingRep') {
        record = (itemsData || []).filter((item: any) => item.id === userId);
        record.forEach((element: any) => {
          element.label = element.label || element.value || element.name;
        });
      }
      return (
        <AutocompleteSingleSelectPicker
          items={itemsData}
          setSelectedItem={(result: any): void => {
            if (name !== 'salesRep') {
              handleFormValues(setFieldValue, name, result, values, optionsDynamic);
            } else if ((!result.length && values[name] && values[name].length) || result.length) {
              handleFormValues(setFieldValue, name, result, values, optionsDynamic);
              handleCallback && handleCallback(result);
            } else {
              handleFormValues(setFieldValue, name, result, values, optionsDynamic);
            }
          }}
          disabled={
            handleFieldDisable(optionsDependentField, values) || disabled === true || handlefailedTabFieldDisable(isFailed)
          }
          selectedItem={values[`${name}`] || record || []}
          // label={`${fieldLabel}-2`}
          label={fieldLabel}
          placeholder={optionsPlaceHolder}
          withAddMenuButton={showAddMenu}
          addItemLabel="Create New Surgeon Contact" // optional
          key={`${key}`}
          required={required}
          fetchItems={fetchItems}
          filter={false}
          name={name}
          modalHeading={modalHeading}
        />
      );
    }
    case FormInputTypes.SELECT_MULTI_DYNAMIC_AUTO_COMPLETE_PICKER: {
      return (
        <AutocompleteMultiSelectPicker
          items={itemsData}
          setSelectedItems={(result: any): void => {
            handleFormValues(setFieldValue, name, result, values, optionsDynamic);
          }}
          disabled={
            handleFieldDisable(optionsDependentField, values) || disabled === true || handlefailedTabFieldDisable(isFailed)
          }
          selectedItems={values[`${name}`] || []}
          // label={`${fieldLabel}-4`}
          label={fieldLabel}
          placeholder={optionsPlaceHolder}
          required={required}
          withAddMenuButton={false}
          key={`${key}`}
          filterPicker
        />
      );
    }
    case FormInputTypes.SELECT_MULTI_DYNAMIC_PICKER: {
      ((values && values[`${name}`]) || []).forEach((element: any) => {
        element.label = element.value || element.name || element.label;
      });
      return (
        <AutocompleteMultiSelectPicker
          items={itemsData}
          setSelectedItems={(result: any): void => {
            handleFormValues(setFieldValue, name, result, values, optionsDynamic);
          }}
          disabled={
            handleFieldDisable(optionsDependentField, values) || disabled === true || handlefailedTabFieldDisable(isFailed)
          }
          selectedItems={values[`${name}`] || []}
          // label={`${fieldLabel}-5`}
          label={fieldLabel}
          placeholder={optionsPlaceHolder}
          required={required}
          withAddMenuButton={false}
          key={`${key}`}
        />
      );
    }
    case FormInputTypes.TOGGLE_SWITCH: {
      return (
        <SwitchToggle
          // label={`${fieldLabel}-6`}
          label={fieldLabel}
          setIsChecked={(result: any): void => {
            handleFormValues(setFieldValue, name, result, values, optionsDynamic);
          }}
          isChecked={values[`${name}`]}
          key={`${key}`}
          disabled={disabled === true}
          />
      );
    }
    case FormInputTypes.RADIO: {
      return (
        <RadioButton
          label={fieldLabel}
          items={itemsData}
          accountChecked={fieldLabel === 'Event Location' ? locationChecked : accountChecked}
          handleRadioButtonChange={(result: any): void => {
            handleFormValues(setFieldValue, name, result, values, optionsDynamic);
          }}
          disabled = {handlefailedTabFieldDisable(isFailed)}
        />
      );
    }
    case FormInputTypes.ATTACHMENTS: {
      return (
        <UploadFile
          label={fieldLabel}
          buttonText="Attachments"
          handleUploadFiles={handleUploadFiles}
          handleDeleteAttachments={handleDeleteAttachments}
          uploadedFiles={uploadedFiles}
          handleOnChange={(result: any): void => {
            handleFormValues(setFieldValue, name, result, values, optionsDynamic);
          }}
          required={required}
          uploadedShippingLabelFiles={uploadedShippingLabelFiles}
        />
      );
    }
    case FormInputTypes.DATE_TIME_PICKER: {
      const timeZoneName = getTimezoneName(new Date(values[`${name}`]))
        .match(/\b([A-Z])/g)
        ?.join('');

      return (
        <div>
          {date && time ? (
            <div className="date-picker">
              <span style={{ margin: '0 0.125rem', color: '#c23934', cursor: 'help' }}>*</span>
              {fieldLabel}
              <div className="input-container">
                <Datepicker
                  value={
                    values[`${name}`]
                      ? `${new Date(values[`${name}`]).toLocaleString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: '2-digit',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true,
                        })} ${timeZoneName}`
                      : ''
                  }
                  selected={values[`${name}`] ? new Date(values[`${name}`]) : startDate}
                  disabled={disabled === true}
                  onChange={(result: any): void => {
                    const newValues = {
                      ...values,
                      [name]: result,
                    };
                    setStartDate(result);
                    handleFormValues(setFieldValue, name, result, newValues, optionsDynamic);
                  }}
                  showTimeSelect
                  // timeFormat="hh:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  timeFormat="p"
                  placeholderText={'MM/DD/YYYY, ' + text}
                  dateFormat="MM/dd/yyyy, hh:mm a"
                  minDate={new Date()}
                  minTime={
                    values[`${name}`]
                      ? calculateMinTime(new Date(values[`${name}`]))
                      : calculateMinTime(new Date(startDate))
                  }
                  maxTime={moment()
                    .endOf('day')
                    .toDate()}
                  // maxDate={addMonths(new Date(), 5)}
                  showDisabledMonthNavigation
                />
              </div>
            </div>
          ) : (
            <DatePicker
              label={date ? fieldLabel : ''}
              placeholder="Pick a Date"
              key={`${key}`}
              value={values[`${name}`]}
              handleChangeDate={(result: any): void => {
                handleFormValues(setFieldValue, name, result, values, optionsDynamic);
              }}
              disabled={disabled === true}
              required={required}
              userData={userInfo}
              modalHeading={modalHeading}
            />
          )}
          {/* {date ? <DatePicker
            label={date ? fieldLabel : ""}
            placeholder="Pick a Date"
            key={`${key}`}
            value={values[`${name}`]}
            handleChangeDate={(result: any): void => {
              handleFormValues(setFieldValue, name, result, values, optionsDynamic);
            }}
            disabled={isDisable}
            required={required}
          /> : null}
          {time ? <TimePicker
            label={time ? null : fieldLabel}
            showByDefault={isEditEvent}
            placeholder="Pick a Time"
            key={`${key}`}
            value={values[`${name}`]}
            handleChangeTime={(result: any): void => {
              handleFormValues(setFieldValue, name, result, values, optionsDynamic);
            }}
            disabled={isDisable}
            required={required}
          /> : null } */}
        </div>
      );
    }
    case FormInputTypes.DATE_PICKER: {
      return (
        <DatePicker
          label={fieldLabel}
          placeholder="Pick a Date"
          key={`${key}`}
          value={values[`${name}`]}
          handleChangeDate={(result: any): void => {
            handleFormValues(setFieldValue, name, result, values, optionsDynamic);
          }}
          disabled={disabled === true}
          required={required}
          isFilterDate={allowBackDate}
        />
      );
    }
    case FormInputTypes.TIME_PICKER: {
      return (
        <TimePicker
          label={fieldLabel}
          placeholder="Pick a Time"
          key={`${key}`}
          value={values[`${name}`]}
          handleChangeTime={(result: any): void => {
            handleFormValues(setFieldValue, name, result, values, optionsDynamic);
          }}
          showByDefault={isEditEvent}
          disabled={disabled === true}
        />
      );
    }

    case FormInputTypes.TEXT_BOX: {
      if (
        (values?.eventDuration === undefined || values?.eventDuration === null) &&
        fieldLabel === 'Duration (hours)'
      ) {
        values.eventDuration = '';
      }

      if (fieldLabel === 'Onsite Contact Name') {
        if (values.salesRep && values.salesRep.length > 0) {
          if (values?.onsiteContractName === undefined || values?.onsiteContractName === null) {
            values.onsiteContractName = values.salesRep[0].label;
          }
        } else {
          values.onsiteContractName = null;
        }
      }

      if (fieldLabel === 'Onsite Contact Phone') {
        if (values.salesRep && values.salesRep.length > 0) {
          if (values?.onsiteContractPhone === undefined || values?.onsiteContractPhone === null) {
            values.onsiteContractPhone = values.salesRep[0].mobilephone;
          }
        } else {
          values.onsiteContractPhone = null;
        }
      }

      if (
        fieldLabel === 'Delivery Contact Name' &&
        (values?.isShippingDetails === undefined || values?.isShippingDetails === null)
      ) {
        if (values.salesRep && values.salesRep.length > 0) {
          if (values?.deliveryContractName === undefined || values?.deliveryContractName === null) {
            values.deliveryContractName = values.salesRep[0].label;
          }
        } else {
          values.deliveryContractName = null;
        }
      }
      if (
        fieldLabel === 'Delivery Contact Phone' &&
        (values?.isShippingDetails === undefined || values?.isShippingDetails === null)
      ) {
        if (values.salesRep && values.salesRep.length > 0) {
          if (
            values?.deliveryContractPhone === undefined ||
            values?.deliveryContractPhone === null
          ) {
            values.deliveryContractPhone = values.salesRep[0].mobilephone;
          }
        } else {
          values.deliveryContractPhone = null;
        }
      }
      if (fieldLabel === 'Location Description' && optionsDynamic) {
        values[`${name}`] = optionsDynamic;
      }

      return (
        <InputField
          // label={`${fieldLabel}-6`}
          label={fieldLabel}
          placeholder={
            fieldLabel === 'Delivery Contact Phone' || fieldLabel === 'Onsite Contact Phone'
              ? '1-XXX-XXX-XXXX'
              : `Enter ${fieldLabel}`
          }
          type="text"
          key={`${key}`}
          required={required}
          value={values[`${name}`]}
          maxLength={(characterLimit && characterLimit.toString()) || '255'}
          handleChangeText={(result: any, inputType: any): void => {
            if (name === 'eventDuration') {
              const inputDuration = result.replace(/\D/g, '');
              handleFormValues(setFieldValue, name, inputDuration, values, optionsDynamic);
            } else {
              handleFormValues(setFieldValue, name, result, values, optionsDynamic, inputType);
            }
          }}
          disabled={disabled === true}
          name={name}
        />
      );
    }
    case FormInputTypes.TEXT_AREA: {
      return (
        <TextArea
          label={fieldLabel}
          maxLength={(characterLimit && characterLimit.toString()) || '255'}
          showTooltip={showTooltip}
          tooltipText={optionsPlaceHolder || ''}
          key={`${key}`}
          value={values[`${name}`] || ''}
          required={required}
          handleChangeText={(result: any): void => {
            handleFormValues(setFieldValue, name, result, values, optionsDynamic);
          }}
          disabled={disabled === true}
        />
      );
    }
    case FormInputTypes.TAB_NAVIGATOR: {
      if (eventUsage === true) {
        return (
          <TabNavigator
            key={`${key}`}
            items={itemsData}
            selectedTab={selectedTab}
            handleTabChange={(result: any): void => {
              handleFormValues(setFieldValue, name, result, values, optionsDynamic);
            }}
            formType={formType}
            partsListCheck={partsListCheck}
          />
        );
      } else return null;
    }
    case FormInputTypes.INPUT: {
      if (
        (values?.eventDuration === undefined || values?.eventDuration === null) &&
        fieldLabel === 'Duration (hours)'
      ) {
        values.eventDuration = '';
      }

      if (
        fieldLabel === 'Delivery Contact Name' &&
        (values?.isShippingDetails === undefined || values?.isShippingDetails === null)
      ) {
        if (values.salesRep && values.salesRep.length > 0) {
          if (values?.deliveryContractName === undefined || values?.deliveryContractName === null) {
            values.deliveryContractName = values.salesRep[0].label;
          }
        } else {
          values.deliveryContractName = null;
        }
      }

      if (fieldLabel === 'Onsite Contact Name') {
        if (values.salesRep && values.salesRep.length > 0) {
          if (values?.onsiteContractName === undefined || values?.onsiteContractName === null) {
            values.onsiteContractName = values.salesRep[0].label;
          }
        } else {
          values.onsiteContractName = null;
        }
      }

      if (fieldLabel === 'Onsite Contact Phone') {
        if (values.salesRep && values.salesRep.length > 0) {
          if (values?.onsiteContractPhone === undefined || values?.onsiteContractPhone === null) {
            values.onsiteContractPhone = values.salesRep[0].mobilephone;
          }
        } else {
          values.onsiteContractPhone = null;
        }
      }

      if (
        fieldLabel === 'Delivery Contact Phone' &&
        (values?.isShippingDetails === undefined || values?.isShippingDetails === null)
      ) {
        if (values.salesRep && values.salesRep.length > 0) {
          if (
            values?.deliveryContractPhone === undefined ||
            values?.deliveryContractPhone === null
          ) {
            values.deliveryContractPhone = values.salesRep[0].mobilephone;
          }
        } else {
          values.deliveryContractPhone = null;
        }
      }
      if (fieldLabel === 'Location Description' && optionsDynamic) {
        values[`${name}`] = optionsDynamic;
      }
      if (fieldLabel === 'Country') {
        values[`${name}`] = 'US';
      }
      return (
        <InputField
          // label={`${fieldLabel}-6`}
          label={fieldLabel}
          placeholder={`Enter ${fieldLabel}`}
          type="text"
          key={`${key}`}
          required={required}
          value={values[`${name}`]}
          maxLength={(characterLimit && characterLimit.toString()) || '255'}
          handleChangeText={(result: any): void => {
            if (name === 'eventDuration') {
              const inputDuration = result.replace(/\D/g, '');
              handleFormValues(setFieldValue, name, inputDuration, values, optionsDynamic);
            } else {
              handleFormValues(setFieldValue, name, result, values, optionsDynamic);
            }
          }}
          disabled={fieldLabel === 'Country' || disabled === true}
          name={name}
        />
      );
    }
    case FormInputTypes.COMMENTS_AREA: {
      // if (eventStatus !== '' && name === 'comments') {
      //   return null;
      // }
      let display = '';
      if (values && values[`${name}`]) {
        if (typeof values[`${name}`] === 'string') {
          display = values[`${name}`];
        } else {
          (values[`${name}`] || []).forEach((record: any, index: number) => {
            if (record.value) {
              display = index ? `${display}\n${record.value}` : record.value;
            }
          });
        }
      }
      return (
        <TextArea
          label={fieldLabel}
          maxLength={(characterLimit && characterLimit.toString()) || '255'}
          showTooltip={showTooltip}
          tooltipText={optionsPlaceHolder || ''}
          key={`${key}`}
          value={display || ''}
          required={required}
          handleChangeText={(result: any): void => {
            handleFormValues(setFieldValue, name, result, values, optionsDynamic);
          }}
          disabled={disabled === true}
        />
      );
    }
    default: {
      return <div key={`${key}`} />;
    }
  }
};

export default RenderDynamicFields;
