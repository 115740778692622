/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-concat */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { format, isValid } from 'date-fns';
import moment from 'moment';
import * as XLSX from 'xlsx';

import {
  DATE_VIEW_FORMATTER,
  TRIAL_EVENT_TYPE,
  DEMO_EVENT_TYPE,
  SURGERY_USAGE_EVENT_TYPE,
  SURGERY_EVENT_TYPE,
  WORKSHOP_EVENT_TYPE,
  INVENTORY_REQUEST,
  ORDERS,
  INVENTORY_RETURN,
  EVENT_TYPE,
  CYCLE_COUNT,
  CYCLECOUNT_EVENT_TYPE,
  EXCLAMATION,
  EMPTY_COMMENTS,
  COMMENTS,
  USAGE_TYPE,
} from './constants';

export const getUTCDate = (date: string): string => {
  const utcTimeString = new Date(date.replace(' ', 'T'));

  const utcDate = new Date(
    utcTimeString.getFullYear(),
    utcTimeString.getMonth(),
    utcTimeString.getDate(),
    utcTimeString.getHours(),
    utcTimeString.getMinutes(),
    utcTimeString.getSeconds()
  ).toISOString();
  return `${utcDate.substr(0, 10)} ${utcDate.substr(11, 8)}`;
};

export const getApiDateTime = (eventDate: any): string => {
  const formattedSelectedDate = new Date(eventDate);
  let updatedDate = new Date(formattedSelectedDate).setHours(0);
  updatedDate = new Date(updatedDate).setMinutes(0);
  updatedDate = new Date(updatedDate).setSeconds(0);
  const finalDate = format(new Date(updatedDate), DATE_VIEW_FORMATTER);
  const utcDate = getUTCDate(finalDate);
  return utcDate;
};

export const getCurrentApiDateTime = (eventDate: any): string => {
  const formattedSelectedDate = new Date(eventDate);
  const hour = formattedSelectedDate.getHours();
  const minutes = formattedSelectedDate.getMinutes();
  let date = new Date(formattedSelectedDate).setHours(hour);
  date = new Date(date).setMinutes(minutes);
  const finalDate = format(new Date(date), DATE_VIEW_FORMATTER);
  const utcDate = getUTCDate(finalDate);

  return utcDate;
};

export const getEventIcon = (eventTypeName: string): string => {
  switch (eventTypeName) {
    case SURGERY_EVENT_TYPE:
    case SURGERY_USAGE_EVENT_TYPE:
      return '/icons/surgery.svg';
    case WORKSHOP_EVENT_TYPE:
      return '/icons/workshop.svg';
    case TRIAL_EVENT_TYPE:
      return '/icons/trial.svg';
    case INVENTORY_REQUEST:
      return '/icons/request_inventory.svg';
    case ORDERS:
      return '/icons/orders.svg';
    case INVENTORY_RETURN:
      return 'icons/return_inventory.svg';
    case CYCLE_COUNT:
      return '/icons/cycle_count.svg';
    case EXCLAMATION:
      return 'icons/exclamation.svg';
    case EMPTY_COMMENTS:
      return 'icons/no-comments-icon.svg';
    case COMMENTS:
      return 'icons/comments-icon.svg';
    case 'Pickup Return':
      return 'icons/return_inventory.svg';
    case 'Inventory Return':
      return 'icons/return_inventory.svg';
    default:
      return '/icons/surgery.svg';
  }
};

export const getHoldIcon = (): string => {
  return '/icons/VectorHold.svg';
};
export const getWhiteBGIcon = (): string => {
  return '/icons/WhiteBG.svg';
};

export const getEventIconInner = (eventTypeName: string): string => {
  switch (eventTypeName) {
    case SURGERY_EVENT_TYPE:
    case SURGERY_USAGE_EVENT_TYPE:
      return '/icons/surgery_inner.svg';
    case WORKSHOP_EVENT_TYPE:
      return '/icons/workshop_inner.svg';
    case TRIAL_EVENT_TYPE:
      return '/icons/trial_inner.svg';
    case DEMO_EVENT_TYPE:
      return '/icons/demo_inner.svg';
    case CYCLECOUNT_EVENT_TYPE:
      return '/icons/clipboard-icon.svg';
    default:
      return '/icons/surgery_inner.svg';
  }
};

export const getSvgIcon = (eventTypeName: string): string => {
  switch (eventTypeName) {
    case 'dollar':
      return '/icons/dollar-icons.svg';
    case 'percentage':
      return '/icons/percentage-icons.svg';
    case 'rightArrow':
      return '/icons/icon-right.svg';
    case 'downArrow':
      return '/icons/icon-down.svg';
    case 'bellIcon':
      return '/icons/icon-bell.svg';
    case 'notificationIcon':
      return '/icons/notification-bell-icon.png';
    case 'starMarked':
      return '/icons/gold-Flag.svg';
    case 'starNotMarked':
      return '/icons/starred-icon.svg';
    case 'upArrow':
      return '/icons/up-arrow.svg';
    case 'downPointedArrow':
      return '/icons/down-arrow.svg';
    case 'inventoryView':
      return '/icons/InventoryView.svg';
    case 'backorder':
      return '/icons/utility/backorder.png';
    case 'reset':
      return '/icons/reset.svg';
    case 'resetDisabled':
      return '/icons/resetDisabled.svg';
    case 'threshold':
      return '/icons/threshold.svg';
    case 'inprogress':
      return '/icons/inprogress-icon.svg';
    case 'submit':
      return '/icons/submitted-icon.svg';
    case 'catalog':
      return '/icons/catalog.svg';
    case 'trialPart':
      return '/icons/Trial-hold.svg';
    case 'globalSearch':
      return '/icons/global-search-icon.svg';
    default:
      return '';
  }
};

export const isEmpty = (obj: any): boolean => {
  if (obj) {
    return Object.keys(obj).length > 0;
  }
  return false;
};

export const getUTCDateTime = (date: any): string => {
  const utcDateTime = moment.utc(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
  return utcDateTime;
};

export const getLocalDateTime = (date: any): string => {
  const localDateTime = moment
    .utc(date)
    .local()
    .format('YYYY-MM-DD HH:mm:ss');
  return localDateTime;
};

export const isValidEmail = (email: string): RegExpMatchArray | null => {
  const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email.match(validRegex);
};

export const getDateDifferenceInDays = (fromDate: string, toDate: string): number => {
  const a = moment(fromDate);
  const b = moment(toDate);
  return a.diff(b, 'days'); // 1
};

export const getUSDString = (value: string): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(Number(value));
};

export const priceValidationIndicatorClass = (
  eventType = '',
  lineItem: any,
  displayOrderType?: any,
  enabledModalFields?: any
): string => {
  let priceTextClass = 'price-txt';
  let freightValidation = lineItem.isFrieghtMisc;
  if (lineItem.isFrieghtMisc) {
    if (displayOrderType === USAGE_TYPE.SHIP_AND_BILL) {
      freightValidation = false;
    }
    /*  else if (displayOrderType === USAGE_TYPE.BILL_ONLY) {
      freightValidation = false;
    } */
  }

  if (eventType !== EVENT_TYPE.DEMO && eventType !== EVENT_TYPE.TRIAL) {
    if (!freightValidation) {
      if (lineItem.contractPrice && lineItem.floorPrice) {
        // has both price
        // if (Number(lineItem.contractPrice) > Number(lineItem.floorPrice)) {
        if (Number(lineItem.contractPrice) !== Number(lineItem.usgPrice)) {
          if (Number(lineItem.usgPrice) > Number(lineItem.contractPrice)) {
            priceTextClass = 'price-txt-warning';
          } else if (
            Number(lineItem.contractPrice) >= Number(lineItem.floorPrice) &&
            Number(lineItem.usgPrice) < Number(lineItem.floorPrice) &&
            enabledModalFields?.includes('floorPrice') &&
            !lineItem.wasted
          ) {
            priceTextClass = 'price-txt-warning';
          }
        }
      } else if (lineItem.contractPrice) {
        // Only Contract Price
        if (Number(lineItem.usgPrice) > Number(lineItem.contractPrice)) {
          priceTextClass = 'price-txt-warning';
        }
      } else if (
        Number(lineItem.contractPrice) >= Number(lineItem.floorPrice) &&
        Number(lineItem.usgPrice) < Number(lineItem.floorPrice) &&
        enabledModalFields?.includes('floorPrice') &&
        !lineItem.wasted
      ) {
        // Only Floor Price
        priceTextClass = 'price-txt-warning';
      }
    }
  }
  return priceTextClass;
};

export const excelExport = (sheetsData: any, FileName: any): any => {
  // export json to Worksheet of Excel
  // A workbook is the name given to an Excel file
  const wb = XLSX.utils.book_new(); // make Workbook of Excel

  // add Worksheet to Workbook
  // Workbook contains one or more worksheets

  sheetsData.forEach((sheet: any) => {
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(sheet?.data), sheet?.title);
  });

  // export Excel file
  XLSX.writeFile(wb, FileName);
};

export const warningValidation = (label: string, date: any, userData: any): string => {
  let warningText = '';
  if (label === 'Requested Delivery Date' && isValid(new Date(date))) {
    userData?.businessRules?.forEach((br: any) => {
      if (br?.rules === 'Lead Time Warning' && br?.isRequired === true && br?.values > 0) {
        const diffDays = getDateDifferenceInDays(new Date(date).toString(), new Date().toString());
        if (diffDays < br?.values) {
          warningText =
            'The Requested Delivery Date selected is less then the minimum shipping lead time. Your request might not be fulfilled by this date.';
        } else {
          warningText = '';
        }
      }
    });
  }
  return warningText;
};

export const calculateActualQuantitity = (item: any, onHandQuantity: any): number => {
  const isPackContent = item?.salesUnitOfMeasure === 'PK' || item?.salesUnitOfMeasure === 'BX';
  const packOfContentCount = item?.packContent > 0 ? item?.packContent : 1;
  return isPackContent ? onHandQuantity * packOfContentCount : onHandQuantity;
};

export const GetActualQty = (
  orderType: string,
  sellingUOM: string,
  actualUOM: string,
  packQty: number,
  orderedQty: number
): number => {
  let actualQty = 0;
  if (sellingUOM && sellingUOM.length <= 0) throw new Error('Selling UOM cant be empty');

  const packOfContentCount = packQty > 0 ? packQty : 1;

  const calculatedActualUOM = actualUOM === null ? 'PCE' : actualUOM;

  switch (orderType) {
    case USAGE_TYPE.SHIP_AND_BILL:
    case USAGE_TYPE.INVENTORY_REQUEST:
      actualQty = sellingUOM === calculatedActualUOM ? orderedQty : orderedQty * packOfContentCount;
      break;
    case USAGE_TYPE.BILL_ONLY:
      // actualQty = sellingUOM === calculatedActualUOM ? orderedQty : orderedQty / packOfContentCount;
      actualQty = orderedQty;
      break;
    default:
      actualQty = 0; // This is only for default case where we do not pass unwanted Order Type
      break;
  }
  return actualQty;
};

export const formatShipMethodsLabel = (shipMethods: any): [] => {
  if (shipMethods?.getShipMethods && shipMethods?.getShipMethods?.length > 0) {
    return shipMethods?.getShipMethods?.map((item: any) => {
      return {
        ...item,
        value: item.label,
        label: item.label,
      };
    });
  }

  return [];
};
