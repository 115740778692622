/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useState, useCallback } from 'react';
// import { useSelector } from 'react-redux';
// import Icon from '@salesforce/design-system-react/components/icon';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Input from '@salesforce/design-system-react/components/input';
import Button from '@salesforce/design-system-react/components/button';
import Modal from '@salesforce/design-system-react/components/modal';
// import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
// import InputField from '../../../../Shared/Input';
import debounced from '../../../../../util/debounced';
import './index.scss';
import PricingModal from './PricingModal';
import {
  getUSDString,
  priceValidationIndicatorClass,
  getHoldIcon,
  GetActualQty,
} from '../../../../../util/utilityFunctions';
// import { getUserInfo } from '../../../../../store/ducks/userInfo';
import { EVENT_TYPE, USAGE_TYPE, NOTIFY_TYPE, MAX_QUANTITY } from '../../../../../util/constants';
import SnackBar from '../../../../Shared/SnackBar';
import useSnackBar from '../../../../../util/customHooks/useSnackBar';

interface Props {
  usageDetails?: any;
  viewOnly: boolean;
  isAssignUser: boolean;
  eventType?: string;
  refetch: () => void;
  items: any[];
  setItems: (items: any[]) => void;
  isERPOrder: boolean;
  pricingListConfig?: any;
  handleSave?: any;
  configs?: any;
  type?: string;
  updatedPrice?: any;
}

const PricingTabularDetails: FC<Props> = ({
  usageDetails,
  refetch,
  items,
  setItems,
  viewOnly,
  isAssignUser,
  eventType,
  isERPOrder,
  pricingListConfig,
  handleSave,
  configs,
  type,
  updatedPrice,
}) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const { open, notification, openSnackBar } = useSnackBar();
  const [modalUniqueId, setModalUniqueId] = useState('');
  const [openPricingModal, setOpenPricingModal] = useState(false);
  const [inputValue, setInputValue] = useState<{ [uniqueId: string]: string }>({});
  const [selectedItem, setSelectedItem] = useState();
  const [openSaveConfirmModal, setOpenSaveConfirmModal] = useState(false);
  // const userInfo = useSelector(getUserInfo);
  let enabledModalFields: any[] = [];
  if (configs?.priceDetail_tab) {
    configs?.priceDetail_tab.forEach((item: any) => {
      if (item.isShow === true) {
        enabledModalFields = [...enabledModalFields, item.mappingKey];
      }
    });
  }
  const calcDiscount = (unit: string, list: string): string => {
    let discount = '';
    if (list && Number(list) > 0) {
      const discOffList = (1 - Number(unit) / Number(list)) * 100;
      discount = parseFloat(discOffList.toString()).toFixed(2);
    }
    return discount;
  };
  useEffect(() => {
    if (items.length > 0) {
      const total = items.reduce((acc: any, curr: any) => {
        return acc + Number(curr.usgTotalAmount);
      }, 0);
      setTotalPrice(total);
    }
  }, [items]);

  const handleCount = (
    event: any,
    data: { number: number },
    id: string,
    content: number,
    name: string
  ): void => {
    const duplicateParts = [];
    const listItems = items.map((i: any) => {
      if (i.uniqueId === id) {
        let qty = data.number > i.maxQuantity ? i.maxQuantity : data.number;
        const product: any = items?.find(
          (item: any) =>
            item.lotNumber === i.lotNumber &&
            item.productNumber === i.productNumber &&
            item.externalId !== i.externalId &&
            item.sourceLocation === i.sourceLocation
        );

        if (
          !i.isValidLotSerialCombination &&
          i.iscatalog &&
          usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY
        ) {
          qty = data.number;
          // actualqty = data.number;
        }

        const actualqty = GetActualQty(
          usageDetails.diplayOrderType,
          name,
          i.salesUnitOfMeasure,
          i.packContent,
          data.number
        );

        if (
          product &&
          duplicateParts.length === 0 &&
          i.isValidLotSerialCombination &&
          !product.isValidLotSerialCombination
        ) {
          const itemToUpdate = product.iscatalog ? product : i;
          itemToUpdate.caseUsageSubInventoryCode = i.caseUsageSubInventoryCode;
          itemToUpdate.isLotValueUpdated = true;
          duplicateParts.push(product);
        }
        return {
          ...i,
          quantity: qty,
          usgTotalAmount: i.usgPrice * actualqty,
          actualQuantity: actualqty,
        };
      }
      return i;
    });
    setItems(listItems || []);
    if (data.number !== 0) {
      handleSave(true);
    } else {
      setOpenSaveConfirmModal(true);
      setModalUniqueId(id);
    }
  };
  const holdIconPath = getHoldIcon();
  const handleCountalert = (
    event: any,
    data: { number: number },
    id: string,
    content: number,
    name: string,
    onHold: string
  ): void => {
    if (onHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCount(event, data, id, content, name);
  };

  const togglePriceModal = (): void => {
    setOpenPricingModal(!openPricingModal);
  };
  const goToPriceDetails = (item: any): void => {
    setSelectedItem(item);
    togglePriceModal();
  };

  const getUoM = (name: string, packContent: string): string => {
    let actualUOM = name;

    if (packContent && Number(packContent) > 1) {
      actualUOM += `/ ${packContent}`;
    }

    return actualUOM;
  };

  const getSellingUoM = (lineItem: any): string => {
    const sellingUOMDetails = updatedPrice?.filter(
      (item: any) => item.productNumber === lineItem.productNumber
    );

    let sellingUOM = 'PCE';
    if (sellingUOMDetails?.length > 0) {
      sellingUOM = sellingUOMDetails[0]?.sellingUOM;
    }

    return sellingUOM || 'PCE';
  };
  const setOvverrideType = (type: string, id: string): void => {
    const listItems = items.map((i: any) => {
      if (i.uniqueId === id) {
        const unitPrice =
          type !== i.priceOverrideType ? i.contractPrice || i.originalUsgPrice : i.usgPrice;
        const overrideVal = type !== i.priceOverrideType ? '' : i.priceOverride;
        return {
          ...i,
          priceOverrideType: type,
          priceOverride: overrideVal,
          priceType: overrideVal ? 'User Override' : i.originalPriceType,
          usgPrice: unitPrice,
          discountPercentage: calcDiscount(unitPrice, i.listPrice),
        };
      }
      return i;
    });
    setItems(listItems || []);
    // handleSave(true);
  };

  const savePartsOnPricing = (): void => {
    setOpenSaveConfirmModal(false);
    const listItems = items.map((i: any) => {
      if (Number(i.quantity) === 0 && i.uniqueId === modalUniqueId) {
        const product: any = items?.find(
          (item: any) =>
            item.lotNumber === i.lotNumber &&
            item.productNumber === i.productNumber &&
            item.externalId !== i.externalId &&
            item.sourceLocation === i.sourceLocation
        );

        if (product && i.isValidLotSerialCombination && !product.isValidLotSerialCombination) {
          const itemToUpdate = product.iscatalog ? product : i;
          itemToUpdate.caseUsageSubInventoryCode = i.caseUsageSubInventoryCode;
          itemToUpdate.isLotValueUpdated = true;
        }

        return {
          ...i,
          quantity: '',
          deleted: true,
        };
      }
      return i;
    });
    setItems(listItems || []);
    handleSave(true);

    setTimeout(() => {
      refetch();
    }, 4000);
  };

  const handleAutoSave = (): void => {
    handleSave(true);
  };

  const debouncedFetchRequestedItems = useCallback(debounced(800, handleAutoSave), [
    debounced,
    handleAutoSave,
  ]);

  const handleWasted = (event: any, id: string): void => {
    const listItems = items.map((i: any) => {
      // if (items.length === 1 && Number(i.quantity) === 0) {
      //   saveParts(false);
      // } else {
      //   saveParts(true);
      // }
      if (i.uniqueId === id) {
        return {
          ...i,
          wasted: event.target.checked,
          overridePrice: i.overridePrice,
          priceOverride: i.priceOverride,
          priceOverrideType: i.priceOverrideType,
          usgPrice: i.contractPrice ? i?.contractPrice : i?.listPrice,
        };
      }
      return i;
    });
    setItems(listItems || []);
    debouncedFetchRequestedItems();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, item: any): void => {
    // Prevent the minus sign from being entered
    if (
      event.key === '-' ||
      (event.key === '0' &&
        (Number(inputValue[item?.uniqueId]) === 0 || inputValue[item?.uniqueId] === undefined) &&
        !item?.wasted)
    ) {
      event.preventDefault();
    }
  };

  const handleUserInputChnage = (inputValue: string, id: string): void => {
    const sanitizedValue = inputValue.replace(/^0+(?=\d)/, '');
    setInputValue(prevValues => ({
      ...prevValues,
      [id]: sanitizedValue,
    }));
  };

  const handleUserInput = (inputValue: string, id: string): void => {
    const listItems = items.map((i: any) => {
      if (i.uniqueId === id) {
        // eslint-disable-next-line no-nested-ternary
        let unitPrice = inputValue !== '' ? inputValue : i.contractPrice ? i.contractPrice : '0';
        let discount = '';
        if (i.priceOverrideType === 'PERCENTAGE') {
          if (inputValue) {
            const discOffList = Number(i.listPrice) * (Number(inputValue) / 100);
            const newUnitPrice = Number(i.listPrice) - Number(discOffList);
            unitPrice = newUnitPrice.toString();
            discount = calcDiscount(unitPrice, i.listPrice);
          }
        } else if (i.listPrice && Number(i.listPrice) > 0) {
          const discOffList = (1 - Number(unitPrice) / Number(i.listPrice)) * 100;
          discount = parseFloat(discOffList.toString()).toFixed(2);
        }
        return {
          ...i,
          priceType: inputValue ? 'User Override' : i.originalPriceType,
          usgPrice: unitPrice,
          usgTotalAmount: (Number(unitPrice) * Number(i.actualQuantity)).toString(),
          priceOverride: inputValue,
          discountPercentage: discount || '',
          overridePrice: !!inputValue,
        };
      }
      return i;
    });
    setItems(listItems);
    setInputValue({});
    debouncedFetchRequestedItems();
  };

  return (
    <>
      <SnackBar open={open} notification={notification} />
      {openSaveConfirmModal && (
        <Modal
          className="delete-preset-modal default-modal_header default-modal_footer"
          isOpen={openSaveConfirmModal}
          onRequestClose={(): void => setOpenSaveConfirmModal(false)}
          ariaHideApp={false}
          footer={[
            <Button label="Yes" variant="brand" onClick={savePartsOnPricing} key="save_yes" />,
            <Button
              label="No"
              onClick={(): void => setOpenSaveConfirmModal(false)}
              key="save_no"
            />,
          ]}
          heading="Confirmation"
        >
          <div className="slds-text-align--center">
            <div>
              <h1 className="slds-m-around--large ">
                {'Part(s) will be removed as the quantity is 0. Are you sure you want to proceed?'}
              </h1>
            </div>
          </div>
        </Modal>
      )}
      {pricingListConfig.includes('total_price') && (
        <div
          style={{
            fontSize: '14px',
            textAlign: 'end',
            fontWeight: 'bold',
            padding: '2px 30px',
          }}
        >
          <span>Total Price: </span>
          <span>{getUSDString(totalPrice.toString()) || '$0.00'}</span>
        </div>
      )}
      <div className="pricing-table-container">
        <div
          className={`pricing-table-header ${
            usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY
              ? 'bill-only-grid-cols'
              : 'ship-bill-grid-cols'
          }`}
        >
          <p>Part</p>
          {/* <p>Part Description</p> */}
          <p className="pad-left-60">Qty</p>
          <p>{pricingListConfig.includes('actual_qty') ? `Actual Qty` : ` `}</p>
          <p>{pricingListConfig.includes('seiiing_uom') ? `Selling UoM` : ` `}</p>
          <p>{pricingListConfig.includes('actual_uom') ? `Actual UoM` : ` `}</p>
          {usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY && (
            <p>{pricingListConfig.includes('wasted') ? `Wasted` : ` `}</p>
          )}
          <p>{pricingListConfig.includes('contract_price') ? `Contract Price` : ` `}</p>
          <p className="pad-left-30">
            {pricingListConfig.includes('override_price') ? `Override Price` : ` `}
          </p>
          <p>{pricingListConfig.includes('unit_price') ? `Unit Price` : ` `}</p>
          <p>{pricingListConfig.includes('line_total') ? `Line Total` : ` `}</p>
          <p>{pricingListConfig.includes('list_discount') ? `List Discount` : ` `}</p>
        </div>
        {items &&
          items.map((lineItem: any) => (
            <div
              className={`pricing-table-row ${
                usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY
                  ? 'bill-only-grid-cols'
                  : 'ship-bill-grid-cols'
              }`}
            >
              <div>
                <Button
                  disabled={
                    lineItem.wasted ||
                    viewOnly ||
                    isAssignUser ||
                    eventType === EVENT_TYPE.DEMO ||
                    eventType === EVENT_TYPE.TRIAL
                  }
                  style={{
                    textAlign: 'start',
                    color:
                      !lineItem?.isValidLotSerialCombination &&
                      lineItem.iscatalog &&
                      lineItem.lotValidation === 'true' &&
                      usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY &&
                      usageDetails?.status !== 'Failed' &&
                      type !== 'Failed' &&
                      type !== 'Processed'
                        ? 'red'
                        : '#080707',
                  }}
                  label={`${lineItem.productNumber} - ${lineItem.productdescription}`}
                  variant="base"
                  onClick={(): void => goToPriceDetails(lineItem)}
                />
                {lineItem.productOnHold ? (
                  <img
                    src={holdIconPath}
                    className="logo-image"
                    alt="company"
                    style={{ marginLeft: '8px', marginRight: '8px' }}
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="row-control">
                <Input
                  disabled={isAssignUser || viewOnly}
                  id="counter-input"
                  minValue={0}
                  maxValue={
                    (lineItem.iscatalog && lineItem.lotValidation === 'false') ||
                    (lineItem.iscatalog && !lineItem.isValidLotSerialCombination)
                      ? MAX_QUANTITY
                      : Number(lineItem.maxQuantity)
                  }
                  value={lineItem.quantity}
                  variant="counter"
                  onChange={(event: any, data: { number: number }): void => {
                    handleCountalert(
                      event,
                      data,
                      lineItem.uniqueId,
                      lineItem.packContent || 1,
                      lineItem.sellingUoM,
                      lineItem.productOnHold
                    );
                  }}
                />
              </div>
              <p className="vertical-align-middle">
                {pricingListConfig.includes('actual_qty')
                  ? parseFloat(lineItem.actualQuantity).toFixed(2) || ''
                  : ''}
              </p>
              <p className="vertical-align-middle">
                {/* usageDetails.disaplayOrderType === USAGE_TYPE.SHIP_AND_BILL
                //  ? 'Each'
                : getUoM(lineItem.salesUnitOfMeasure, lineItem.packContent)*/}
                {pricingListConfig.includes('seiiing_uom') ? getSellingUoM(lineItem) : ''}
              </p>
              <p className="vertical-align-middle">
                {/* usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY
                  ? 'Each'
                : getUoM(lineItem.salesUnitOfMeasure, lineItem.packContent)*/}
                {pricingListConfig.includes('actual_uom')
                  ? getUoM(lineItem.salesUnitOfMeasure, lineItem.packContent)
                  : ''}
              </p>
              {usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY && (
                <div className="vertical-align-middle">
                  {pricingListConfig.includes('wasted') && (
                    <Checkbox
                      labels={{
                        toggleEnabled: '',
                        toggleDisabled: '',
                      }}
                      variant="toggle"
                      disabled={isAssignUser || viewOnly}
                      checked={lineItem.wasted}
                      onChange={(e: any): void => {
                        handleWasted(e, lineItem.uniqueId);
                      }}
                    />
                  )}
                </div>
              )}
              <p className="price-txt vertical-align-middle">
                {pricingListConfig.includes('contract_price')
                  ? getUSDString(lineItem.contractPrice || 0)
                  : ''}
              </p>
              <div className="price-override-control">
                {pricingListConfig.includes('override_price') && (
                  <>
                    <Dropdown
                      width="x-small"
                      disabled={
                        isAssignUser ||
                        viewOnly ||
                        eventType === EVENT_TYPE.DEMO ||
                        eventType === EVENT_TYPE.TRIAL
                      }
                      // disabled={
                      //   // !lineItem.allowOverride ||
                      //   viewOnly || eventType === EVENT_TYPE.DEMO || eventType === EVENT_TYPE.TRIAL
                      // }
                      iconCategory="utility"
                      iconSize="small"
                      align="right"
                      iconName="down"
                      iconPosition="right"
                      label={lineItem.priceOverrideType === 'PERCENTAGE' ? '%' : '$'}
                      options={[
                        { label: '$', value: 'DOLLAR' },
                        { label: '%', value: 'PERCENTAGE' },
                      ]}
                      onSelect={(option: any): void => {
                        setOvverrideType(option.value, lineItem.uniqueId);
                      }}
                    />
                    <Input
                      type="number"
                      minValue="0"
                      label=""
                      key={lineItem.priceOverride}
                      defaultValue={lineItem.priceOverride || inputValue.uniqueId}
                      onBlur={(event: any): void => {
                        handleUserInput(event?.target?.value, lineItem.uniqueId);
                      }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        handleUserInputChnage(event.target.value, lineItem.uniqueId);
                      }}
                      disabled={
                        viewOnly ||
                        isAssignUser ||
                        eventType === EVENT_TYPE.DEMO ||
                        eventType === EVENT_TYPE.TRIAL
                      }
                      name=""
                      onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>): void => {
                        handleKeyPress(event, lineItem);
                      }}
                    />
                    {/* <InputField
                      type="number"
                      minValue="0"
                      label=""
                      value={lineItem.priceOverride || ''}
                      handleChangeText={(result: string): void => {
                        handleUserInput(result, lineItem.uniqueId);
                      }}
                      disabled={
                        viewOnly ||
                        isAssignUser ||
                        eventType === EVENT_TYPE.DEMO ||
                        eventType === EVENT_TYPE.TRIAL
                      }
                      name=""
                    /> */}
                  </>
                )}
              </div>
              <p
                className={
                  usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY &&
                  lineItem.priceValidation === 'false'
                    ? 'vertical-align-middle price-txt'
                    : `vertical-align-middle ${priceValidationIndicatorClass(
                        eventType,
                        lineItem,
                        usageDetails.diplayOrderType,
                        enabledModalFields
                      )}`
                }
              >
                {pricingListConfig.includes('unit_price') ? getUSDString(lineItem.usgPrice) : ''}
              </p>
              <p className="vertical-align-middle">
                {pricingListConfig.includes('line_total')
                  ? getUSDString(lineItem.usgTotalAmount)
                  : ''}
              </p>
              <p className="price-txt vertical-align-middle">
                {pricingListConfig.includes('list_discount')
                  ? `${lineItem.discountPercentage || 0} %`
                  : ''}
              </p>
            </div>
          ))}
      </div>
      {openPricingModal && (
        <PricingModal
          open={openPricingModal}
          handleToggleModal={togglePriceModal}
          lineItems={items}
          lineItem={selectedItem}
          caseExternalId={usageDetails?.caseExternalId}
          caseId={usageDetails?.caseId}
          salesRepId={usageDetails?.salesRepId}
          refetch={refetch}
          viewOnly={viewOnly}
          isAssignUser={isAssignUser}
          eventType={eventType || ''}
          diplayOrderType={usageDetails?.diplayOrderType || ''}
          isERPOrder={isERPOrder}
          orderId={usageDetails?.id || ''}
          modalconfigs={configs}
          updatedPrice={updatedPrice}
          openPricingSnackBar={openSnackBar}
        />
      )}
    </>
  );
};

export default PricingTabularDetails;
