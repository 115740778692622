/* eslint-disable */
/**
 * Module for inventory return filter.
 * @module src/components/inventory/invntoryReturns/inventoryReturnFilter
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
// import Spinner from '@salesforce/design-system-react/components/spinner';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import Button from '@salesforce/design-system-react/components/button';
// import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Combobox from '@salesforce/design-system-react/components/combobox';
// import Popover from '@salesforce/design-system-react/components/popover';
// import Input from '@salesforce/design-system-react/components/input';
// import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import {
  FILTER_INVENTORY_COMPLETED_STATUS,
  FILTER_INVENTORY_FAILED_STATUS,
  FILTER_INVENTORY_REQUEST_STATUS,
  FILTER_INVENTORY_RETURNS_COMPLETED_STATUS,
  FILTER_INVENTORY_RETURNS_STATUS,
  FILTER_INVENTORY_REQUEST_TYPE,
  FILTER_INVENTORY_APPROVED_STATUS,
  INVENTORY_3PL_OPTIONS,
} from '../../../../util/constants';
import DatePicker from '../../../Shared/DatePicker';
import AutocompleteSingleSelectPicker from '../../../Shared/AutocompleteSingleSelectPicker';
import './index.scss';
import SaveFilter from './SaveFilter';
import DeleteFilterPreset from './DeleteFilterPreset';
import moment from 'moment';
// import debounced from '../../../../util/debounced';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
// import { GET_PRODUCT_LIST } from '../../../../graphql/getProductList';
import { GET_PRODUCT_GROUP } from '../../../../graphql/getProductGroups';
import { GET_SALES_REPS } from '../../../../graphql/getSalesReps';
import { GET_OPS_LIST } from '../../../../graphql/getOpsList';
import { GET_USER_TERRITORIES } from '../../../../graphql/getUserTerritories';
import { clearInventoryReturnsFilters } from '../../../../store/ducks/inventoryReturnsFilters';
import { clearInventoryRequestFilters } from '../../../../store/ducks/inventoryRequestFilters';
import { GET_USER_REGION_AND_TERRITORIES } from '../../../../graphql/getUserRegionAndTerritories';
import CREATE_FILTER_PRESET from '../../../../graphql/mutations/createFilterPreset';
import UPDATE_FILTER_PRESET from '../../../../graphql/mutations/updateFilterPreset';
import DELETE_FILTER_PRESET from '../../../../graphql/mutations/deleteFilterPreset';
import { getRegionTerritoryInfo, setRegionTerritoryInfo } from '../../../../store/ducks/userRegionAndTerritory';

interface SelectedData {
  id: string;
  label: string;
}
interface DynamicType {
  [key: string]: any;
}

interface OpsList {
  id: string;
  value: string;
}

interface OpsDropdownSelect {
  label: string;
  value: string;
  id: string;
}
interface FilterPreset {
  filterValues: SelectedFilters;
  filterExternalId?: string;
  filterName?: string;
}

interface SelectedFilters {
  status: SelectedData[];
  shipFromDate: Date | string;
  shipToDate: Date | string;
  returnExpiryFromDate: Date | string;
  returnExpiryToDate: Date | string;
  submitToDate: Date | string;
  submitFromDate: Date | string;
  createdEndDate: Date | string;
  salesRep: any;
  threePL: string[];
  createdFromDate: Date | string;
  search: string;
  productGroup: any;
}

interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
  userId?: string;
}

enum DetailTypes {
  salesRep = 'SalesRep',
  userTerritory = 'UserTerritory',
  productGroup = 'ProductGroup',
}

type UserRegionAndTerritory = {
  id?: string;
  accountId: string;
  region: string;
  regionId: string;
  territory: string;
  territoryId: string;
  userId: string;
  __typename: string;
};

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  isRequest: boolean;
  type?: string;
  handleFilterRequest?: (filter: DynamicType | null) => void;
  selectedFilter?: FilterPreset | DynamicType | null;
  handleToggleFilterEvent: () => void;
  handleClearFilter: (externalId: string) => void;
  handleFilterEvents: (filter: DynamicType | null) => void;
  handleEditFilter: (editEvent: boolean) => void;
  refetchFilterPresets: () => void;
  isEditFilter: boolean;
  pathname: any;
}

const InventoryReturnFilter: FC<Props> = ({
  isOpen,
  toggleOpen,
  isRequest,
  type,
  handleFilterRequest,
  handleToggleFilterEvent,
  handleFilterEvents,
  handleClearFilter,
  handleEditFilter,
  refetchFilterPresets,
  selectedFilter,
  isEditFilter,
  pathname
}) => {
  const userRegionTerritoryInfo: any = useSelector(getRegionTerritoryInfo);
  const filterValues = selectedFilter?.filterValues;
  const userInfo = useSelector(getUserInfo);
  const filterName = selectedFilter ? selectedFilter.filterName : '';
  const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;

  // States
  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [options, setOptions] = useState<OpsDropdownSelect[]>();
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {}); // For Selected Value
  const [territoryOptions, setTerritoryOptions] = useState<UserRegionAndTerritory[]>();
  const [regionOptions, setRegionOptions] = useState<UserRegionAndTerritory[]>();
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  // Mutation & Queries
  const [getOpsList, { data }] = useLazyQuery(GET_OPS_LIST);
  const [getTerritories, { data: territoryData }] = useLazyQuery(GET_USER_TERRITORIES);
  const [productGroup, { data: productGroupItems }] = useLazyQuery(GET_PRODUCT_GROUP);
  const [getSalesRepsItems, { data: salesRepsItems, loading: loadingSalesRep }] = useLazyQuery(GET_SALES_REPS);
  const [getRegionAndTerritory, { data: regionAndTerritoryData }] = useLazyQuery(GET_USER_REGION_AND_TERRITORIES);
  const [createFilterPreset, { data: filterPresets }] = useMutation(CREATE_FILTER_PRESET);
  const [updateFilterPreset, { data: updatedfilterPresets }] = useMutation(UPDATE_FILTER_PRESET);
  const [deleteFilterPreset] = useMutation(DELETE_FILTER_PRESET);

  const productGroupList = productGroupItems?.getProductGroups;

  const getDate = useCallback((date: any): string => {
    const formattedSelectedDate = new Date(date);
    const finalDate = format(new Date(formattedSelectedDate), 'yyyy-MM-dd');
    return finalDate;
  }, []);

  const optionsWithCustomLabel = (value: any): any[] =>
    value &&
    // eslint-disable-next-line consistent-return
    value.map((elem: any) => {
      if (elem.__typename === DetailTypes.salesRep) {
        return { ...elem, label: `${elem.value}` };
      } else {
        return { ...elem, label: `${elem.value}` };
      }
    });
  const salesRepsData = optionsWithCustomLabel(salesRepsItems?.getSalesReps);

  useEffect(() => {
    if (userInfo) {
      getRegionAndTerritory({
        variables: {
          userId: userInfo?.id,
        },
      });
    }
  }, [getRegionAndTerritory, userInfo]);

  const getUniqueRegionTerritory = (array: UserRegionAndTerritory[]): UserRegionAndTerritory[] => {
    const cleanedArray = array.filter((item: any) => item !== undefined && item !== null);
    return cleanedArray.filter(
      (item: UserRegionAndTerritory, index: number, self: UserRegionAndTerritory[]) =>
        index === self.findIndex((element: UserRegionAndTerritory) => item.id === element.id)
    );
  };


  const getRegionTerritoryOptions = () => {
    let tempRegions: any[] = [];
    let tempTerritories: any[] = [];
    if (selectedFilters && selectedFilters?.salesRep?.length && !selectedFilters?.region?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item.userId === selectedFilters.salesRep[0].id)
          tempRegions.push({ id: item.regionId, value: item.region });
      });
      userRegionTerritoryInfo?.map((item: any) => {
        if (item.userId === selectedFilters.salesRep[0].id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && selectedFilters?.region?.length && selectedFilters?.salesRep?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item?.userId === selectedFilters?.salesRep?.[0]?.id && item?.regionId === selectedFilters?.region?.[0]?.id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && selectedFilters?.region?.length && !selectedFilters?.salesRep?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item?.regionId === selectedFilters?.region?.[0]?.id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && !selectedFilters?.region?.length && !selectedFilters?.salesRep?.length) {
      tempRegions = userRegionTerritoryInfo?.map((item: any, index: number) => {
        return { id: item.regionId, value: item.region };
      });
      tempTerritories = userRegionTerritoryInfo?.map((item: any, index: number) => {
        return { id: item.territoryId, value: item.territory };
      });
    }
    setRegionOptions(getUniqueRegionTerritory(tempRegions));
    setTerritoryOptions(getUniqueRegionTerritory(tempTerritories));
  };

  useEffect(() => {
    if (userRegionTerritoryInfo) getRegionTerritoryOptions()
  }, [selectedFilters,userRegionTerritoryInfo]);

  useEffect(() => {
    if (regionAndTerritoryData) 
      dispatch(setRegionTerritoryInfo(regionAndTerritoryData?.getUserRegionAndTerritories));
  }, [regionAndTerritoryData]);

  useEffect(() => {
    getOpsList({
      variables: {
        personaName: userInfo?.personas,
      },
    });
  }, [getOpsList, userInfo]);

  useEffect(() => {
    if (data && data.getOPSList) {
      const optionList = data.getOPSList.map((i: OpsList) => {
        return {
          label: i.value,
          value: i.value,
          id: i.id,
        };
      });
      setOptions(optionList);
    }
  }, [data]);

  const fetchItems = useCallback(() => {
    getSalesRepsItems();
  }, [getSalesRepsItems]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const dispatch = useDispatch();
  // useEffect(()=>{
  //   if (productItems && productItems.getProductList && productItems.getProductList.length) {
  //     setProductList(productItems.getProductList)
  //     if (selectedFilters?.productSearch) {
  //       const record = productItems.getProductList.filter((rec: any) => rec.productId ===selectedFilters?.productSearch);
  //       const optionsWithLabel =
  //       record &&
  //       record.map((elem: any) => {
  //         // eslint-disable-next-line no-underscore-dangle
  //         return { ...elem, label: `${
  //           elem.productNumber ? elem.productNumber : elem.productNo
  //         }-${elem.productdescription}` };
  //       });
  //       setFilterValue('productSearch', optionsWithLabel);
  //     }
  //   }
  // },[productItems])

  // useEffect(() => {
  //   if (selectedFilters &&  selectedFilters.search && selectedFilters.search.length >= 2) {
  //     debouncedFetchSetItems(selectedFilters.search);
  //   }
  // },[])

  useEffect(() => {
    productGroup();
  }, [productGroup]);

  useEffect(() => {
    if (productGroupList && selectedFilters?.productGroup) {
      const record =
        productGroupList &&
        productGroupList.filter((rec: any) => rec.id === selectedFilters?.productGroup?.[0]?.id);
      const optionsWithLabel1 =
        record &&
        record.map((elem: any) => {
          // eslint-disable-next-line no-underscore-dangle
          return { ...elem, label: `${elem.id}` };
        });
      setFilterValue('productGroup', optionsWithLabel1);
    }
  }, [productGroupList]);

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate) {
      const utcDate = getDate(selectedDate);
      setFilterValue(name, utcDate);
    }
  };

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };
      setSelectedFilters(selectedFilterValues);
      if (key === 'salesRep'){
        const selectedFilterValues = 
        { ...selectedFilters,
          [key]: filterValuesObj,
          region: [],
          territory: [],
        };
        setSelectedFilters(selectedFilterValues);
      }
      if (key === 'region'){
        const selectedFilterValues = 
        { ...selectedFilters,
          [key]: filterValuesObj,
          territory: [],
        };
        setSelectedFilters(selectedFilterValues);
      }
      if (key === 'salesRep' && filterValuesObj?.length === 0) {
        const newObject = {
          ...selectedFilters,
          salesRep: [],
          region: [],
          territory: [],
        };
        setSelectedFilters(newObject);
      } else if (key === 'region' && filterValuesObj?.length === 0) {
        const newObject = {
          ...selectedFilters,
          region: [],
          territory: [],
        };
        setSelectedFilters(newObject);
      }
    },
    [selectedFilters]
  );

  const handleSelect = (data: any, name: string) => {
    const { selection } = data;
    if (selection){
      setFilterValue(name, selection);
    } else {
      setFilterValue(name, data);
    }
  };

  const handleApplyFilter = (): void => {
    handleToggleFilterEvent()
    const filters = {
      ...selectedFilters,
      productGroup: (selectedFilters as any)?.productGroup?.[0].id,
      // salesRep: (selectedFilters as any)?.salesRep ? [(selectedFilters as any)?.salesRep?.[0].id] : [''],
      // territory: (selectedFilters as any)?.territory ? [(selectedFilters as any)?.territory?.[0].id] : [''],
      // assignedTo: (selectedFilters as any)?.assignedTo ? [(selectedFilters as any)?.assignedTo?.[0].id] : [''],
    };
    if (handleFilterRequest) {
      handleFilterRequest(filters);
    }
  };

  const resetFilters = (): void => {
    handleToggleFilterEvent()
    setSelectedFilters(null);
    if (window.location.href.indexOf('/Requests') > 0) {
      dispatch(clearInventoryRequestFilters());
    } else {
      dispatch(clearInventoryReturnsFilters());
    }

    if (handleFilterRequest) {
      handleFilterRequest(null);
    }
  };

  const handleSaveModalVisible = (): void => {
    setSaveModalVisible(!saveModalVisible);
  };

  const handleDeleteModalVisible = useCallback((): void => {
    setDeleteModalVisible(!deleteModalVisible);
  }, [deleteModalVisible]);

  const handleCreateFilterPreset = (name: string): void => {
    // debugger
    let reducedFilterValues = {};
    if (selectedFilters) {
      Object.keys(selectedFilters).forEach((key: string) => {
        if (Array.isArray(selectedFilters[key])) {
          const values = selectedFilters[key].map((item: SelectedData) => {
            const itemCopy = { id: item.id, label: item.label };
            return { ...itemCopy };
          });
          reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
        } else if (key !== '__typename' && selectedFilters[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: selectedFilters[key] };
        }
      });
    }

    const filterData = { ...reducedFilterValues };

    if (!filterExternalId || filterExternalId === '') {
      createFilterPreset({
        variables: {
          userId: userInfo?.id,
          filterValues: filterData,
          name,
          type: pathname.toLowerCase().includes('/inventory/requests') ? 'Request' : 'Returns'
        },
      });
    } else if (filterExternalId) {
      updateFilterPreset({
        variables: {
          externalId: filterExternalId,
          filterValues: filterData,
          name,
          type: pathname.toLowerCase().includes('/inventory/requests') ? 'Request' : 'Returns'
        },
      });
    }
  };

  const handleDeleteFilterPreset = (): void => {
    deleteFilterPreset({
      variables: {
        externalId: filterExternalId,
      },
    }).then(response => {
      // if delete filter preset is successful
      const deleteUsageResponse =
        response.data &&
        response.data.deleteFilterPreset &&
        response.data.deleteFilterPreset.message === 'success';
      if (deleteUsageResponse) {
        handleDeleteModalVisible();
        handleToggleFilterEvent();
        handleClearFilter(filterExternalId);
        if (refetchFilterPresets) {
          handleEditFilter(false);
          refetchFilterPresets();
        }
      }
    });
  };

  useEffect(() => {
    if (
      updatedfilterPresets &&
      updatedfilterPresets.updateFilterPreset &&
      updatedfilterPresets.updateFilterPreset.message === 'success'
    ) {
      handleToggleFilterEvent();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
        resetFilters();
      }
    }
  }, [
    handleEditFilter,
    handleFilterEvents,
    handleToggleFilterEvent,
    refetchFilterPresets,
    selectedFilters,
    updatedfilterPresets,
  ]);

  useEffect(() => {
    if (
      filterPresets &&
      filterPresets.createFilterPreset &&
      filterPresets.createFilterPreset.message === 'success'
    ) {
      handleToggleFilterEvent();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
      }
    }
  }, [
    filterPresets,
    handleEditFilter,
    handleFilterEvents,
    handleToggleFilterEvent,
    refetchFilterPresets,
    selectedFilters,
  ]);

  // const getIsChecked = (label: any): boolean => {
  //   let isChecked = false;
  //   const checkedIndex = checked.findIndex((el: any) => el.label === label);
  //   if (checkedIndex > -1) isChecked = true;
  //   return isChecked;
  // };

  // const getInputString = (options: any): string => {
  //   console.log(options, 'options');
  //   let inputValue = '';
  //   if (options.length === 0) inputValue = 'Select an option';
  //   else if (options.length === 1) inputValue = `${options[0].label}`;
  //   else inputValue = `${options.length} options selected`;
  //   return inputValue;
  // };

  // const handleCheckboxChange = (
  //   targetChecked: any,
  //   target: { id: any },
  //   value: { id: string; label: string }
  // ): void => {
  //   console.log(targetChecked, target, value);
  //   if (targetChecked) {
  //     checked.push({
  //       id: target.id,
  //       label: value.label,
  //     });
  //   } else {
  //     const valueIndex = checked.findIndex((el: any) => el.label === value);
  //     checked.splice(valueIndex, 1);
  //   }

  //   const inputValue = getInputString(checked);
  //   console.log(inputValue, 'inputValue');
  //   console.log(checked, 'checked');
  //   handleSelect({selection: checked}, 'status')
  //   setInputValue(inputValue);
  //   setChecked(checked);
  // };

  // const handleClose = (e: any, { trigger }: { trigger: any }) => {
  //   if (trigger === 'cancel') {
  //     const inputValue = getInputString(selection);
  //     const selection1 = selection.length > 0 ? selection.slice(0) : [];
  //     // this.setState({
  //     // 	checked: selection,
  //     // 	inputValue,
  //     // });

  //     console.log(selection1, 'selection1');
  //     setChecked(selection1);
  //     setInputValue(inputValue);
  //   } else {
  //     const checked1 = checked.length > 0 ? checked.slice(0) : [];
  //     console.log(checked1, 'checked1');
  //     setSelection(checked1);
  //   }
  // };

  // const setEventPartQuantity = (searchedItem: string): void => {
  //   if (searchedItem.length > 2) {
  //     productListItems({
  //       variables: { searchText: searchedItem },
  //     });
  //   } else {
  //     productListItems({
  //       variables: { searchText: '' },
  //     });
  //   }
  // };

  // const debouncedFetchSetItems = useCallback(debounced(500, setEventPartQuantity), [
  //   debounced,
  //   setEventPartQuantity,
  // ]);

  // const searchCatalog = useCallback(
  //   (_event: { persist: () => void }, searchedItem: any) => {
  //     const { value } = searchedItem;
  //     _event.persist();
  //     setFilterValue('search', value);
  //     //   setInputValue(value);
  //     if (value && value.length >= 2) {
  //       debouncedFetchSetItems(value);
  //     }
  //   },
  //   [debouncedFetchSetItems]
  // );
  const getProductGroupFilters = (item: any) => {
    if (!Array.isArray(item)) {
      return [{ id: item, value: item, label: item }];
    }
    return item;
  }
  const optionsWithLabel =
    productGroupList &&
    productGroupList.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      return { ...elem, label: `${elem.value}` };
    });
  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={handleToggleFilterEvent}
        dismissOnClickOutside={false}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Save Filter"
            key="Save"
            onClick={handleSaveModalVisible}
            disabled={
              (selectedFilters?.submitToDate &&
                selectedFilters?.submitFromDate &&
                selectedFilters.submitToDate < selectedFilters.submitFromDate) ||
              (selectedFilters?.createdEndDate &&
                selectedFilters?.createdFromDate &&
                selectedFilters.createdEndDate < selectedFilters.createdFromDate) ||
              (selectedFilters?.returnExpiryToDate &&
                selectedFilters?.returnExpiryFromDate &&
                selectedFilters.returnExpiryToDate < selectedFilters.returnExpiryFromDate)
            }
          />,
          <Button label="Reset Filter" key="Reset" onClick={resetFilters} />,
          <Button
            className="yellow-btn"
            label="Apply"
            onClick={handleApplyFilter}
            disabled={
              (selectedFilters?.submitToDate &&
                selectedFilters?.submitFromDate &&
                selectedFilters.submitToDate < selectedFilters.submitFromDate) ||
              (selectedFilters?.createdEndDate &&
                selectedFilters?.createdFromDate &&
                selectedFilters.createdEndDate < selectedFilters.createdFromDate) ||
              (selectedFilters?.returnExpiryToDate &&
                selectedFilters?.returnExpiryFromDate &&
                selectedFilters.returnExpiryToDate < selectedFilters.returnExpiryFromDate)
            }
            variant="brand"
            key="Apply Filter"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            {`${filterName && filterName !== ''
              ? `${isEditFilter ? 'Edit ' : ''}Filter Preset ${filterName}`
              : 'Filters'
              }`}
          </div>,

          isEditFilter && (
            <div className="slds-float_right" key="delete">
              <Button
                className="delete_button"
                label="Delete Preset"
                key="delete"
                onClick={handleDeleteModalVisible}
              />
            </div>
          ),
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          {(type === 'RETURNS' || type === 'FAILED' || type === 'COMPLETED') && !isRequest ? (
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">ATTRIBUTES</h1>
              {/* <p className="selectbox_label">Status</p> */}
              <Combobox
                id="status"
                events={{
                  onRequestOpen: (event: any): void => {
                    setOpen(true);
                    setOpenType(false);
                  },
                  onRequestClose: (event: any): void => {
                    setOpen(false);
                  },
                  onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'status'),
                }}
                labels={{
                  label: `Status`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                isOpen={open}
                multiple
                placeholder="Select"
                options={
                  (!isRequest && type === 'RETURNS'
                    ? FILTER_INVENTORY_RETURNS_STATUS
                    : !isRequest && type === 'COMPLETED'
                      ? FILTER_INVENTORY_COMPLETED_STATUS
                      : !isRequest && type === 'FAILED'
                        ? FILTER_INVENTORY_APPROVED_STATUS
                        : []) || []
                }
                selection={selectedFilters ? selectedFilters.status || [] : []}
                variant="readonly"
              />
              {/* <p className="selectbox_label">Type</p> */}
              <Combobox
                id="returnType"
                events={{
                  onRequestOpen: (event: any): void => {
                    setOpenType(true);
                    setOpen(false);
                  },
                  onRequestClose: (event: any): void => {
                    setOpenType(false);
                  },
                  onSelect: (event: any, data: any): void => handleSelect(data, 'type'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'type'),
                }}
                labels={{
                  label: `Type`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                isOpen={openType}
                placeholder="Select"
                options={FILTER_INVENTORY_REQUEST_TYPE || []}
                selection={selectedFilters ? selectedFilters.type || [] : []}
                variant="readonly"
              />
              {/* <p className="selectbox_label">Product Group</p> */}
              {/* <Combobox
                id="productGroup"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'productGroup'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'productGroup'),
                }}
                labels={{
                  label: `Product Group`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                options={optionsWithLabel || []}
                selection={selectedFilters ? selectedFilters.productGroup || [] : []}
                variant="readonly"
              /> */}
              <AutocompleteSingleSelectPicker
                items={optionsWithLabel}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'productGroup');
                }}
                disabled={false}
                selectedItem={selectedFilters && selectedFilters?.productGroup ? selectedFilters?.productGroup || [] : []}
                placeholder="Select Product Group"
                label="Product Group"
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="productGroup"
                autoSelectValue={false}
              />
            </div>
          ) : (
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">Attributes</h1>
              {/* <p className="selectbox_label">Status</p> */}
              <Combobox
                id="status"
                events={{
                  onRequestOpen: (event: any): void => {
                    setOpen(true);
                  },
                  onRequestClose: (event: any): void => {
                    setOpen(false);
                  },
                  onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'status'),
                }}
                labels={{
                  label: `Status`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                isOpen={open}
                multiple
                placeholder="Select"
                options={
                  (type === 'REQUESTED' && isRequest
                    ? FILTER_INVENTORY_REQUEST_STATUS
                    : type === 'PROCESSED' && isRequest
                      ? FILTER_INVENTORY_COMPLETED_STATUS
                      : type === 'FAILED' && isRequest
                        ? FILTER_INVENTORY_APPROVED_STATUS
                        : !isRequest && type === 'RETURNS'
                          ? FILTER_INVENTORY_RETURNS_STATUS
                          : !isRequest && type === 'COMPLETED'
                            ? FILTER_INVENTORY_RETURNS_COMPLETED_STATUS
                            : []) || []
                }
                selection={selectedFilters ? selectedFilters.status || [] : []}
                variant="readonly"
              />
              {/* <p className="selectbox_label">3PL Delivery</p> */}
              <Combobox
                id="3PLDelivery"
                labels={{
                  label: `3PL Delivery`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'threePL'),
                }}
                placeholder="Select"
                options={INVENTORY_3PL_OPTIONS || []}
                selection={selectedFilters ? selectedFilters.threePL || [] : []}
                variant="readonly"
              />

              {isRequest && (
                <>
                  {/* <p className="selectbox_label">Product Group</p> */}
                  {/* <Combobox
                    id="productGroup"
                    events={{
                      onSelect: (event: any, data: any): void => handleSelect(data, 'productGroup'),
                      onRequestRemoveSelectedOption: (event: any, data: any): void =>
                        handleSelect(data, 'productGroup'),
                    }}
                    labels={{
                      label: `Product Group`,
                      placeholder: 'Select',
                      noOptionsFound: 'No data found',
                    }}
                    placeholder="Select"
                    options={optionsWithLabel || []}
                    selection={selectedFilters ? selectedFilters.productGroup || [] : []}
                    variant="readonly"
                  /> */}
                  <AutocompleteSingleSelectPicker
                    items={optionsWithLabel}
                    setSelectedItem={(result: any): void => {
                      handleSelect(result, 'productGroup');
                    }}
                    disabled={false}
                    selectedItem={selectedFilters ? selectedFilters?.productGroup || [] : []}
                    placeholder="Select Product Group"
                    label="Product Group"
                    withAddMenuButton={false}
                    fetchItems={(searchText: string): string => searchText}
                    filter
                    name="productGroup"
                    autoSelectValue={false}
                  />
                </>
              )}
            </div>
          )}
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">DATE</h1>
            <div className="date-error">
              {(selectedFilters?.submitToDate &&
                selectedFilters?.submitFromDate &&
                selectedFilters.submitToDate < selectedFilters.submitFromDate) ||
                (selectedFilters?.shipToDate &&
                  selectedFilters?.shipFromDate &&
                  selectedFilters.shipToDate < selectedFilters.shipFromDate) ||
                (selectedFilters?.returnExpiryToDate &&
                  selectedFilters?.returnExpiryFromDate &&
                  selectedFilters.returnExpiryToDate < selectedFilters.returnExpiryFromDate) ||
                (selectedFilters?.createdEndDate &&
                  selectedFilters?.createdFromDate &&
                  selectedFilters.createdEndDate < selectedFilters.createdFromDate) ? (
                <Pill
                  labels={{
                    label: 'Error: To Date must be greater than or equal to From Date',
                  }}
                  hasError
                  icon={
                    <Icon
                      title="Error"
                      category="utility"
                      name="warning"
                      className="slds-icon-text-error"
                    />
                  }
                />
              ) : null}
            </div>
            {!isRequest ? (
              <>
                <div>
                  <p className="dateLabel">Requested Date</p>
                  <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="From"
                        handleChangeDate={(data: any): void =>
                          handleChangeDate(data, 'requestedFromDate')
                        }
                        disabled={false}
                        isFilterDate
                        value={selectedFilters ? selectedFilters.requestedFromDate : null}
                      />
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <span>-</span>
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="To"
                        handleChangeDate={(data: any): void =>
                          handleChangeDate(data, 'requestedToDate')
                        }
                        disabled={false}
                        isFilterDate
                        value={selectedFilters ? selectedFilters.requestedToDate : null}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p className="dateLabel">Ship Date</p>
                  <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="From"
                        handleChangeDate={(data: any): void =>
                          handleChangeDate(data, 'shipFromDate')
                        }
                        disabled={false}
                        isFilterDate
                        value={selectedFilters ? selectedFilters.shipFromDate : null}
                      />
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <span>-</span>
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="To"
                        handleChangeDate={(data: any): void => handleChangeDate(data, 'shipToDate')}
                        disabled={false}
                        isFilterDate
                        value={selectedFilters ? selectedFilters.shipToDate : null}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p className="dateLabel">Return Expiration Date</p>
                  <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="From"
                        handleChangeDate={(data: any): void =>
                          handleChangeDate(data, 'returnExpiryFromDate')
                        }
                        disabled={false}
                        isFilterDate
                        value={selectedFilters ? selectedFilters.returnExpiryFromDate : null}
                      />
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <span>-</span>
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="To"
                        handleChangeDate={(data: any): void =>
                          handleChangeDate(data, 'returnExpiryToDate')
                        }
                        disabled={false}
                        isFilterDate
                        value={selectedFilters ? selectedFilters.returnExpiryToDate : null}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <p className="dateLabel">IR Creation Date</p>
                  <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="From"
                        handleChangeDate={(data: any): void =>
                          handleChangeDate(data, 'createdFromDate')
                        }
                        disabled={false}
                        isFilterDate
                        value={
                          selectedFilters && selectedFilters.createdFromDate
                            ? moment(selectedFilters.createdFromDate).format()
                            : null
                        }
                      />
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <span>-</span>
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="To"
                        handleChangeDate={(data: any): void =>
                          handleChangeDate(data, 'createdEndDate')
                        }
                        disabled={false}
                        isFilterDate
                        value={
                          selectedFilters && selectedFilters.createdEndDate
                            ? moment(selectedFilters.createdEndDate).format()
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* <div>
                  <p className="selectbox_label">Ship Date Range</p>
                  <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="From"
                        handleChangeDate={(data: any): void => handleChangeDate(data, 'shipDateRangeStartDate')}
                        disabled={true}
                        isFilterDate={true}
                        value={selectedFilters ? selectedFilters.shipDateRangeStartDate : null}
                      />
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <span>-</span>
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="To"
                        handleChangeDate={(data: any): void => handleChangeDate(data, 'shipDateRangeEndDate')}
                        disabled={true}
                        isFilterDate={true}
                        value={selectedFilters ? selectedFilters.shipDateRangeEndDate : null}
                      />
                    </div>
                  </div>
                </div> */}
              </>
            )}
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">OTHER</h1>
            <div className="slds-form-element__control">
              <AutocompleteSingleSelectPicker
                items={salesRepsData || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'salesRep');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.salesRep || [] : []}
                placeholder="Sales Rep"
                label="Sales Rep"
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="salesRep"
                autoSelectValue={false}
              />
            </div>
            <AutocompleteSingleSelectPicker
              items={regionOptions || []}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'region');
              }}
              disabled={false}
              selectedItem={selectedFilters ? selectedFilters.region || [] : []}
              placeholder="Select Region"
              label="Region"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="region"
              autoSelectValue={false}
            />
            <AutocompleteSingleSelectPicker
              items={territoryOptions || []}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'territory');
              }}
              disabled={false}
              selectedItem={selectedFilters ? selectedFilters.territory || [] : []}
              placeholder="Select Territory"
              label="Territory"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="territory"
              autoSelectValue={false}
            />
            {isRequest && <Combobox
              id="assigned"
              labels={{
                label: `Assigned To`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'assignedTo'),
              }}
              placeholder="Select"
              options={options || []}
              selection={selectedFilters ? selectedFilters.assignedTo || [] : []}
              variant="readonly"
            />}
          </div>
          {saveModalVisible && (
            <SaveFilter
              handleSaveModalVisible={handleSaveModalVisible}
              saveModalVisible={saveModalVisible}
              handleCreateFilterPreset={handleCreateFilterPreset}
              selectedFilterName={filterName}
            />
          )}

          {deleteModalVisible && (
            <DeleteFilterPreset
              deleteModalVisible={deleteModalVisible}
              handleDeleteModalVisible={handleDeleteModalVisible}
              handleDeleteFilterPreset={handleDeleteFilterPreset}
            />
          )}
        </section>
      </Modal>
    </IconSettings>
  );
};

/** Custom component for Inventory Return Filter */
export default InventoryReturnFilter;
